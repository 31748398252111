import {
    apiRequest,
    payrollHonorariumReady,
    payrollHonorariumCreate,
    payrollHonorariumEmployee,
    payrollHonorariumCheckDataCreate,
} from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchCheckTunkin = async (query) => {
        return await apiRequest("get", payrollHonorariumCheckDataCreate, {
            params: query
        }, true)
    }

    fetchReady = async (query) => {
        return await apiRequest("get", payrollHonorariumReady, {params: query}, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", payrollHonorariumCreate, {
            body: query
        }, true)
    }

    fetchEmployee = async (query) => {
        return await apiRequest("get", payrollHonorariumEmployee, {
            params: query
        }, true)
    }
}

export default CreateRepository