import {
    apiRequest,
    masterReferenceNrsList,
    masterReferenceNrsActivated,
    masterMappingPpspmListOperator,
    masterCmsDPCreateLayanan,
    masterCmsDPEditLayanan,
    masterCmsDPDetailLayanan,
    masterMappingPpspmListApprover,
    masterCmsDPCreateSLayanan,
    masterCmsDPEditSLayanan,
    masterCmsDPDetailSLayanan,
    masterCmsDPListInformasi,
    masterCmsDPCreateInformasi,
    masterCmsDPEditInformasi,
    masterCmsDPDetailInformasi,
    masterMappingPpspm,
    masterMappingPpspmDelete
} from "../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchInactive = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingPpspmDelete, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }


    fetchActive = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsActivated, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableDpLayanan = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingPpspmListOperator, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableDpSLayanan = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingPpspmListApprover, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableDpInformasi = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsDPListInformasi, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository