import React, { Fragment, Component } from "react"
import { accessRole, ppk } from "../../../../../helpers/access_role"
import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Alert, Label, Modal, Input } from "reactstrap"
import Dropzone from "react-dropzone"
import Select from "react-select"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Shimmer from "react-shimmer-effect"
import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import CustomSelect from "../../../../../helpers/ui/CustomSelect"
import { FileInput, generateFormData } from "../../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect } from "react-router-dom"
import ProcessBloc from "./Blocs/ProcessBloc"
import apiResponse from "../../../../../services/apiResponse"
import OfficialTravelUpdateLocalComponent from "./Components/OfficialTravelUpdateLocalComponent"
import OfficialTravelUpdateInterComponent from "./Components/OfficialTravelUpdateInterComponent"

import OfficialTravelSourceComponent from "./Components/OfficialTravelSourceComponent"

import MinioClient from "../../../../../services/minioClient"

import moment from "moment"

class ProcessView extends Component {
    processBloc = new ProcessBloc()
    minioClient = new MinioClient()

    constructor(props) {
        super(props)
        this.state = {
            detailUuid: null,
            showDetail: false,
            uuid: this.props.uuid,
            participantUuid: null,
            loadingInitial: true,
            processDetail: {},
            loadingEmployee: true,
            processEmployees: [],
            action: "0",
            dataForm: {},
            formFileName: [],
            formValue: [],
            formFiles: [],
            forMinio: [],
            formTemp: [],
            showModal: false,
            showModalType: null,
            currentEdit: null,
            currentName: "",
            copyFrom: null,
            copyUuid: null,
            copyStatus: null,
        }
    }

    submitData = async () => {
        //const formData = generateFormData(this.state.dataForm, this.state.forMinio)
        const formData = generateFormData(this.state.dataForm, {})
        /* if (this.state.action === 1) { //ajukan

        } else if (this.state.action === 2) { //approve
            //await this.processBloc.fetchUpdate(formData)
        } else { //draft
            //await this.processBloc.fetchUpdate(formData)
        } */
        await this.processBloc.fetchUpdate(formData)
    }
    deleteParticipant = async () => {
        await this.processBloc.fetchDeleteParticipant({ uuid: this.state.participantUuid })
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            confirm: true,
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    deleteConfirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    deleteConfirm: false,
                }
                break
            case "confirm":
                forState = {
                    deleteLoading: true,
                    deleteConfirm: false,
                }
                this.deleteParticipant()
                break
            case "success":
                this.processBloc.fetchDetail({ uuid: this.props.uuid })
                forState = {
                    loadingEmployee: true,
                    deleteSuccess: false,
                }
                break
            case "failed":
                forState = {
                    deleteFailed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        if (this.props.uuid) {
            this.processBloc.fetchDetail({ uuid: this.props.uuid })
        }
        this.processBloc.processDetailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingInitial: true,
                        loadingEmployee: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    this.setState({
                        loadingInitial: false,
                        processDetail: result.data.response.detail,
                        destination: result.data.response.destination,
                        uuid: result.data.response.official_travel_process_uuid,
                    })
                    this.getEmployeeData()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loadingInitial: false,
                    })
                    break
                default:
                    break
            }
        })
        this.processBloc.processParticipantChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingEmployee: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    var _formFileName = []
                    var _formValue = []
                    var _forMinio = []
                    for (var e = 0; e < result.data.response.length; ++e) {
                        var response = result.data.response[e]
                        _forMinio[e] = { field: "document_" + response.official_travel_process_emp_uuid, name: "Keuangan/PerjalananDinas/Lampiran" }

                        /* if (response.official_travel_process_emp_file_name !== null) {
                            var maxName = response.official_travel_process_emp_file_name.length
                            _formFileName[response.official_travel_process_emp_uuid] = maxName < 17 ? response.official_travel_process_emp_file_name : response.official_travel_process_emp_file_name.substring(0, 15) + '...'
                        } */
                        if (response.official_travel_process_emp_file_url !== null) {
                            _formFileName[response.official_travel_process_emp_uuid] = response.official_travel_process_emp_file_url
                        }
                        _formValue[response.official_travel_process_emp_uuid] = []
                        _formValue[response.official_travel_process_emp_uuid]["status"] = response.official_travel_process_emp_official_travel_participant_status
                        _formValue[response.official_travel_process_emp_uuid]["kurs"] = response.official_travel_process_emp_kurs_value
                        _formValue[response.official_travel_process_emp_uuid]["oversea"] = response.official_travel_oversea
                        _formValue[response.official_travel_process_emp_uuid]["daily_max_day"] = response.official_travel_process_emp_daily_max_day
                        _formValue[response.official_travel_process_emp_uuid]["daily_max_value_1"] = response.official_travel_process_emp_daily_max_value_1
                        _formValue[response.official_travel_process_emp_uuid]["daily_max_value_2"] = response.official_travel_process_emp_daily_max_value_2
                        _formValue[response.official_travel_process_emp_uuid]["daily_max_value_3"] = response.official_travel_process_emp_daily_max_value_3
                        _formValue[response.official_travel_process_emp_uuid]["daily"] = []
                        _formValue[response.official_travel_process_emp_uuid]["hotel"] = []
                        _formValue[response.official_travel_process_emp_uuid]["transport"] = []
                        _formValue[response.official_travel_process_emp_uuid]["representative"] = []
                        _formValue[response.official_travel_process_emp_uuid]["other"] = []
                        _formValue[response.official_travel_process_emp_uuid]["allowed"] = [this.state.processDetail.official_travel_process_type1_official_travel_other_uuid === "self" ? 1 : 0, this.state.processDetail.official_travel_process_type2_official_travel_other_uuid === "self" ? 1 : 0, this.state.processDetail.official_travel_process_type3_official_travel_other_uuid === "self" ? 1 : 0, this.state.processDetail.official_travel_process_type4_official_travel_other_uuid === "self" ? 1 : 0]

                        var _daily = []
                        var _hotel = []
                        var _transport = []
                        var _representative = []
                        var _other = []
                        for (var d = 0; d < response.daily.length; ++d) {
                            _daily[response.daily[d].official_travel_process_value_type] = [response.daily[d].official_travel_process_value_uuid, response.daily[d].official_travel_process_value_quantity.toString(), response.daily[d].official_travel_process_value_amount, response.daily[d].official_travel_process_value_total]
                        }
                        for (var d = 0; d < response.hotel.length; ++d) {
                            _hotel[response.hotel[d].official_travel_process_value_type] = [response.hotel[d].official_travel_process_value_uuid, response.hotel[d].official_travel_process_value_quantity.toString(), response.hotel[d].official_travel_process_value_amount, response.hotel[d].official_travel_process_value_total]
                        }
                        for (var d = 0; d < response.transport.length; ++d) {
                            _transport[response.transport[d].official_travel_process_value_type] = [response.transport[d].official_travel_process_value_uuid, response.transport[d].official_travel_process_value_quantity.toString(), response.transport[d].official_travel_process_value_amount, response.transport[d].official_travel_process_value_total]
                        }
                        for (var d = 0; d < response.representative.length; ++d) {
                            _representative[response.representative[d].official_travel_process_value_type] = [response.representative[d].official_travel_process_value_uuid, response.representative[d].official_travel_process_value_quantity.toString(), response.representative[d].official_travel_process_value_amount, response.representative[d].official_travel_process_value_total]
                        }
                        for (var d = 0; d < response.other.length; ++d) {
                            _other[response.other[d].official_travel_process_value_type] = [response.other[d].official_travel_process_value_uuid, response.other[d].official_travel_process_value_quantity.toString(), response.other[d].official_travel_process_value_amount, response.other[d].official_travel_process_value_total]
                        }

                        _formValue[response.official_travel_process_emp_uuid]["daily"]["items"] = _daily
                        _formValue[response.official_travel_process_emp_uuid]["daily"]["total"] = response.official_travel_process_emp_value_daily
                        _formValue[response.official_travel_process_emp_uuid]["hotel"]["items"] = _hotel
                        _formValue[response.official_travel_process_emp_uuid]["hotel"]["total"] = response.official_travel_process_emp_value_hotel
                        _formValue[response.official_travel_process_emp_uuid]["transport"]["items"] = _transport
                        _formValue[response.official_travel_process_emp_uuid]["transport"]["total"] = response.official_travel_process_emp_value_transport
                        _formValue[response.official_travel_process_emp_uuid]["representative"]["items"] = _representative
                        _formValue[response.official_travel_process_emp_uuid]["representative"]["total"] = response.official_travel_process_emp_value_representative
                        _formValue[response.official_travel_process_emp_uuid]["other"]["items"] = _other
                        _formValue[response.official_travel_process_emp_uuid]["other"]["total"] = response.official_travel_process_emp_value_other
                    }

                    _forMinio[_forMinio.length] = { field: "process_file", name: "Keuangan/PerjalananDinas/Lampiran" }

                    /* if (this.state.processDetail.official_travel_process_file_name !== null) {
                        _formFileName['process_file'] = this.state.processDetail.official_travel_process_file_name
                    } */
                    if (this.state.processDetail.official_travel_process_file_url !== null) {
                        _formFileName["process_file"] = this.state.processDetail.official_travel_process_file_url
                    }
                    this.setState({
                        formValue: _formValue,
                        formFileName: _formFileName,
                        loadingEmployee: false,
                        processEmployees: result.data.response,
                        forMinio: _forMinio,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loadingEmployee: false,
                    })
                    break
                default:
                    break
            }
        })
        this.processBloc.processUpdateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    var response = result.data.response
                    if (result.data.status) {
                        this.setState({
                            processUuid: response,
                            loading: false,
                            success: true,
                        })
                    } else {
                        this.setState({
                            loading: false,
                            failed: true,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.processBloc.deleteParticipantChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    var response = result.data.response
                    if (result.data.status) {
                        this.setState({
                            deleteLoading: false,
                            deleteSuccess: true,
                            redirect: response === 0 ? true : false,
                        })
                    } else {
                        this.setState({
                            deleteLoading: false,
                            deleteFailed: true,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        deleteLoading: false,
                        deleteFailed: true,
                    })
                    break
                default:
                    break
            }
        })
    }

    getEmployeeData() {
        this.processBloc.fetchParticipant({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.processBloc.deleteParticipantChannel.unsubscribe()
        this.processBloc.processUpdateChannel.unsubscribe()
        this.processBloc.processDetailChannel.unsubscribe()
        this.processBloc.processParticipantChannel.unsubscribe()
    }

    acceptedFile = (file, id) => {
        var _formFileName = this.state.formFileName
        var _formFiles = this.state.formFiles
        var maxName = file[0].path.length
        _formFileName[id] = id === "process_file" ? file[0].path : maxName < 17 ? file[0].path : file[0].path.substring(0, 15) + "..."
        _formFiles[id] = file
        this.setState({
            formFileName: _formFileName,
            formFiles: _formFiles,
        })
    }

    onChangeFileUrl(value, id) {
        var _formFileName = this.state.formFileName
        _formFileName[id] = value.target.value
        this.setState({
            formFileName: _formFileName,
        })
    }

    handlerCloseModal = () => {
        this.setState({
            showModal: false,
            showModalType: null,
        })
    }

    handlerChangeValue = (newValue) => {
        alert("asasa")
        var _formValue = this.state.formValue
        _formValue[this.state.currentEdit] = newValue
        this.setState({
            formValue: _formValue,
            showModal: false,
            showModalType: null,
        })
    }

    numberWithPoint(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    downloadFile = (path) => {
        this.setState({
            minioLoading: true,
        })
        this.minioClient.get(path).then((url) => {
            this.setState({
                minioLoading: false,
                minioSuccess: true,
            })
            setTimeout(() => {
                this.setState({
                    minioSuccess: false,
                })
            }, 1000)
            window.open(url, "_blank")
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Tambah Proses Pengajuan Perjalanan Dinas</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}>
                                        <Row className="mb-3">
                                            <label className="col-md-2 mt-1">Nomor Pengajuan</label>
                                            <Col md="10" className="mt-1">
                                                {this.state.loadingInitial ? (
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: 200, height: 15 }}></div>
                                                    </Shimmer>
                                                ) : (
                                                    this.state.processDetail.official_travel_process_number
                                                )}
                                                <AvField type="hidden" name="process_uuid" value={this.state.uuid} />
                                                <AvField type="hidden" name="process_action" value={this.state.action} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-2 mt-1">Tujuan</label>
                                            <Col md="10" className="mt-1">
                                                {this.state.loadingInitial ? (
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: 200, height: 15 }}></div>
                                                    </Shimmer>
                                                ) : (
                                                    this.state.processDetail.official_travel_process_type_name
                                                )}
                                                <AvField type="hidden" name="process_uuid" value={this.state.uuid} />
                                                <AvField type="hidden" name="process_action" value={this.state.action} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-2 mt-1">Data Pengajuan</label>
                                            <Col md="10" className="mt-1">
                                                {this.state.loadingInitial ? (
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: 200, height: 15 }}></div>
                                                    </Shimmer>
                                                ) : (
                                                    <div className="d-flex flex">
                                                        <div className={"bg-soft-" + (this.state.processDetail.official_travel_process_type1_official_travel_other_uuid === "self" ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Uang Harian</div>
                                                        <div className={"bg-soft-" + (this.state.processDetail.official_travel_process_type2_official_travel_other_uuid === "self" ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>{this.state.processDetail.official_travel_process_type === 2 ? "Perjalanan" : "Penginapan"}</div>
                                                        <div className={"bg-soft-" + (this.state.processDetail.official_travel_process_type3_official_travel_other_uuid === "self" ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Transportasi</div>
                                                        {this.state.processDetail.official_travel_process_type === 2 ? <></> : <div className={"bg-soft-" + (this.state.processDetail.official_travel_process_type4_official_travel_other_uuid === "self" ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Representatif</div>}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-2 mt-1">File Lampiran</label>
                                            <Col md="10" className="mt-1">
                                                {this.state.loadingEmployee ? (
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: 240, height: 22 }}></div>
                                                    </Shimmer>
                                                ) : (
                                                    <>
                                                        <table width="100%">
                                                            <tr>
                                                                <td width="*">
                                                                    <AvInput
                                                                        onChange={(value) => {
                                                                            this.onChangeFileUrl(value, "process_file")
                                                                        }}
                                                                        validate={{ required: { value: this.state.action === "0" ? false : this.state.formFileName["process_file"] ? false : true } }}
                                                                        name="process_file"
                                                                        style={{ padding: "2px 4px" }}
                                                                        value={this.state.formFileName["process_file"]}
                                                                    />
                                                                </td>
                                                                {this.state.processDetail.official_travel_process_file_url !== null ? (
                                                                    <td width="24px">
                                                                        <div className="bd-highlight float-start" style={{ padding: "2px 4px" }}>
                                                                            <a href={this.state.processDetail.official_travel_process_file_url} rel="noreferrer" target="_blank">
                                                                                <i className="uil-eye"></i>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </tr>
                                                        </table>
                                                        {/* {(this.state.processDetail.official_travel_process_file_path !== null) ?
                                                            <div className="bd-highlight float-start" style={{ padding: '0px 4px' }}>
                                                                <span className={"btn btn-warning btn-sm"} onClick={() => { this.downloadFile(this.state.processDetail.official_travel_process_file_path) }} style={{ padding: '2px 5px' }}><i className="uil-eye"></i></span>
                                                            </div>
                                                            : <></>
                                                        }
                                                        <div className="bd-highlight float-start">
                                                            <Dropzone onDrop={acceptedFiles => this.acceptedFile(acceptedFiles, "process_file")}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} multiple={false} accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
                                                                            <span
                                                                                className={"btn " + (this.state.formFileName['process_file'] ? "btn-danger" : "btn-secondary") + " btn-sm"}
                                                                                style={{
                                                                                    padding: '2px 5px'
                                                                                }}
                                                                            >
                                                                                <i className="uil-file"></i>
                                                                            </span> {this.state.formFileName['process_file'] ? this.state.formFileName['process_file'] : 'Pilih File'}
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                        <div className="bd-highlight" style={{ marginTop: '-0.32rem', fontSize: 22 }}>
                                                            <AvField validate={{ required: { value: (this.state.action === "0" ? false : (this.state.formFileName["process_file"] ? false : true)) } }} name={"process_file"} value={this.state.formFiles["process_file"]} type="hidden" errorMessage="&nbsp;&nbsp;&nbsp;&nbsp;&#9888;" />
                                                        </div> */}
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        {this.state.loadingInitial ? (
                                            <></>
                                        ) : (
                                            <>
                                                {this.state.processDetail.official_travel_process_status === 3 ? (
                                                    <Row className="mb-3">
                                                        <Col md="12" className="mt-1">
                                                            <Alert color="warning">
                                                                <b>Data sebelumnya ditolak:</b> {this.state.processDetail.official_travel_process_process_reason}
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                        <hr />
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <div className="table-responsive">
                                                    <Table className="table table-bordered table-striped table-hover" style={{ minWidth: 1000 }}>
                                                        {!this.state.loadingInitial ? (
                                                            <Thead>
                                                                <Tr>
                                                                    <Th width="180" className="text-center">
                                                                        Nomor ST
                                                                    </Th>
                                                                    {/* <Th width="40"></Th> */}
                                                                    <Th width="*">Nama Pegawai</Th>
                                                                    {this.state.processDetail.official_travel_process_type === 1 ? (
                                                                        <></>
                                                                    ) : (
                                                                        <Th width="120" className="text-end">
                                                                            Kurs ($)
                                                                        </Th>
                                                                    )}
                                                                    <Th width="160" className="text-end">
                                                                        Uang Harian
                                                                    </Th>
                                                                    <Th width="160" className="text-end">
                                                                        {this.state.processDetail.official_travel_process_type === 1 ? "Penginapan" : "Perjalanan"}
                                                                    </Th>
                                                                    <Th width="160" className="text-end">
                                                                        Transportasi
                                                                    </Th>
                                                                    <Th width="160" className="text-end" style={{ display: this.state.processDetail.official_travel_process_type === 1 ? "" : "none" }}>
                                                                        Representatif
                                                                    </Th>
                                                                    <Th width="160" className="text-end">
                                                                        Total
                                                                    </Th>
                                                                    {/* <Th width="220">Lampiran</Th> */}
                                                                    <Th width="120" className="text-center">
                                                                        Aksi
                                                                    </Th>
                                                                </Tr>
                                                            </Thead>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <Tbody>
                                                            {this.state.loadingEmployee ? (
                                                                <tr>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: "100%", height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    {/* <td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                        <Shimmer className="shimmer"><div style={{ width: '100%', height: 15 }}></div></Shimmer>
                                                                    </td> */}
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: "80%", height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    {/* this.state.formTemp */}
                                                                    {this.state.processDetail.official_travel_process_type === 1 ? (
                                                                        <></>
                                                                    ) : (
                                                                        <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: 120, height: 15 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                    )}
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0, display: this.state.processDetail.official_travel_process_type === 1 ? "" : "none" }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    {/* <td className="text-center">
                                                                        <Shimmer className="shimmer"><span style={{ width: '100%', height: 22 }}></span></Shimmer>
                                                                    </td> */}
                                                                    <td className="text-center">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 22, height: 22 }}></div>
                                                                        </Shimmer>{" "}
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 22, height: 22 }}></div>
                                                                        </Shimmer>{" "}
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 22, height: 22 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <>
                                                                    {this.state.processEmployees?.map((item, key) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td className="text-center" style={{ paddingTop: 12, paddingBottom: 12 }}>
                                                                                    <span
                                                                                        className="link-primary"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    detailUuid: item.official_travel_uuid,
                                                                                                },
                                                                                                function () {
                                                                                                    this.setState({
                                                                                                        showDetail: true,
                                                                                                    })
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {item.official_travel_number}
                                                                                    </span>
                                                                                </td>
                                                                                {/* <td style={{ paddingTop: 12, paddingBottom: 12 }}>
                                                                                        <div
                                                                                            className="btn btn-warning btn-sm"
                                                                                            disabled={true}
                                                                                            style={{
                                                                                                padding: item.official_travel_oversea === 2 ? '1px 5.5px' : '1px 3px',
                                                                                                borderRadius: 20,
                                                                                                fontSize: 10,
                                                                                                cursor: 'default',
                                                                                            }}
                                                                                        >
                                                                                            {item.official_travel_oversea === 2 ? '$' : 'Rp'}
                                                                                        </div>
                                                                                    </td> */}
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }}>
                                                                                    {item.employee_name}
                                                                                    <br />
                                                                                    <AvField type="hidden" name={"employee[" + key + "]"} value={item.official_travel_process_emp_uuid} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_kurs"} id={item.official_travel_process_emp_uuid + "_kurs"} value={this.state.formValue[item.official_travel_process_emp_uuid]["kurs"]} />
                                                                                </td>
                                                                                {/* this.state.formTemp */}
                                                                                {this.state.processDetail.official_travel_process_type === 1 ? (
                                                                                    <></>
                                                                                ) : (
                                                                                    <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                        Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_process_emp_uuid]["kurs"].replace(".00", "").replace(".", ","))},-
                                                                                    </td>
                                                                                )}
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                    Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["total"].replace(".00", "").replace(".", ","))},-
                                                                                    <br />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_1_u"} id={item.official_travel_process_emp_uuid + "_1_1_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][1][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_1_q"} id={item.official_travel_process_emp_uuid + "_1_1_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][1][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_1_a"} id={item.official_travel_process_emp_uuid + "_1_1_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][1][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_1_t"} id={item.official_travel_process_emp_uuid + "_1_1_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][1][3]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_2_u"} id={item.official_travel_process_emp_uuid + "_1_2_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][2][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_2_q"} id={item.official_travel_process_emp_uuid + "_1_2_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][2][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_2_a"} id={item.official_travel_process_emp_uuid + "_1_2_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][2][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_2_t"} id={item.official_travel_process_emp_uuid + "_1_2_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][2][3]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_3_u"} id={item.official_travel_process_emp_uuid + "_1_3_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][3][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_3_q"} id={item.official_travel_process_emp_uuid + "_1_3_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][3][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_3_a"} id={item.official_travel_process_emp_uuid + "_1_3_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][3][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_1_3_t"} id={item.official_travel_process_emp_uuid + "_1_3_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["items"][3][3]} />
                                                                                </td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                    Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["total"].replace(".00", "").replace(".", ","))},-
                                                                                    <br />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_2_1_u"} id={item.official_travel_process_emp_uuid + "_2_1_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["items"][1][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_2_1_q"} id={item.official_travel_process_emp_uuid + "_2_1_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["items"][1][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_2_1_a"} id={item.official_travel_process_emp_uuid + "_2_1_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["items"][1][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_2_1_t"} id={item.official_travel_process_emp_uuid + "_2_1_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["items"][1][3]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_2_4_u"} id={item.official_travel_process_emp_uuid + "_2_4_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["items"][4][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_2_4_q"} id={item.official_travel_process_emp_uuid + "_2_4_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["items"][4][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_2_4_a"} id={item.official_travel_process_emp_uuid + "_2_4_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["items"][4][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_2_4_t"} id={item.official_travel_process_emp_uuid + "_2_4_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["items"][4][3]} />
                                                                                </td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                    Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["total"].replace(".00", "").replace(".", ","))},-
                                                                                    <br />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_1_u"} id={item.official_travel_process_emp_uuid + "_3_1_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][1][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_1_q"} id={item.official_travel_process_emp_uuid + "_3_1_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][1][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_1_a"} id={item.official_travel_process_emp_uuid + "_3_1_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][1][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_1_t"} id={item.official_travel_process_emp_uuid + "_3_1_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][1][3]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_4_u"} id={item.official_travel_process_emp_uuid + "_3_4_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][4][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_4_q"} id={item.official_travel_process_emp_uuid + "_3_4_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][4][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_4_a"} id={item.official_travel_process_emp_uuid + "_3_4_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][4][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_4_t"} id={item.official_travel_process_emp_uuid + "_3_4_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][4][3]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_21_u"} id={item.official_travel_process_emp_uuid + "_3_21_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][21][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_21_q"} id={item.official_travel_process_emp_uuid + "_3_21_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][21][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_21_a"} id={item.official_travel_process_emp_uuid + "_3_21_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][21][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_21_t"} id={item.official_travel_process_emp_uuid + "_3_21_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][21][3]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_24_u"} id={item.official_travel_process_emp_uuid + "_3_24_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][24][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_24_q"} id={item.official_travel_process_emp_uuid + "_3_24_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][24][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_24_a"} id={item.official_travel_process_emp_uuid + "_3_24_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][24][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_24_t"} id={item.official_travel_process_emp_uuid + "_3_24_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][24][3]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_31_u"} id={item.official_travel_process_emp_uuid + "_3_31_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][31][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_31_q"} id={item.official_travel_process_emp_uuid + "_3_31_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][31][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_31_a"} id={item.official_travel_process_emp_uuid + "_3_31_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][31][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_31_t"} id={item.official_travel_process_emp_uuid + "_3_31_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][31][3]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_34_u"} id={item.official_travel_process_emp_uuid + "_3_34_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][34][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_34_q"} id={item.official_travel_process_emp_uuid + "_3_34_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][34][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_34_a"} id={item.official_travel_process_emp_uuid + "_3_34_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][34][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_3_34_t"} id={item.official_travel_process_emp_uuid + "_3_34_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["items"][34][3]} />
                                                                                </td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12, display: this.state.processDetail.official_travel_process_type === 1 ? "" : "none" }} className="text-end">
                                                                                    Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_process_emp_uuid]["representative"]["total"].replace(".00", "").replace(".", ","))},-
                                                                                    <br />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_4_1_u"} id={item.official_travel_process_emp_uuid + "_4_1_u"} value={this.state.formValue[item.official_travel_process_emp_uuid]["representative"]["items"][1][0]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_4_1_q"} id={item.official_travel_process_emp_uuid + "_4_1_q"} value={this.state.formValue[item.official_travel_process_emp_uuid]["representative"]["items"][1][1]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_4_1_a"} id={item.official_travel_process_emp_uuid + "_4_1_a"} value={this.state.formValue[item.official_travel_process_emp_uuid]["representative"]["items"][1][2]} />
                                                                                    <AvField onChange={() => {}} type="hidden" name={item.official_travel_process_emp_uuid + "_4_1_t"} id={item.official_travel_process_emp_uuid + "_4_1_t"} value={this.state.formValue[item.official_travel_process_emp_uuid]["representative"]["items"][1][3]} />
                                                                                </td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                    Rp. {this.numberWithPoint((parseInt(this.state.formValue[item.official_travel_process_emp_uuid]["daily"]["total"]) + parseInt(this.state.formValue[item.official_travel_process_emp_uuid]["hotel"]["total"]) + parseInt(this.state.formValue[item.official_travel_process_emp_uuid]["transport"]["total"]) + parseInt(this.state.formValue[item.official_travel_process_emp_uuid]["representative"]["total"])).toString().replace(".00", "").replace(".", ","))},-
                                                                                </td>
                                                                                <td style={{ padding: "10px", display: "none" }}>
                                                                                    <table width="100%">
                                                                                        <tr>
                                                                                            <td width="*">
                                                                                                {/* <AvInput validate={{ required: { value: (this.state.action === "0" ? false : (this.state.formFileName["process_file"] ? false : true)) } }} name="process_file_url" style={{ padding: '2px 4px' }} value={this.state.formFileName["process_file"]} /> */}
                                                                                                <AvInput
                                                                                                    onChange={(value) => {
                                                                                                        this.onChangeFileUrl(value, item.official_travel_process_emp_uuid)
                                                                                                    }}
                                                                                                    validate={{ required: { value: this.state.action === "0" ? false : this.state.formFileName["process_file"] ? false : this.state.formFileName[item.official_travel_process_emp_uuid] ? false : true } }}
                                                                                                    style={{ padding: "2px 4px" }}
                                                                                                    name={"document_" + item.official_travel_process_emp_uuid}
                                                                                                    value={this.state.formFileName[item.official_travel_process_emp_uuid]}
                                                                                                />
                                                                                            </td>
                                                                                            {this.state.formFileName[item.official_travel_process_emp_uuid] !== null ? (
                                                                                                <td width="24px">
                                                                                                    <div className="bd-highlight float-start" style={{ padding: "2px 4px" }}>
                                                                                                        <a href={this.state.formFileName[item.official_travel_process_emp_uuid]} rel="noreferrer" target="_blank">
                                                                                                            <i className="uil-eye"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </td>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </tr>
                                                                                    </table>
                                                                                    {/* {(item.official_travel_process_emp_file_path !== null) ?
                                                                                            <div className="bd-highlight float-start" style={{ padding: '0px 4px' }}>
                                                                                                <span className={"btn btn-warning btn-sm"} onClick={() => { this.downloadFile(item.official_travel_process_emp_file_path) }} style={{ padding: '2px 5px' }}><i className="uil-eye"></i></span>
                                                                                            </div>
                                                                                            : <></>
                                                                                        }
                                                                                        <div className="bd-highlight float-start">
                                                                                            <Dropzone onDrop={acceptedFiles => this.acceptedFile(acceptedFiles, item.official_travel_process_emp_uuid)}>
                                                                                                {({ getRootProps, getInputProps }) => (
                                                                                                    <section>
                                                                                                        <div {...getRootProps()}>
                                                                                                            <input {...getInputProps()} multiple={false} accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
                                                                                                            <span
                                                                                                                className={"btn " + (this.state.formFileName[item.official_travel_process_emp_uuid] ? "btn-danger" : "btn-secondary") + " btn-sm"}
                                                                                                                style={{
                                                                                                                    padding: '2px 5px'
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="uil-file"></i>
                                                                                                            </span> {this.state.formFileName[item.official_travel_process_emp_uuid] ? this.state.formFileName[item.official_travel_process_emp_uuid] : 'Pilih File'}
                                                                                                        </div>
                                                                                                    </section>
                                                                                                )}
                                                                                            </Dropzone>
                                                                                        </div>
                                                                                        <div className="bd-highlight float-end" style={{ marginTop: '-0.28rem', fontSize: 20 }}>
                                                                                            <AvField validate={{ required: { value: (this.state.action === "0" ? false : (this.state.formFileName["process_file"] ? false : (this.state.formFileName[item.official_travel_process_emp_uuid] ? false : true))) } }} name={"document_" + item.official_travel_process_emp_uuid} value={this.state.formFiles[item.official_travel_process_emp_uuid]} type="hidden" errorMessage="&#9888;" />
                                                                                        </div> */}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {this.state.copyFrom === null ? (
                                                                                        <span
                                                                                            className={"btn btn-success btn-sm"}
                                                                                            style={{
                                                                                                padding: "2px 5px",
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                console.log(item.official_travel_uuid)
                                                                                                this.setState({
                                                                                                    copyFrom: item.official_travel_process_emp_uuid,
                                                                                                    copyUuid: item.official_travel_uuid,
                                                                                                    copyStatus: item.official_travel_process_emp_official_travel_participant_status,
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <i className="uil-file-copy-alt"></i>
                                                                                        </span>
                                                                                    ) : (
                                                                                        <>
                                                                                            {item.official_travel_uuid === this.state.copyUuid && item.official_travel_process_emp_official_travel_participant_status === this.state.copyStatus ? (
                                                                                                <span
                                                                                                    className={"btn " + (this.state.copyFrom === item.official_travel_process_emp_uuid ? "btn-primary" : "btn-danger") + " btn-sm"}
                                                                                                    style={{
                                                                                                        padding: "2px 5px",
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        if (this.state.copyFrom === item.official_travel_process_emp_uuid) {
                                                                                                            this.setState({
                                                                                                                copyFrom: null,
                                                                                                            })
                                                                                                        } else {
                                                                                                            var _formValue = this.state.formValue
                                                                                                            var _dataSource = _formValue[this.state.copyFrom]
                                                                                                            var _dataTarget = _formValue[item.official_travel_process_emp_uuid]

                                                                                                            var types = [
                                                                                                                [1, 2, 3], //daily
                                                                                                                [1, 4], //hotel
                                                                                                                [1], //other
                                                                                                                [1], //representative
                                                                                                                [1, 4, 21, 24, 31, 34], //transport
                                                                                                            ]
                                                                                                            _dataTarget["kurs"] = _dataSource["kurs"]
                                                                                                            for (var t = 0; t < types.length; ++t) {
                                                                                                                var name = ""
                                                                                                                if (t === 0) {
                                                                                                                    name = "daily"
                                                                                                                } else if (t === 1) {
                                                                                                                    name = "hotel"
                                                                                                                } else if (t === 2) {
                                                                                                                    name = "other"
                                                                                                                } else if (t === 3) {
                                                                                                                    name = "representative"
                                                                                                                } else if (t === 4) {
                                                                                                                    name = "transport"
                                                                                                                }
                                                                                                                if (name !== "") {
                                                                                                                    for (var i = 0; i < types[t].length; ++i) {
                                                                                                                        _dataTarget[name]["items"][types[t][i]][1] = _dataSource[name]["items"][types[t][i]][1]
                                                                                                                        _dataTarget[name]["items"][types[t][i]][2] = _dataSource[name]["items"][types[t][i]][2]
                                                                                                                        _dataTarget[name]["items"][types[t][i]][3] = _dataSource[name]["items"][types[t][i]][3]
                                                                                                                    }
                                                                                                                    _dataTarget[name]["total"] = _dataSource[name]["total"]
                                                                                                                }
                                                                                                            }

                                                                                                            _formValue[item.official_travel_process_emp_uuid] = _dataTarget
                                                                                                            this.setState({
                                                                                                                formValue: _formValue,
                                                                                                            })
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <i className={this.state.copyFrom === item.official_travel_process_emp_uuid ? "uil-file-times-alt" : "uil-clipboard-alt"}></i>
                                                                                                </span>
                                                                                            ) : (
                                                                                                <Button
                                                                                                    className={"btn btn-soft-secondary btn-sm"}
                                                                                                    disabled={true}
                                                                                                    style={{
                                                                                                        padding: "2px 5px",
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="uil-file-copy-alt"></i>
                                                                                                </Button>
                                                                                            )}
                                                                                        </>
                                                                                    )}{" "}
                                                                                    <span
                                                                                        className="btn btn-warning btn-sm"
                                                                                        style={{
                                                                                            padding: "2px 5px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            var _formTemp = this.state.formValue
                                                                                            this.setState({
                                                                                                copyFrom: null,
                                                                                                showModal: true,
                                                                                                showModalType: _formTemp[item.official_travel_process_emp_uuid].oversea,
                                                                                                currentEdit: item.official_travel_process_emp_uuid,
                                                                                                currentName: item.employee_name,
                                                                                                formTemp: _formTemp[item.official_travel_process_emp_uuid],
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <i className="uil-pen"></i>
                                                                                    </span>{" "}
                                                                                    <span
                                                                                        className="btn btn-danger btn-sm"
                                                                                        style={{
                                                                                            padding: "2px 5px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                participantUuid: item.official_travel_process_emp_uuid,
                                                                                                deleteConfirm: true,
                                                                                            })

                                                                                            /* var _formTemp = this.state.formValue
                                                                                                this.setState({
                                                                                                    showModal: true,
                                                                                                    currentEdit: item.official_travel_process_emp_uuid,
                                                                                                    currentName: item.employee_name,
                                                                                                    formTemp: _formTemp[item.official_travel_process_emp_uuid]
                                                                                                }); */
                                                                                        }}
                                                                                    >
                                                                                        <i className="uil-trash-alt"></i>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </>
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button
                                                    color="success"
                                                    type="submit"
                                                    disabled={this.state.loadingEmployee}
                                                    onClick={() => {
                                                        this.setState({
                                                            action: ppk() === 1 ? "2" : "1",
                                                        })
                                                    }}
                                                >
                                                    <i className="uil-arrow-up-right"></i> {ppk() === 1 ? "Simpan dan Setujui" : "Ajukan"}
                                                </Button>{" "}
                                                <Button
                                                    color="success"
                                                    type="submit"
                                                    disabled={this.state.loadingEmployee}
                                                    onClick={() => {
                                                        this.setState({
                                                            action: "0",
                                                        })
                                                    }}
                                                >
                                                    <i className="uil-arrow-up-right"></i> Simpan Draft
                                                </Button>{" "}
                                                <Link to="/official-travel/submission.html">
                                                    <Button color="danger" type="button">
                                                        <i className="uil-arrow-left"></i> Kembali
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Detail Surat Tugas
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <OfficialTravelSourceComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>

                <Modal size="lg" isOpen={this.state.showModal}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Perbaharui Nilai Pengajuan untuk {this.state.currentName}
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showModal: false,
                                    showModalType: null,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {this.state.showModalType === 2 ? <OfficialTravelUpdateInterComponent formData={this.state.formTemp} closeModal={this.handlerCloseModal} changeValue={this.handlerChangeValue} /> : <OfficialTravelUpdateLocalComponent formData={this.state.formTemp} closeModal={this.handlerCloseModal} changeValue={this.handlerChangeValue} />}
                </Modal>

                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan menyimpan data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil disimpan" failedTitle="Gagal!" failedInfo="Data gagal disimpan" showConfirm={this.state.confirm} showLoading={this.state.loading} showSuccess={this.state.success} showFailed={this.state.failed} response={this.confirmResponse} />

                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan membatalkan data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil dibatalkan" failedTitle="Gagal!" failedInfo="Data gagal dibatalkan" showConfirm={this.state.deleteConfirm} showLoading={this.state.deleteLoading} showSuccess={this.state.deleteSuccess} showFailed={this.state.deleteFailed} response={this.deleteConfirmResponse} />

                <ConfirmAlert loadingTitle="Membuat file..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="File akan segera terunduh otomatis" failedTitle="Gagal!" failedInfo="File gagal dibuat, silahkan coba lagi" showLoading={this.state.minioLoading} showSuccess={this.state.minioSuccess} showFailed={this.state.minioFailed} />
                {this.state.redirect ? (
                    <>
                        <Redirect to="/official-travel/submission.html" />
                    </>
                ) : (
                    <></>
                )}
            </Fragment>
        )
    }
}

export default ProcessView
