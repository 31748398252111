import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'


class CreateBloc {

    employeeChannel = new Subject()
    employeeOperatorChannel = new Subject()
    employeeApproverChannel = new Subject()
    createChannel = new Subject()

    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.employeeChannel.next({ status: apiResponse.INITIAL })
        this.employeeOperatorChannel.next({ status: apiResponse.INITIAL })
        this.employeeApproverChannel.next({ status: apiResponse.INITIAL })
    }   

    fetchEmployeeList = async () => {
        this.employeeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeList().then((result) => {
                this.employeeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchEmployeeOperator = async () => {
        this.employeeOperatorChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeOperator().then((result) => {
                this.employeeOperatorChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeOperatorChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchEmployeeApprover = async () => {
        this.employeeApproverChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeApprover().then((result) => {
                this.employeeApproverChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeApproverChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }
}

export default CreateBloc