import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    Alert,
    Table
} from "reactstrap"
import { withRouter } from "react-router";
import apiResponse from '../../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'
import moment from 'moment'

import CreateBloc from '../Blocs/CreateBloc'
import { noConflict } from 'lodash';


class OfficialTravelConflictComponent extends Component {

    createBloc = new CreateBloc()

    constructor(props) {
        super(props)
        this.state = {
            uuid: null,
            headLoading: true,
            headData: null,
            tailLoading: true,
            tailData: null,

            conflictData: {},
            conflictUuid: null,
            conflictUse: '',
            processUuid: null,
            confirmText: '',

            noConflict: 0
        }
    }

    conflictSolver_0 = async () => {
        await this.createBloc.fetchConflictSolver_0({
            conflict: this.state.conflictUuid,
            use: this.state.conflictUse
        })
    }
    conflictSolver_1 = async () => {
        await this.createBloc.fetchConflictSolver_1({
            conflict: this.state.conflictUuid,
            use: this.state.conflictUse,
            process: this.state.processUuid
        })
    }

    confirmResponse_0 = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm_0: false
                }
                break;
            case 'confirm':
                forState = {
                    loading_0: true,
                    confirm_0: false
                }
                this.conflictSolver_0()
                break;
            case 'success':
                forState = {
                    success_0: false,
                    redirect_0: true
                }
                break;
            case 'failed':
                forState = {
                    failed_0: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    confirmResponse_1 = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm_1: false
                }
                break;
            case 'confirm':
                forState = {
                    loading_1: true,
                    confirm_1: false
                }
                this.conflictSolver_1()
                break;
            case 'success':
                forState = {
                    success_1: false,
                    redirect_1: true
                }
                break;
            case 'failed':
                forState = {
                    failed_1: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    confirmResponse_2 = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm_2: false
                }
                break;
            case 'confirm':
                forState = {
                    confirm_2: false
                }
                this.props.onRefund(
                    this.state.conflictUuid,
                    this.state.processUuid,
                    this.state.conflictUse,
                    this.state.confirmText,
                    this.state.conflictData
                )
                break;
            case 'success':
                forState = {
                    success_2: false,
                    redirect_2: true
                }
                break;
            case 'failed':
                forState = {
                    failed_2: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.conflictSolver_0Channel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        this.setState({
                            loading_0: false,
                            success_0: true,
                        });
                        this.props.onSolved()
                    } else {
                        this.setState({
                            loading_0: false,
                            failed_0: true,
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading_0: false,
                        failed_0: true,
                    });
                    break
                default:
                    break
            }
        })
        this.createBloc.conflictSolver_1Channel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        this.setState({
                            loading_1: false,
                            success_1: true,
                        });
                        this.props.onSolved()
                    } else {
                        this.setState({
                            loading_1: false,
                            failed_1: true,
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading_1: false,
                        failed_1: true,
                    });
                    break
                default:
                    break
            }
        })
        this.createBloc.conflictHeadChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        headLoading: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        headLoading: true
                    })
                    break
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            noConflict: response === null ? (this.state.noConflict + 1) : this.state.noConflict,
                            headData: response,
                            headLoading: false
                        }, () => {
                            if (this.state.noConflict === 2) {
                                this.props.onSolved()
                            }
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.conflictTailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        tailLoading: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        tailLoading: true
                    })
                    break
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            noConflict: response === null ? (this.state.noConflict + 1) : this.state.noConflict,
                            tailData: response,
                            tailLoading: false
                        }, () => {
                            if (this.state.noConflict === 2) {
                                this.props.onSolved()
                            }
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.createBloc.fetchConflictHead({ uuid: this.props.uuid, type: 'head' })
        this.createBloc.fetchConflictTail({ uuid: this.props.uuid, type: 'tail' })
    }

    componentWillUnmount() {
        this.createBloc.conflictSolver_0Channel.unsubscribe()
        this.createBloc.conflictSolver_1Channel.unsubscribe()
        this.createBloc.conflictHeadChannel.unsubscribe()
        this.createBloc.conflictTailChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row className="mb-3">
                        <label className="col-md-12">
                            Irisan Tanggal Awal
                        </label>
                        <Col sm={12}>
                            {this.state.headLoading ?
                                <Shimmer><div className="shimmer" style={{ width: '100%', height: 47.59 }}></div></Shimmer>
                                :
                                <>
                                    {this.state.headData === null ?
                                        <Alert color="success" className="text-center">
                                            Tidak ada irisan
                                        </Alert>
                                        :
                                        <>
                                            {this.state.headData.official_travel_conflict_status === 2 ?
                                                <Alert color="warning" className="text-center">
                                                    Dalam proses pengembalian
                                                </Alert>
                                                :
                                                <>
                                                    <div style={{ 'padding-left': '10px', 'padding-bottom': '5px', 'margin-bottom': '5px', 'border-bottom': '1px solid #dddddd' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tr>
                                                                <td width="120px">Nomor ST</td>
                                                                <td width="10px">:</td>
                                                                <td width="*"><b>{this.state.headData.official_travel_number_tail}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tanggal</td>
                                                                <td>:</td>
                                                                <td>{moment(this.state.headData.official_travel_participant_date_start_tail).format('Do MMM YYYY')} <i className="uil-arrow-right"></i> <b>{moment(this.state.headData.official_travel_participant_date_end_tail).format('Do MMM YYYY')}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tempat Dinas</td>
                                                                <td>:</td>
                                                                <td>
                                                                    <>
                                                                        {this.state.headData.official_travel_participant_oversea_tail === 1 ? ('Dalam Negeri' + (this.state.headData.official_travel_participant_domicile_tail === 1 ? ' (Dalam Kota)' : ' (Luar Kota)')) : 'Luar Negeri'}
                                                                    </>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tujuan</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {this.state.headData.official_travel_participant_destination_tail !== " - " ? 
                                                                        <div>{this.state.headData.official_travel_participant_destination_tail}</div>
                                                                    : this.state.headData.official_travel_participant_oversea_tail === 1 ? 
                                                                        this.state.headData.destination.map((item, index) => {
                                                                            return <div key={index}>{item.city_name}, {item.province_name}</div>
                                                                        }) 
                                                                    : this.state.headData.destination.map((item, index) => {
                                                                        return <div key={index}>{item.country_name}</div>
                                                                    })}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Status</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {this.state.headData.official_travel_process_status_tail === null ?
                                                                        <div className="bd-highlight float-start">
                                                                            <div style={{ paddingTop: 2 }}>
                                                                                <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Belum diproses
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            {this.state.headData.official_travel_process_status_tail === 7 ?
                                                                                <div className="bd-highlight float-start">
                                                                                    <div style={{ paddingTop: 2 }}>
                                                                                        <div className="bg-danger rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sudah dibayar
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="bd-highlight float-start">
                                                                                    <div style={{ paddingTop: 2 }}>
                                                                                        <div className="bg-warning rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sedang diproses
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="text-center">Uang harian tanggal {moment(this.state.headData.official_travel_participant_date_end_tail).format('LL')} akan dibayarkan pada ST:</div>
                                                    <table style={{ width: '100%', 'margin-top': '10px' }}>
                                                        <tr>
                                                            <td className="text-center" style={{ width: '50%' }}>
                                                                <span
                                                                    className="btn btn-primary btn-sm"
                                                                    style={{
                                                                        padding: '2px 14px'
                                                                    }}
                                                                    onClick={() => {
                                                                        if (this.state.headData.official_travel_process_status_tail === null) {
                                                                            this.setState({
                                                                                conflictUuid: this.state.headData.official_travel_conflict_uuid,
                                                                                conflictUse: 'head',
                                                                                confirmText: 'Apakah anda yakin uang harian tanggal ' + moment(this.state.headData.official_travel_participant_date_end_tail).format('LL') + ' akan dibayarkan pada ST ' + this.state.headData.official_travel_number_head + '?',
                                                                                confirm_0: true
                                                                            })
                                                                        } else if (this.state.headData.official_travel_process_status_tail === 7) {
                                                                            this.setState({
                                                                                conflictUuid: this.state.headData.official_travel_conflict_uuid,
                                                                                processUuid: this.state.headData.official_travel_participant_official_travel_process_uuid_tail,
                                                                                conflictUse: 'head',
                                                                                confirmText: 'Apakah anda yakin uang harian tanggal ' + moment(this.state.headData.official_travel_participant_date_end_tail).format('LL') + ' akan dibayarkan pada ST ' + this.state.headData.official_travel_number_head + ', dan akan melakukan pengembalian dana untuk ST ' + this.state.headData.official_travel_number_tail + ' yang sudah dibayarkan?',
                                                                                conflictData: {
                                                                                    number: this.state.headData.official_travel_number_tail,
                                                                                    date_start: moment(this.state.headData.official_travel_participant_date_start_tail).format('Do MMM YYYY'),
                                                                                    date_end: moment(this.state.headData.official_travel_participant_date_end_tail).format('Do MMM YYYY'),
                                                                                    place: this.state.headData.official_travel_participant_oversea_tail === 1 ? ('Dalam Negeri' + (this.state.headData.official_travel_participant_domicile_tail === 1 ? ' (Dalam Kota)' : ' (Luar Kota)')) : 'Luar Negeri',
                                                                                    destination: this.state.headData.official_travel_participant_destination_tail,
                                                                                    status: this.state.headData.official_travel_process_status_tail
                                                                                },
                                                                                confirm_2: true
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                conflictUuid: this.state.headData.official_travel_conflict_uuid,
                                                                                processUuid: this.state.headData.official_travel_participant_official_travel_process_uuid_tail,
                                                                                conflictUse: 'head',
                                                                                confirmText: 'Apakah anda yakin uang harian tanggal ' + moment(this.state.headData.official_travel_participant_date_end_tail).format('LL') + ' akan dibayarkan pada ST ' + this.state.headData.official_travel_number_head + ', dan akan menyesuaikan jumlah hari untuk ST ' + this.state.headData.official_travel_number_tail + ' yang sedang diproses?',
                                                                                confirm_1: true
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="uil-check-circle"></i> {this.state.headData.official_travel_number_head}
                                                                </span>
                                                            </td>
                                                            <td className="text-center" style={{ width: '50%' }}>
                                                                <span
                                                                    className="btn btn-danger btn-sm"
                                                                    style={{
                                                                        padding: '2px 14px'
                                                                    }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            conflictUuid: this.state.headData.official_travel_conflict_uuid,
                                                                            conflictUse: 'tail',
                                                                            confirmText: 'Apakah anda yakin tanggal ' + moment(this.state.headData.official_travel_participant_date_end_tail).format('LL') + ' akan digunakan untuk ST ' + this.state.headData.official_travel_number_tail + '?',
                                                                            confirm_0: true
                                                                        })


                                                                    }}
                                                                >
                                                                    <i className="uil-check-circle"></i> {this.state.headData.official_travel_number_tail}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-12">
                            Irisan Tanggal Akhir
                        </label>
                        <Col sm={12}>
                            {this.state.tailLoading ?
                                <Shimmer><div className="shimmer" style={{ width: '100%', height: 47.59 }}></div></Shimmer>
                                :
                                <>
                                    {this.state.tailData === null ?
                                        <Alert color="success" className="text-center">
                                            Tidak ada irisan
                                        </Alert>
                                        :
                                        <>
                                            {this.state.tailData.official_travel_conflict_status === 2 ?
                                                <Alert color="warning" className="text-center">
                                                    Dalam proses pengembalian
                                                </Alert>
                                                :
                                                <>
                                                    <div style={{ 'padding-left': '10px', 'padding-bottom': '5px', 'margin-bottom': '5px', 'border-bottom': '1px solid #dddddd' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tr>
                                                                <td width="120px">Nomor ST</td>
                                                                <td width="10px">:</td>
                                                                <td width="*"><b>{this.state.tailData.official_travel_number_head}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tanggal</td>
                                                                <td>:</td>
                                                                <td><b>{moment(this.state.tailData.official_travel_participant_date_start_head).format('Do MMM YYYY')}</b> <i className="uil-arrow-right"></i> {moment(this.state.tailData.official_travel_participant_date_end_head).format('Do MMM YYYY')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tempat Dinas</td>
                                                                <td>:</td>
                                                                <td>
                                                                    <>
                                                                        {this.state.tailData.official_travel_participant_oversea_head === 1 ? ('Dalam Negeri' + (this.state.tailData.official_travel_participant_domicile_head === 1 ? ' (Dalam Kota)' : ' (Luar Kota)')) : 'Luar Negeri'}
                                                                    </>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tujuan</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {this.state.tailData.official_travel_participant_destination_head !== " - " ? 
                                                                        <div>{this.state.tailData.official_travel_participant_destination_head}</div>
                                                                    : this.state.tailData.official_travel_participant_oversea_head === 1 ? 
                                                                        this.state.tailData.destination.map((item, index) => {
                                                                            return <div key={index}>{item.city_name}, {item.province_name}</div>
                                                                        }) 
                                                                    : this.state.tailData.destination.map((item, index) => {
                                                                        return <div key={index}>{item.country_name}</div>
                                                                    })}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Status</td>
                                                                <td>:</td>
                                                                <td>
                                                                    {this.state.tailData.official_travel_process_status_head === null ?
                                                                        <div className="bd-highlight float-start">
                                                                            <div style={{ paddingTop: 2 }}>
                                                                                <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Belum diproses
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            {this.state.tailData.official_travel_process_status_head === 7 ?
                                                                                <div className="bd-highlight float-start">
                                                                                    <div style={{ paddingTop: 2 }}>
                                                                                        <div className="bg-danger rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sudah dibayar
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="bd-highlight float-start">
                                                                                    <div style={{ paddingTop: 2 }}>
                                                                                        <div className="bg-warning rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sedang diproses
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="text-center">Uang harian tanggal {moment(this.state.tailData.official_travel_participant_date_start_head).format('LL')} akan dibayarkan pada ST:</div>
                                                    <table style={{ width: '100%', 'margin-top': '10px' }}>
                                                        <tr>
                                                            <td className="text-center" style={{ width: '50%' }}>
                                                                <span
                                                                    className="btn btn-danger btn-sm"
                                                                    style={{
                                                                        padding: '2px 14px'
                                                                    }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            conflictUuid: this.state.tailData.official_travel_conflict_uuid,
                                                                            conflictUse: 'head',
                                                                            confirmText: 'Apakah anda yakin tanggal ' + moment(this.state.tailData.official_travel_participant_date_start_head).format('LL') + ' akan digunakan untuk ST ' + this.state.tailData.official_travel_number_head + '?',
                                                                            confirm_0: true
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="uil-check-circle"></i> {this.state.tailData.official_travel_number_head}
                                                                </span>
                                                            </td>
                                                            <td className="text-center" style={{ width: '50%' }}>
                                                                <span
                                                                    className="btn btn-primary btn-sm"
                                                                    style={{
                                                                        padding: '2px 14px'
                                                                    }}
                                                                    onClick={() => {
                                                                        if (this.state.tailData.official_travel_process_status_head === null) {
                                                                            this.setState({
                                                                                conflictUuid: this.state.tailData.official_travel_conflict_uuid,
                                                                                conflictUse: 'tail',
                                                                                confirmText: 'Apakah anda yakin uang harian tanggal ' + moment(this.state.tailData.official_travel_participant_date_start_head).format('LL') + ' akan dibayarkan pada ST ' + this.state.tailData.official_travel_number_tail + '?',
                                                                                confirm_0: true
                                                                            })
                                                                        } else if (this.state.tailData.official_travel_process_status_head === 7) {
                                                                            this.setState({
                                                                                conflictUuid: this.state.tailData.official_travel_conflict_uuid,
                                                                                processUuid: this.state.tailData.official_travel_participant_official_travel_process_uuid_head,
                                                                                conflictUse: 'tail',
                                                                                confirmText: 'Apakah anda yakin uang harian tanggal ' + moment(this.state.tailData.official_travel_participant_date_start_head).format('LL') + ' akan dibayarkan pada ST ' + this.state.tailData.official_travel_number_tail + ', dan akan melakukan pengembalian dana untuk ST ' + this.state.tailData.official_travel_number_head + ' yang sudah dibayarkan?',
                                                                                conflictData: {
                                                                                    number: this.state.tailData.official_travel_number_head,
                                                                                    date_start: moment(this.state.tailData.official_travel_participant_date_start_head).format('Do MMM YYYY'),
                                                                                    date_end: moment(this.state.tailData.official_travel_participant_date_end_head).format('Do MMM YYYY'),
                                                                                    place: this.state.tailData.official_travel_participant_oversea_head === 1 ? ('Dalam Negeri' + (this.state.tailData.official_travel_participant_domicile_head === 1 ? ' (Dalam Kota)' : ' (Luar Kota)')) : 'Luar Negeri',
                                                                                    destination: this.state.tailData.official_travel_participant_destination_head,
                                                                                    status: this.state.tailData.official_travel_process_status_head
                                                                                },
                                                                            }, () => {
                                                                                this.setState({
                                                                                    confirm_2: true
                                                                                })
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                conflictUuid: this.state.tailData.official_travel_conflict_uuid,
                                                                                processUuid: this.state.tailData.official_travel_participant_official_travel_process_uuid_head,
                                                                                conflictUse: 'tail',
                                                                                confirmText: 'Apakah anda yakin uang harian tanggal ' + moment(this.state.tailData.official_travel_participant_date_start_head).format('LL') + ' akan dibayarkan pada ST ' + this.state.tailData.official_travel_number_tail + ', dan akan menyesuaikan jumlah hari untuk ST ' + this.state.tailData.official_travel_number_head + ' yang sedang diproses?',
                                                                                confirm_1: true
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="uil-check-circle"></i> {this.state.tailData.official_travel_number_tail}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.confirmText}

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm_0}
                    showLoading={this.state.loading_0}
                    showSuccess={this.state.success_0}
                    showFailed={this.state.failed_0}

                    response={this.confirmResponse_0}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.confirmText}

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm_1}
                    showLoading={this.state.loading_1}
                    showSuccess={this.state.success_1}
                    showFailed={this.state.failed_1}

                    response={this.confirmResponse_1}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.confirmText}

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm_2}
                    showLoading={this.state.loading_2}
                    showSuccess={this.state.success_2}
                    showFailed={this.state.failed_2}

                    response={this.confirmResponse_2}
                />
            </Fragment >
        )
    }
}

export default withRouter(OfficialTravelConflictComponent);
