import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import Shimmer from "react-shimmer-effect"
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, useHistory, Redirect } from "react-router-dom"

import apiResponse from '../../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'


class IndexView extends Component {

    createBloc = new CreateBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            messageError: "",
        }
    }

    submitData = async () => {
        await this.createBloc.fetchCreate(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        // Transform the form values to match the database structure
        const formData = {
            operator_uuid: values.operator_uuid,
            approver_uuid: values.approver_uuid,
            ppspm_code: values.ppspm_code
        }
        
        this.setState({
            dataForm: formData,
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: result.data.status,
                        messageError: result.data.status === false ? result.data.message : ""
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.getLayananChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingCurrent: true
                    });
                    break;
                case apiResponse.COMPLETED:
                    const response = result.data.response;
        
                    // Memetakan response menjadi opsi untuk CustomSelect
                    const layananOptions = response.map(item => ({
                        value: item.dashboard_pegawai_id,
                        label: item.dashboard_pegawai_name
                    }));
        
                    this.setState({
                        loadingCurrent: false,
                        layanan: layananOptions
                    });
                    break;
                case apiResponse.ERROR:
                    this.setState({
                        loadingCurrent: false
                    });
                    break;
                default:
                    break;
            }
        });
        
        this.createBloc.fetchGetLayanan();

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingCurrent: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingCurrent: false,
                        employees: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchEmployeeList()
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.getLayananChannel?.unsubscribe()
        this.createBloc.employeeChannel?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">

                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Data Operator & Approver</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />

                                  

                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="ppspm_code"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode PPSPM<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="ppspm_code"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Masukkan Kode PPSPM"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Kode PPSPM wajib diisi' },
                                                        pattern: { value: '^[0-9]{1,6}$', errorMessage: 'Hanya boleh angka dan maksimal 6 digit' },
                                                        maxLength: { value: 6, errorMessage: 'Maksimal 6 digit' }
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Operator<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                {
                                                this.state.loadingCurrent?
                                                    <><Shimmer><div className="shimmer" style={{width: '100%', height: 36}}></div></Shimmer>{" "}</>
                                                :
                                                    <>
                                                        <CustomSelect 
                                                            name="operator_uuid"
                                                            errorMessage="Pilih Nama Operator"
                                                            options={this.state.employees} 
                                                            placeholder="Pilih Nama Operator"
                                                            optionLabel="employee_name"
                                                            optionValue="employee_uuid"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </>
                                                }
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Approver<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                {
                                                this.state.loadingCurrent?
                                                    <><Shimmer><div className="shimmer" style={{width: '100%', height: 36}}></div></Shimmer>{" "}</>
                                                :
                                                    <>
                                                        <CustomSelect 
                                                            name="approver_uuid"
                                                            errorMessage="Pilih Nama Approver"
                                                            options={this.state.employees} 
                                                            placeholder="Pilih Nama Approver"
                                                            optionLabel="employee_name"
                                                            optionValue="employee_uuid"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                       
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <Button color="danger" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/official-travel/mapping-ppspm.html">
                                                    <Button color="success"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/official-travel/mapping-ppspm.html'/>
                : null }
            </Fragment>
        )
    }

}

export default IndexView