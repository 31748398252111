import {
    apiRequest,
    officialTravelPpkEmployee,
    officialTravelPpkEmployeeOperator,
    officialTravelPpkEmployeeApprover,
    officialTravelPpkCreate
} from "../../../../../services/adapters/process"

class CreateRepository {

    fetchEmployeeList = async () => {
        return await apiRequest("get", officialTravelPpkEmployee, {}, true)
    }

    fetchEmployeeOperator = async () => {
        return await apiRequest("get", officialTravelPpkEmployeeOperator, {}, true)
    }

    fetchEmployeeApprover = async () => {
        return await apiRequest("get", officialTravelPpkEmployeeApprover, {}, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", officialTravelPpkCreate, {
            body: query
        }, true)
    }
}

export default CreateRepository