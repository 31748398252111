import {
    apiRequest,
    payrollHonorariumCheckData,
    payrollSkpGetSigner,
    payrollHonorariumDetail,
    payrollHonorariumProcess
} from "../../../../../../services/adapters/process"

class UpdateRepository {
    fetchCheckTunkin = async (query) => {
        return await apiRequest("get", payrollHonorariumCheckData, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", payrollSkpGetSigner, {
            params: query
        }, true)
    }

    fetchApprove = async (query) => {
        return await apiRequest("post", payrollHonorariumProcess, {
            body: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", payrollHonorariumDetail, {
            params: query
        }, true)
    }
}

export default UpdateRepository