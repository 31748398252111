import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect";

//import { YearSelect, MonthSelect } from '../../../../../helpers/ui/YearSelect'
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Label,
    Modal,
    ModalHeader, 
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import CreateBloc from './Blocs/CreateBloc'

const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
];
class PayrollCreateView extends Component {

    createBloc = new CreateBloc();
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear()-1 : this.thisDate.getFullYear()
    thisMonth = this.thisDate.getMonth() + 1

    

    constructor(props) {
        super(props)
        const userdata = JSON.parse(localStorage.getItem('userdata')) || {};
        const employee_nip = userdata.employee_nip || null;

        this.state = {
            dataForm: {},
            year: null,
            month: null,
            gpp: null,
            employee_nip: employee_nip,
            years: [],
            months: [],
            loadingYear: true,
            loadingMonth: false,
            jumlah: 3,
            count: 3,
            accessId: localStorage.getItem('currentRoleId'),
            loadingEmployee: true,
            employees: [],
            checkTunkin: [],
            loadingCheckTunkin: true,
            checkTunkinStatus: '',
            showDetail: null,
            dataDetail: {
                tunkin: 0,
                uangmakan: 0,
                tunkin13: 0,
                gaji13: 0,
                gpp: null,
            },
            detail: null,
            loadingDetail: true,
            selectedMonthNumber: null,
            selectedMonthName: null,
            selectedYear: null,
            loadingFetch: false,
        }
    }

    submitData = async () => {
        await this.createBloc.fetchCreate(this.state.dataForm)
    }

    loadPayrollReadyYear = async (uuid) => {
        await this.createBloc.fetchReadyYear({uuid: uuid})
    }

    loadPayrollReadyMonth = async (year) => {
        const result = await this.createBloc.fetchReadyMonth({ year: year, uuid: this.state.employee_nip });
        console.log(result);
    }

    loadPayrollReadyGPP = async (month) => {
        await this.createBloc.fetchCheckTunkin({ uuid: this.state.employee_nip, year: this.state.year, month: month, jumlah: this.state.jumlah })
        this.setState({ loadingFetch: false })
    }

    loadPayrollReadyGPPJml = async (jumlah) => {
        await this.createBloc.fetchCheckTunkin({ uuid: this.state.employee_nip, year: this.state.year, month: this.state.month, jumlah: jumlah })
        this.setState({ loadingFetch: false })
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()

        let year = this.state.year 
        let month = this.state.month
        let jumlah = this.state.jumlah
        var _count = 0;
        
        for (let i = 0; i < jumlah; i++) {
            if ((month - i) <= 0) {
                if((year - 1) >= 2021) {
                    ++_count;
                }
            } else {
                ++_count;
            }
        }
        this.setState({
            dataForm: {
              ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    /* listPengajuan = () => {
        let year = this.state.year 
        let month = this.state.month
        let jumlah = this.state.jumlah

        let list = []
        for (let i = 0; i < jumlah; i++) {
            if ((month - i) <= 0) {
                list.push({ year: year - 1, month: 12 + (month - i) })
            } else {
                list.push({ year: year, month: month - i })
            }
        }
        return list
    } */

    listPengajuan = () => {
        let year = this.state.year 
        let month = this.state.month
        let jumlah = this.state.jumlah

        let list = []
        if(year !== null && month !== null) {
        //var _count = 0;

            for (let i = 0; i < jumlah; i++) {
                if ((month - i) <= 0) {
                    if((year - 1) >= 2021) {
                        list.push({ year: year - 1, month: 12 + (month - i) })
                        //++_count;
                    }
                } else {
                    list.push({ year: year, month: month - i })
                    //++_count;
                }
            }
        }
        /* this.setState({
            count: _count
        }) */
        return list
    }

    componentDidMount() {
        this.createBloc.checkTunkinChannel.subscribe((result) => {
            switch(result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response

                    this.setState({ 
                        checkTunkin: response.result,
                        checkTunkinStatus: response.pass,
                        loadingFetch: false
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        checkTunkinStatus: null,
                        loadingFetch: false
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.readyYearChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingYear: false,
                        years: response
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.createBloc.readyMonthChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingMonth: false,
                        months: response
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingEmployee: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    console.log('apa ini', response);
                    this.setState({
                        loadingEmployee: false,
                        employees: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })
        if(this.state.accessId === '2') {
            this.createBloc.fetchEmployee()
            this.setState({
                loadingYear: false
            })
        } else {
            this.loadPayrollReadyYear(this.state.employee_nip)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.year !== this.state.year) {
            this.listPengajuan()
        }
        if (prevState.month !== this.state.month) {
            this.listPengajuan()
        }
        if (prevState.jumlah !== this.state.jumlah) {
            this.listPengajuan()
        }
    }

    componentWillUnmount() {
        this.createBloc.checkTunkinChannel?.unsubscribe()
        this.createBloc.createChannel.unsubscribe()
        this.createBloc.readyYearChannel.unsubscribe()
        this.createBloc.readyMonthChannel.unsubscribe()
        this.createBloc.employeeChannel?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Pengajuan Honorarium Tetap</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        {
                                            this.state.accessId === '2' ? 
                                                <Row className="mb-3">
                                                    <Label
                                                        htmlFor="fieldNama"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Nama Pegawai<span className="text-danger">*</span>
                                                    </Label>
                                                    <Col md="6">
                                                        {
                                                            this.state.loadingEmployee?
                                                                <><Shimmer><div className="shimmer" style={{width: '100%', height: 38}}></div></Shimmer>{" "}</>
                                                            :
                                                                <>
                                                                    <CustomSelect 
                                                                        name="uuid"
                                                                        errorMessage="Pilih Pegawai"
                                                                        options={this.state.employees} 
                                                                        placeholder="Pilih Pegawai"
                                                                        optionLabel="employee_name"
                                                                        optionValue="employee_uuid"
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(value) => {
                                                                            this.setState({
                                                                                year: null,
                                                                                years: [],
                                                                                loadingYear: true,
                                                                                loadingFetch: true,
                                                                                month: null,
                                                                                months: [],
                                                                                loadingMonth: true,
                                                                                employee_nip: value.employee_nip
                                                                            })
                                                                            setTimeout(() => {
                                                                                this.setState({
                                                                                    loadingMonth: false
                                                                                })
                                                                            }, 1)
                                                                            this.loadPayrollReadyYear(value.employee_nip)
                                                                        }}
                                                                    />
                                                                    <small className="text-danger">Pegawai Honorer (Non PNS) tidak dapat mengajukan honorarium tetap.</small>
                                                                </>
                                                            }
                                                    </Col>
                                                </Row>
                                            : <></>
                                        }
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Tahun<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingYear ?
                                                        <Shimmer><div className="shimmer" style={{width: '100%', height: 33}}></div></Shimmer>
                                                    :
                                                        <CustomSelect 
                                                            name="year"
                                                            errorMessage="Pilih Tahun"
                                                            options={this.state.years} 
                                                            placeholder="Pilih Tahun"
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            validate={{ required: { value: true } }}
                                                            onChange={(value) => {
                                                                this.setState({
                                                                    year: value.value,
                                                                    month: null,
                                                                    months: [],
                                                                    loadingMonth: true
                                                                })
                                                                this.loadPayrollReadyMonth(value.value)
                                                            }}
                                                        />
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Bulan<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingMonth ?
                                                        <Shimmer><div className="shimmer" style={{width: '100%', height: 33}}></div></Shimmer>
                                                    :
                                                        <CustomSelect 
                                                            name="month"
                                                            errorMessage="Pilih Bulan"
                                                            options={this.state.months} 
                                                            placeholder="Pilih Bulan"
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            validate={{ required: { value: true } }}
                                                            onChange={
                                                                opt => {
                                                                    this.setState({ month: opt.value, loadingFetch: true });
                                                                    this.loadPayrollReadyGPP(opt.value);
                                                                }
                                                            }
                                                        />
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Jumlah<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <CustomSelect 
                                                    name="jumlah"
                                                    options={[{value: 1, label: '1 Bulan'}, {value: 3, label: '3 Bulan'}, {value: 6, label: '6 Bulan'}, {value: 12, label: '12 Bulan'}]}
                                                    defaultValue={this.state.jumlah}
                                                    onChange={
                                                        opt => {
                                                            this.setState({ jumlah: opt.value, loadingFetch: true });
                                                            this.loadPayrollReadyGPPJml(opt.value);
                                                        }
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Pengajuan Honorarium Tetap
                                            </Label>
                                            <Col md="8">
                                            <small><i>Data yang dapat diajukan minimal bulan Januari 2021</i></small>
                                                <Table
                                                    className="table table-striped table-bordered table-hover"
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="50px" data-priority="1">No</Th>
                                                            <Th width="*" data-priority="3">Bulan</Th>
                                                            <Th width="*" data-priority="1">Tahun</Th>
                                                            <Th width="*" data-priority="1">GPP</Th>
                                                        </Tr>
                                                    </Thead>
                                                    {
                                                        <Tbody>
                                                            { 
                                                                this.state.loadingFetch ?
                                                                    <Tr>
                                                                        <Td colSpan="4">
                                                                            <Shimmer>
                                                                                <div className="shimmer" style={{width: '100%', height: 33}}></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                    </Tr>
                                                                :
                                                                this.state.checkTunkin?.map((val, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{i+1}</td>
                                                                            <td>{months[val.month - 1]}</td>
                                                                            <td>{val.year}</td>
                                                                            <td>
                                                                                {
                                                                                    val.available ?
                                                                                        <div
                                                                                            class="link-primary"
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    showDetail: true,
                                                                                                    selectedMonthNumber: val.month,
                                                                                                    selectedMonthName: months[val.month - 1],
                                                                                                    selectedYear: val.year,
                                                                                                    dataDetail: val.data
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            Tersedia
                                                                                        </div>
                                                                                    : "Tidak Tersedia"
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </Tbody>
                                                    }
                                                </Table>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mb-3">
                                            <Col md="1"></Col>
                                            <Col md="6">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/payroll/honorarium-tetap.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>


                                    </AvForm>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody style={{height: 100}}>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <ModalHeader 
                        toggle={() => {
                            this.setState({
                                showDetail: !this.state.showDetail
                            })
                        }}
                    >
                        Detail Honorarium Tetap bulan { this.state.selectedMonthName } { this.state.selectedYear }
                    </ModalHeader>
                    <ModalBody>
                        { this.state.showDetail ?
                        <table>
                            <tr>
                                <td width="5%" style={{fontWeight: 'bold'}}>I.</td>
                                <td style={{fontWeight: 'bold'}} colspan="6">GAJI</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td width="5%">I.A.</td>
                                <td colspan="4">PENGHASILAN</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td width="35%">Gaji Pokok</td>
                                <td width="2%">:</td>
                                <td width="3%">Rp</td>
                                <td className="text-end" width="20%">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.gapok.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td width="10%"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Anak</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tanak.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Suami/Istri</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tistri.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Umum</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tumum.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Jabatan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? ((this.state.dataDetail.gpp.tstruktur*1)+(this.state.dataDetail.gpp.tfungsi*1)).toString().replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Beras</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tberas.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Pph</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tpajak.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Pembulatan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.bulat.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                                <td>JUMLAH PENGHASILAN</td>
                                <td className="text-end" colspan="4">Rp</td>
                                <td className="text-end" width="20%">{ this.state.dataDetail.gpp !== null ? ((this.state.dataDetail.gpp?.gapok !== null ? (this.state.dataDetail.gpp.gapok*1) : 0)+(this.state.dataDetail.gpp?.tanak !== null ? (this.state.dataDetail.gpp.tanak*1) : 0)+(this.state.dataDetail.gpp?.tistri !== null ? (this.state.dataDetail.gpp.tistri*1) : 0)+(this.state.dataDetail.gpp?.tumum !== null ? (this.state.dataDetail.gpp.tumum*1) : 0)+(this.state.dataDetail.gpp?.tstruktur !== null ? (this.state.dataDetail.gpp.tstruktur*1) : 0)+(this.state.dataDetail.gpp?.tfungsi !== null ? (this.state.dataDetail.gpp.tfungsi*1) : 0)+(this.state.dataDetail.gpp?.tberas !== null ? (this.state.dataDetail.gpp.tberas*1) : 0)+(this.state.dataDetail.gpp?.tpajak !== null ? (this.state.dataDetail.gpp.tpajak*1) : 0)+(this.state.dataDetail.gpp?.bulat !== null ? (this.state.dataDetail.gpp.bulat*1) : 0) * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0" }</td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <div style={{height: '8px'}}></div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>I.B.</td>
                                <td colspan="4">POTONGAN</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Iuran Wajib Pegawai</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.iwp.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Iuran BPJS</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.bpjs.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Iuran BPJS 2</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.bpjs2.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Iuran Perumahan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.taperum.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Sewa Rumah</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.sewarmh.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Potongan PPh 21</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.pph.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Potongan Lain</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.potlain.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                                <td>JUMLAH POTONGAN</td>
                                <td className="text-end" colspan="4">Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? ((this.state.dataDetail.gpp?.iwp !== null ? (this.state.dataDetail.gpp.iwp*1) : 0)+(this.state.dataDetail.gpp?.bpjs !== null ? (this.state.dataDetail.gpp.bpjs*1) : 0)+(this.state.dataDetail.gpp?.bpjs2 !== null ? (this.state.dataDetail.gpp.bpjs2*1) : 0)+(this.state.dataDetail.gpp?.taperum !== null ? (this.state.dataDetail.gpp.taperum*1) : 0)+(this.state.dataDetail.gpp?.sewarmh !== null ? (this.state.dataDetail.gpp.sewarmh*1) : 0)+(this.state.dataDetail.gpp?.pph !== null ? (this.state.dataDetail.gpp.pph*1) : 0)+(this.state.dataDetail.gpp?.potlain !== null ? (this.state.dataDetail.gpp.potlain*1) : 0) * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0"}</td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <div style={{height: '6px'}}></div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colspan="2">GAJI BERSIH</td>
                                <td className="text-end" colspan="4">Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? (
            (
                [
                    this.state.dataDetail.gpp?.gapok,
                    this.state.dataDetail.gpp?.tanak,
                    this.state.dataDetail.gpp?.tistri,
                    this.state.dataDetail.gpp?.tumum,
                    this.state.dataDetail.gpp?.tstruktur,
                    this.state.dataDetail.gpp?.tfungsi,
                    this.state.dataDetail.gpp?.tberas,
                    this.state.dataDetail.gpp?.tpajak,
                    this.state.dataDetail.gpp?.bulat
                ].reduce((total, item) => total + (item !== null ? item*1 : 0), 0)
                -
                [
                    this.state.dataDetail.gpp?.iwp,
                    this.state.dataDetail.gpp?.bpjs,
                    this.state.dataDetail.gpp?.bpjs2,
                    this.state.dataDetail.gpp?.taperum,
                    this.state.dataDetail.gpp?.sewarmh,
                    this.state.dataDetail.gpp?.pph,
                    this.state.dataDetail.gpp?.potlain
                ].reduce((total, item) => total + (item !== null ? item*1 : 0), 0)
            )
            .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        ) : "0" }</td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <div style={{height: '8px'}}></div>
                                </td>
                            </tr>
                            {/* <tr>
                                <td style={{fontWeight: 'bold'}}>II.</td>
                                <td style={{fontWeight: 'bold'}} colspan="6">PENGHASILAN LAIN-LAIN</td>
                            </tr> */}
                            {/* <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Kinerja</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.tunkin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                                <td></td>
                            </tr> */}
                            {/* <tr>
                                <td></td>
                                <td>-</td>
                                <td>Uang Makan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.uangmakan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Gaji Ke-13/THR</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gaji13.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                                <td></td>
                            </tr> */}
                            {/* <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunkin Ke-13/THR</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.tunkin13.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                                <td></td>
                            </tr> */}
                            {/* <tr>
                                <td></td>
                                <td colspan="2">JUMLAH PENGHASILAN LAIN-LAIN</td>
                                <td className="text-end" colspan="4">Rp</td>
                                <td className="text-end">{ (this.state.dataDetail.tunkin+this.state.dataDetail.uangmakan+this.state.dataDetail.gaji13+this.state.dataDetail.tunkin13).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                            </tr> */}
                            <tr>
                                <td colspan="7">
                                    <div style={{height: '6px'}}></div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style={{fontWeight: 'bold'}}>TOTAL PENGHASILAN</td>
                                <td className="text-end" colspan="4" style={{fontWeight: 'bold'}}>Rp</td>
                                <td className="text-end" style={{fontWeight: 'bold'}}>
                                    { this.state.dataDetail.gpp !== null ? (
                                        (
                                            [
                                                this.state.dataDetail.gpp?.gapok,
                                                this.state.dataDetail.gpp?.tanak,
                                                this.state.dataDetail.gpp?.tistri,
                                                this.state.dataDetail.gpp?.tumum,
                                                this.state.dataDetail.gpp?.tstruktur,
                                                this.state.dataDetail.gpp?.tfungsi,
                                                this.state.dataDetail.gpp?.tberas,
                                                this.state.dataDetail.gpp?.tpajak,
                                                this.state.dataDetail.gpp?.bulat
                                            ].reduce((total, item) => total + (item !== null ? item*1 : 0), 0)
                                            -
                                            [
                                                this.state.dataDetail.gpp?.iwp,
                                                this.state.dataDetail.gpp?.bpjs,
                                                this.state.dataDetail.gpp?.bpjs2,
                                                this.state.dataDetail.gpp?.taperum,
                                                this.state.dataDetail.gpp?.sewarmh,
                                                this.state.dataDetail.gpp?.pph,
                                                this.state.dataDetail.gpp?.potlain
                                            ].reduce((total, item) => total + (item !== null ? item*1 : 0), 0)
                                        )
                                        .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    ) : "0" }
                                </td>
                            </tr>
                        </table>
                        : <></>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            color="danger"
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                        >
                            Tutup
                        </Button>
                    </ModalFooter>
                </Modal> 


                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/payroll/honorarium-tetap.html'/>
                : null }

            </Fragment>
        )
    }
}

export default PayrollCreateView