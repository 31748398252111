import React, { Component } from "react"
import Shimmer from "react-shimmer-effect"
import {
    Row,
    Col
} from 'reactstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'
import moment from 'moment'

class PpkDetailComponent extends Component {

    indexViewBloc = new IndexViewBloc()

    constructor(props) {
        super(props)
        this.state = {
            detailData: {},
            loadingDetail: true,
        }
    }

    componentDidMount() {
        this.indexViewBloc.ppkDetailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingDetail: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingDetail: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        detailData: response,
                        loadingDetail: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.fetchRowPpkDetail({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.indexViewBloc.ppkDetailChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Col>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Kode PPK
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_code !== null ? this.state.detailData.detail.ppk_code : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 70, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama Pegawai
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_employee_name !== null ?
                                        <>
                                            {this.state.detailData.detail.ppk_employee_name}
                                            {
                                                this.state.detailData.detail.ppk_position_name !== null ?
                                                    <><br /><small style={{ color: '#aaaaaa' }}>{this.state.detailData.detail.ppk_position_name}</small></>
                                                    : <></>
                                            }
                                        </>
                                        : '-'}</>
                                    :
                                    <>
                                        <Shimmer><div className="shimmer" style={{ width: 120, height: 15 }}></div></Shimmer><br />
                                        <Shimmer><div className="shimmer" style={{ width: 220, height: 11 }}></div></Shimmer>
                                    </>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama Jabatan PPK
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_name !== null ? this.state.detailData.detail.ppk_name : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 300, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nomor SK
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_sk_number !== null ? this.state.detailData.detail.ppk_sk_number : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 300, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tanggal Mulaiu Berlaku
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_date_start !== null ? moment(this.state.detailData.detail.ppk_date_start).format('LL') : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 150, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tanggal Selesai Berlaku
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_date_end !== null ? moment(this.state.detailData.detail.ppk_date_end).format('LL') : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 150, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Kode Satuan Kerja
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_work_code !== null ? this.state.detailData.detail.ppk_work_code : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 120, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama Satuan Kerja
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_work_unit !== null ? this.state.detailData.detail.ppk_work_unit : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 350, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama Operator
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_operator_name !== null ?
                                        <>
                                            {this.state.detailData.detail.ppk_operator_name}
                                            {
                                                this.state.detailData.detail.ppk_operator_position !== null ?
                                                    <><br /><small style={{ color: '#aaaaaa' }}>{this.state.detailData.detail.ppk_position_name}</small></>
                                                    : <></>
                                            }
                                        </>
                                        : '-'}</>
                                    :
                                    <>
                                        <Shimmer><div className="shimmer" style={{ width: 120, height: 15 }}></div></Shimmer><br />
                                        <Shimmer><div className="shimmer" style={{ width: 220, height: 11 }}></div></Shimmer>
                                    </>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama Approver
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_approver_name !== null ?
                                        <>
                                            {this.state.detailData.detail.ppk_approver_name}
                                            {
                                                this.state.detailData.detail.ppk_approver_position !== null ?
                                                    <><br /><small style={{ color: '#aaaaaa' }}>{this.state.detailData.detail.ppk_position_name}</small></>
                                                    : <></>
                                            }
                                        </>
                                        : '-'}</>
                                    :
                                    <>
                                        <Shimmer><div className="shimmer" style={{ width: 120, height: 15 }}></div></Shimmer><br />
                                        <Shimmer><div className="shimmer" style={{ width: 220, height: 11 }}></div></Shimmer>
                                    </>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nomor DIPA
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_dipa_no !== null ? this.state.detailData.detail.ppk_dipa_no : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 200, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tanggal DIPA
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.detail.ppk_dipa_date !== null ? moment(this.state.detailData.detail.ppk_dipa_date).format('LL') : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 150, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Bendahara
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.treasurer.employee_name !== null ?
                                        <>
                                            {this.state.detailData.treasurer.employee_name}
                                            {
                                                this.state.detailData.treasurer.position_name !== null ?
                                                    <><br /><small style={{ color: '#aaaaaa' }}>{this.state.detailData.treasurer.position_name}</small></>
                                                    : <></>
                                            }
                                        </>
                                        : '-'}</>
                                    :
                                    <>
                                        <Shimmer><div className="shimmer" style={{ width: 120, height: 15 }}></div></Shimmer><br />
                                        <Shimmer><div className="shimmer" style={{ width: 220, height: 11 }}></div></Shimmer>
                                    </>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Anggota
                        </label>
                        <Col md="9">
                            <Table
                                className="table table-striped table-bordered table-hover"
                            >
                                <Thead>
                                    <Tr>
                                        <Th width="280px" data-priority="1">Nama Pegawai</Th>
                                        <Th width="*" data-priority="1">Jabatan</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        this.state.loadingDetail ?
                                            <tr>
                                                <Td>
                                                    <Shimmer><div className="shimmer" style={{ width: '100%', height: 16 }}></div></Shimmer>
                                                </Td>
                                                <Td>
                                                    <Shimmer><div className="shimmer" style={{ width: '100%', height: 16 }}></div></Shimmer>
                                                </Td>
                                            </tr>
                                            :
                                            <>
                                                {
                                                    Object.entries(this.state.detailData.staff).map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    {item[1].employee_name}
                                                                </td>
                                                                <td>
                                                                    {item[1].position_name}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </Tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}
export default PpkDetailComponent