import React, { useState, Fragment, Component } from "react"
import { Row, Col, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import ProcessBloc from "./../Blocs/ProcessBloc"

class OfficialTravelUpdateLocalComponent extends Component {
    processBloc = new ProcessBloc()
    
    constructor(props) {
        super(props)
        this.state = {
            formData: [],
            maxDay: 0,
            activeTab: 0,
            loading: false
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    handleKeyPress = (event) => {
        var last = event.target.value.replace(".", "").replace(".", "").replace(",", ".")
        if (last.length >= 10) {
            event.preventDefault()
        }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault()
            }
        }
    }

    handleKeyUp = (event, id) => {
        const _this = document.querySelector("#" + id)
        var value = event.target.value
        value = value.charAt(0) === "0" ? value.substring(1) : value
        value = value === "" ? "0" : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }

    updateStateFormTemp = (event, category, type, init) => {
        var _formData = [...this.state.formData]
        var currentDestination = _formData[this.state.activeTab]
        
        var value = event.target.value.replace(".", "").replace(".", "").replace(",", ".")
        if (value.substring(value.length - 1) === ".") {
            value = value.substring(0, value.length - 1)
        }
        value = value === "" ? 0 : value

        if (category === "daily") {
            if (init === 1) {
                var maxDay = currentDestination.official_travel_process_emp_daily_max_day === 0 ? 1 : currentDestination.official_travel_process_emp_daily_max_day
                const tabContent = document.querySelector(`[data-tab="${this.state.activeTab}"]`)
                var totalDaily = parseInt(tabContent.querySelector("#modalForm_1_1_1").value.replace(/\./g, "") || 0) + 
                               parseInt(tabContent.querySelector("#modalForm_1_2_1").value.replace(/\./g, "") || 0) + 
                               parseInt(tabContent.querySelector("#modalForm_1_3_1").value.replace(/\./g, "") || 0)
                if (maxDay < totalDaily) {
                    var gap = totalDaily - maxDay
                    if (type === 1) {
                        const thisValue = parseInt(tabContent.querySelector("#modalForm_1_1_1").value.replace(/\./g, ""))
                        value = thisValue - gap
                    }
                    if (type === 2) {
                        const thisValue = parseInt(tabContent.querySelector("#modalForm_1_2_1").value.replace(/\./g, ""))
                        value = thisValue - gap
                    }
                    if (type === 3) {
                        const thisValue = parseInt(tabContent.querySelector("#modalForm_1_3_1").value.replace(/\./g, ""))
                        value = thisValue - gap
                    }
                }
            }
            if (init === 2) {
                var maxValue = 0
                var dailyValue = 0
                if (type === 1) {
                    maxValue = parseInt(currentDestination.daily_max_value_1)
                    dailyValue = parseInt(document.querySelector("#modalForm_1_1_2").value.replace(".", "").replace(".", "").replace(",", "."))
                    if (maxValue < dailyValue) {
                        value = maxValue
                    }
                }
                if (type === 2) {
                    maxValue = parseInt(currentDestination.daily_max_value_2)
                    dailyValue = parseInt(document.querySelector("#modalForm_1_2_2").value.replace(".", "").replace(".", "").replace(",", "."))
                    if (maxValue < dailyValue) {
                        value = maxValue
                    }
                }
                if (type === 3) {
                    maxValue = parseInt(currentDestination.daily_max_value_3)
                    dailyValue = parseInt(document.querySelector("#modalForm_1_3_2").value.replace(".", "").replace(".", "").replace(",", "."))
                    if (maxValue < dailyValue) {
                        value = maxValue
                    }
                }
            }
        }

        currentDestination[category]["items"][type][init] = value
        currentDestination[category]["items"][type][3] = (currentDestination[category]["items"][type][1] * currentDestination[category]["items"][type][2]).toFixed(2)

        if (init === 2) {
            currentDestination[category]["items"][type][init] = parseInt(value).toFixed(2)
        } else {
            currentDestination[category]["items"][type][init] = parseInt(value).toFixed(0)
        }
        var total = 0
        currentDestination[category]["items"].map((item, key) => {
            total += parseInt(item[3])
        })
        currentDestination[category]["total"] = total.toFixed(2)

        _formData[this.state.activeTab] = currentDestination
        this.setState({
            formData: _formData
        })
    }

    numberWithPoint(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    componentDidMount() {
        this.setState({
            formData: this.props.EmployeesDatas
        })
    }

    componentWillUnmount() {}

    handleSubmit = async () => {
        try {
            this.setState({ loading: true })
            const { formData } = this.state
            
            // Prepare data for API
            const payload = {
                process_uuid: this.props.processUuid,
                ...formData.reduce((acc, destination) => {
                    // Daily allowance mapping (category 1)
                    destination.daily.items.forEach((item, type) => {
                        if (type > 0) {
                            acc[`${item[0]}_1_${type}_q`] = item[1]
                            acc[`${item[0]}_1_${type}_a`] = item[2]
                            acc[`${item[0]}_1_${type}_t`] = item[3]
                        }
                    })

                    // Hotel mapping (category 2)
                    destination.hotel.items.forEach((item, type) => {
                        if ([1, 4].includes(type)) {
                            acc[`${item[0]}_2_${type}_q`] = item[1]
                            acc[`${item[0]}_2_${type}_a`] = item[2]
                            acc[`${item[0]}_2_${type}_t`] = item[3]
                        }
                    })

                    // Transport mapping (category 3)
                    destination.transport.items.forEach((item, type) => {
                        if ([1, 4, 21, 24, 31, 34].includes(type)) {
                            acc[`${item[0]}_3_${type}_q`] = item[1]
                            acc[`${item[0]}_3_${type}_a`] = item[2]
                            acc[`${item[0]}_3_${type}_t`] = item[3]
                        }
                    })

                    // Representative mapping (category 4)
                    destination.representative.items.forEach((item, type) => {
                        if (type === 1) {
                            acc[`${item[0]}_4_${type}_q`] = item[1]
                            acc[`${item[0]}_4_${type}_a`] = item[2]
                            acc[`${item[0]}_4_${type}_t`] = item[3]
                        }
                    })
                    
                    return acc
                }, {})
            }

            await this.processBloc.fetchUpdateMultiDestination(payload)
            
            this.props.closeModal()
            this.props.onSuccess()
            
        } catch (error) {
            console.error('Error updating data:', error)
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <Fragment>
                <div className="modal-body">
                    <Col md="12">
                        <Nav tabs className="mb-3">
                            {this.props.EmployeesDatas.map((destination, idx) => (
                                <NavItem key={idx}>
                                    <NavLink
                                        className={this.state.activeTab === idx ? 'active' : ''}
                                        onClick={() => this.toggle(idx)}
                                    >
                                        {destination.destination.city_name}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            {this.props.EmployeesDatas.map((destination, idx) => (
                                <TabPane tabId={idx} key={idx} data-tab={idx}>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered table-hover" style={{ minWidth: 750 }}>
                                            <Thead>
                                                <Tr>
                                                    <Th className="text-center" width="245">
                                                        Uang Harian
                                                    </Th>
                                                    <Th className="text-center" width="170">
                                                        Penginapan
                                                    </Th>
                                                    <Th className="text-center" width="170">
                                                        Transportasi
                                                    </Th>
                                                    <Th className="text-center" width="140">
                                                        Representatif
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <tr>
                                                    <td className="text-end">
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Biasa
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status === 9 || destination["allowed"][0] === 0}
                                                                    style={{ width: 40 }}
                                                                    name="modalForm_1_1_1"
                                                                    placeholder="0"
                                                                    value={destination ? destination.daily.items[1][1] : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_1_1_1"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_1_1_1")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "daily", 1, 1)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][0] === 0}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_1_1_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.daily.items[1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_1_1_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_1_1_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "daily", 1, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Fullday
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][0] === 0}
                                                                    style={{ width: 40 }}
                                                                    name="modalForm_1_2_1"
                                                                    placeholder="0"
                                                                    value={destination ? destination.daily.items[2][1] : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_1_2_1"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_1_2_1")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "daily", 2, 1)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][0] === 0}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_1_2_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.daily.items[2][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_1_2_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_1_2_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "daily", 2, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1" style={{ display: this.state.formData.oversea === 2 ? "none" : "" }}>
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Fullboard
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][0] === 0}
                                                                    style={{ width: 40 }}
                                                                    name="modalForm_1_3_1"
                                                                    placeholder="0"
                                                                    value={destination ? destination.daily.items[3][1] : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_1_3_1"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_1_3_1")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "daily", 3, 1)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][0] === 0}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_1_3_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.daily.items[3][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_1_3_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_1_3_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "daily", 3, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][1] === 0}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_2_1_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination["hotel"]["items"][1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_2_1_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_2_1_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "hotel", 1, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][1] === 0}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_2_4_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination["hotel"]["items"][4][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_2_4_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_2_4_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "hotel", 4, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Darat
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 1}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_3_1_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination["transport"]["items"][1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_1_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_1_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 1, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 1}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_3_4_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination["transport"]["items"][4][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_4_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_4_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 4, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Laut
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 2}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_3_21_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination["transport"]["items"][21][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_21_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_21_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 21, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 2}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_3_24_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination["transport"]["items"][24][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_24_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_24_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 24, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Udara
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 3}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_3_31_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination["transport"]["items"][31][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_31_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_31_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 31, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 3}
                                                                    style={{ width: 100 }}
                                                                    name="modalForm_3_34_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination["transport"]["items"][34][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_34_2"
                                                                    onKeyPress={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_34_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 34, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            style={{ display: this.state.formData.oversea === 2 ? "none" : "" }}
                                                            autocomplete="off"
                                                            disabled={this.state.formData.status == 9 || destination["allowed"][3] === 0}
                                                            name="modalForm_4_1_2"
                                                            placeholder="0"
                                                            value={destination ? this.numberWithPoint(destination["representative"]["items"][1][2].replace(".00", "").replace(".", ",")) : 0}
                                                            type="text"
                                                            className="form-control text-end"
                                                            id="modalForm_4_1_2"
                                                            onKeyPress={(e) => {
                                                                this.handleKeyPress(e)
                                                            }}
                                                            onKeyUp={(e) => {
                                                                this.handleKeyUp(e, "modalForm_4_1_2")
                                                            }}
                                                            onChange={(e) => {
                                                                this.updateStateFormTemp(e, "representative", 1, 2)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination["daily"]["total"].replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination["hotel"]["total"].replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination["transport"]["total"].replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination["representative"]["total"].replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                </tr>
                                            </Tbody>
                                        </Table>
                                    </div>
                                </TabPane>
                            ))}
                        </TabContent>
                    </Col>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={this.handleSubmit}
                        className="btn btn-success waves-effect"
                        disabled={this.state.loading}
                    >
                        {this.state.loading ? (
                            <span>
                                <i className="fa fa-spinner fa-spin me-1"></i> 
                                Loading...
                            </span>
                        ) : (
                            'Simpan'
                        )}
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default OfficialTravelUpdateLocalComponent
