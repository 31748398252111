import {
    apiRequest,
    masterReferenceNrsList,
    masterReferenceNrsActivated,
    masterMappingSptListCustom,
    masterCmsDPCreateLayanan,
    masterCmsDPEditLayanan,
    masterCmsDPDetailLayanan,
    masterCmsDPListSLayanan,
    masterCmsDPCreateSLayanan,
    masterCmsDPEditSLayanan,
    masterCmsDPDetailSLayanan,
    masterCmsDPListInformasi,
    masterCmsDPCreateInformasi,
    masterCmsDPEditInformasi,
    masterCmsDPDetailInformasi,
    masterCmsDPDelete
} from "../../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchInactive = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsDPDelete, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }


    fetchActive = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsActivated, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableDpLayanan = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingSptListCustom, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableDpSLayanan = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsDPListSLayanan, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableDpInformasi = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsDPListInformasi, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository