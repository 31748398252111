import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import CustomSelect from '../../../../../../helpers/ui/CustomSelect'
import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'
import { masterMappingSptSaveDetails } from "../../../../../../services/adapters/master"
import { apiRequest } from "../../../../../../services/adapters/master"


class IndexView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {
                employee_categories_name: '',
                spt_mapping_tunkin: 0,
                spt_mapping_tunsus: 0,
                spt_mapping_gaji: 0,
            },
            messageError: "",
            category_name: "",
            menuItems: [
                {
                    name: "Tunjangan Kinerja",
                    value: "tunkin"
                },
                {
                    name: "Tunjangan Khusus", 
                    value: "tunsus"
                },
                {
                    name: "Gaji",
                    value: "gaji"
                }
            ],
            checkedValues: {},
        }
    }

    submitData = async () => {
        const checkboxData = {};
        
        this.state.menuItems.forEach(item => {
            const value = this.state.checkedValues[item.value];
            checkboxData[item.value] = value ? this.getFormatValue(value) : 0;
        });

        try {
            const queryParams = new URLSearchParams(this.props.location.search);
            const uuid = queryParams.get('uuid');
            
            console.log('Sending data:', { uuid, checkboxData });
            
            const response = await apiRequest("post", masterMappingSptSaveDetails, {
                body: {
                    uuid: uuid,
                    checkboxData: checkboxData
                }
            });

            if (response.status) {
                this.setState({ success: true });
            } else {
                this.setState({ 
                    failed: true,
                    messageError: response.message 
                });
            }
        } catch (error) {
            this.setState({ 
                failed: true,
                messageError: "Terjadi kesalahan saat menyimpan data" 
            });
        }
    }

    getFormatValue = (format) => {
        switch(format) {
            case "1721-A1": return 1;
            case "1721-A2": return 2;
            case "1721-A3": return 3;
            case "1721-B1": return 4;
            default: return 0;
        }
    }

    handlerOnSubmit = (e) => {
        e.preventDefault();
        this.setState({ confirm: true });
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            dataForm: {
                ...prevState.dataForm,
                [name]: value
            }
        }));
    }

    componentDidMount() {
        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: result.data.status,
                        messageError: result.data.status === false ? result.data.message : ""
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response;
                        const checkedValues = {};
                        
                        if (response.spt_mapping_tunkin) {
                            checkedValues.tunkin = this.getFormatString(response.spt_mapping_tunkin);
                        }
                        if (response.spt_mapping_tunsus) {
                            checkedValues.tunsus = this.getFormatString(response.spt_mapping_tunsus);
                        }
                        if (response.spt_mapping_gaji) {
                            checkedValues.gaji = this.getFormatString(response.spt_mapping_gaji);
                        }

                        this.setState({
                            category_name: response.employee_categories_name,
                            checkedValues,
                        });
                    }
                    break;
                case apiResponse.ERROR:

                    break;
                default:
                    break;
            }
        });

        this.updateBloc.fetchGetLayanan();
        const queryParams = new URLSearchParams(this.props.location.search)
        this.updateBloc.fetchDetail({ uuid: queryParams.get('uuid') })
    }

    getFormatString = (value) => {
        switch(value) {
            case 1: return "1721-A1";
            case 2: return "1721-A2";
            case 3: return "1721-A3";
            case 4: return "1721-B1";
            default: return null;
        }
    }

    componentWillUnmount() {
        this.updateBloc.updateChannel?.unsubscribe()
        this.updateBloc.detailChannel?.unsubscribe()
        this.updateBloc.getLayananChannel?.unsubscribe()
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Edit Pajak Ditunjang</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />

                                    <AvForm
                                        className="needs-validation"
                                        onSubmit={this.handlerOnSubmit}
                                    >
                                        {/* Nama Kategori */}
                                        <Row className="mb-3">
                                            <Col md="2">
                                                <Label className="col-form-label">
                                                    Nama Kategori<span className="text-danger">*</span>
                                                </Label>
                                            </Col>
                                            <Col md="10">
                                                <Input
                                                    type="text"
                                                    value={this.state.category_name}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        {/* Tunjangan */}
                                        <Row>
                                            <Col md="2">
                                                <Label className="col-form-label">
                                                    Tunjangan<span className="text-danger">*</span>
                                                </Label>
                                            </Col>
                                            <Col md="10">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Nama Modul</th>
                                                                <th className="text-center">1721-A1</th>
                                                                <th className="text-center">1721-A2</th>
                                                                <th className="text-center">1721-A3</th>
                                                                <th className="text-center">1721-B1</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.menuItems.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.name}</td>
                                                                    <td className="text-center">
                                                                        <Input
                                                                            type="radio"
                                                                            name={`format_${index}`}
                                                                            value="1721-A1"
                                                                            checked={this.state.checkedValues[item.value] === "1721-A1"}
                                                                            onChange={() => {
                                                                                this.setState(prevState => ({
                                                                                    checkedValues: {
                                                                                        ...prevState.checkedValues,
                                                                                        [item.value]: "1721-A1"
                                                                                    }
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <Input
                                                                            type="radio"
                                                                            name={`format_${index}`}
                                                                            value="1721-A2"
                                                                            checked={this.state.checkedValues[item.value] === "1721-A2"}
                                                                            onChange={() => {
                                                                                this.setState(prevState => ({
                                                                                    checkedValues: {
                                                                                        ...prevState.checkedValues,
                                                                                        [item.value]: "1721-A2"
                                                                                    }
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <Input
                                                                            type="radio"
                                                                            name={`format_${index}`}
                                                                            value="1721-A3"
                                                                            checked={this.state.checkedValues[item.value] === "1721-A3"}
                                                                            onChange={() => {
                                                                                this.setState(prevState => ({
                                                                                    checkedValues: {
                                                                                        ...prevState.checkedValues,
                                                                                        [item.value]: "1721-A3"
                                                                                    }
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <Input
                                                                            type="radio"
                                                                            name={`format_${index}`}
                                                                            value="1721-B1"
                                                                            checked={this.state.checkedValues[item.value] === "1721-B1"}
                                                                            onChange={() => {
                                                                                this.setState(prevState => ({
                                                                                    checkedValues: {
                                                                                        ...prevState.checkedValues,
                                                                                        [item.value]: "1721-B1"
                                                                                    }
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <Button color="danger" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/mapping-spt-kategori.html">
                                                    <Button color="success">
                                                        <i className="uil-arrow-left"></i> Kembali
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {this.state.redirect ?
                    <Redirect to='/master/mapping-spt-kategori.html' />
                    : null}
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);