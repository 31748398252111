import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import CustomSelect from '../../../../../../helpers/ui/CustomSelect'
import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'
import { masterMappingPajakSaveDetailsCustom, masterMappingPajakEmployees } from "../../../../../../services/adapters/master"
import { apiRequest } from "../../../../../../services/adapters/master"
import Shimmer from "react-shimmer-effect";


class IndexView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            messageError: "",
            category_name: "",
            employees: [],
            selectedEmployee: "",
            menuItems: [
                {
                    name: "Tunjangan Kinerja",
                    children: ["Reguler", "Ke-13", "Ke-14", "Susulan", "Rapel"],
                    checked: false
                },
                {
                    name: "Tunjangan Khusus",
                    children: ["Susulan", "Rapel"],
                    checked: false
                },
                {
                    name: "Uang Makan",
                    children: ["Susulan", "Rapel"],
                    checked: false
                },
                {
                    name: "Honorarium Tetap",
                    children: ["Susulan", "Rapel"],
                    checked: false
                },
                {
                    name: "TPLN",
                    children: ["Reguler", "Ke-13", "Ke-14", "Susulan", "Rapel"],
                    checked: false
                },
                {
                    name: "Uang Lembur",
                    children: ["Uang Lembur"],
                    checked: false
                }
            ],
            childCheckedStates: {},
            loadingEmployees: true,
        }
        this.handleParentCheckbox = this.handleParentCheckbox.bind(this);
        this.handleChildCheckbox = this.handleChildCheckbox.bind(this);
    }

    handleParentCheckbox(index) {
        const updatedMenuItems = [...this.state.menuItems];
        const newCheckedState = !updatedMenuItems[index].checked;
        updatedMenuItems[index].checked = newCheckedState;

        const updatedChildStates = {...this.state.childCheckedStates};
        updatedMenuItems[index].children.forEach((child, childIndex) => {
            const key = `${index}-${childIndex}`;
            updatedChildStates[key] = newCheckedState;
        });

        this.setState({
            menuItems: updatedMenuItems,
            childCheckedStates: updatedChildStates
        });
    }

    handleChildCheckbox(parentIndex, childIndex) {
        const key = `${parentIndex}-${childIndex}`;
        const updatedChildStates = {
            ...this.state.childCheckedStates,
            [key]: !this.state.childCheckedStates[key]
        };

        this.setState({ childCheckedStates: updatedChildStates });
    }

    submitData = async () => {
        const checkboxData = {};
        
        // Convert state data to API format
        this.state.menuItems.forEach(item => {
            if (item.name === "Tunjangan Kinerja") {
                checkboxData.tunkin_reguler = this.state.childCheckedStates[`0-0`] ? 1 : 0;
                checkboxData.tunkin_13 = this.state.childCheckedStates[`0-1`] ? 1 : 0;
                checkboxData.tunkin_14 = this.state.childCheckedStates[`0-2`] ? 1 : 0;
                checkboxData.tunkin_susulan = this.state.childCheckedStates[`0-3`] ? 1 : 0;
                checkboxData.tunkin_rapel = this.state.childCheckedStates[`0-4`] ? 1 : 0;
            }
            else if (item.name === "Tunjangan Khusus") {
                checkboxData.tunsus_reguler = this.state.childCheckedStates[`1-0`] ? 1 : 0;
                checkboxData.tunsus_rapel = this.state.childCheckedStates[`1-1`] ? 1 : 0;
            }
            else if (item.name === "Uang Makan") {
                checkboxData.uangmakan_reguler = this.state.childCheckedStates[`2-0`] ? 1 : 0;
                checkboxData.uangmakan_rapel = this.state.childCheckedStates[`2-1`] ? 1 : 0;
            }
            else if (item.name === "Honorarium Tetap") {
                checkboxData.honorarium_reguler = this.state.childCheckedStates[`3-0`] ? 1 : 0;
                checkboxData.honorarium_rapel = this.state.childCheckedStates[`3-1`] ? 1 : 0;
            }
            else if (item.name === "TPLN") {
                checkboxData.tpln_reguler = this.state.childCheckedStates[`4-0`] ? 1 : 0;
                checkboxData.tpln_13 = this.state.childCheckedStates[`4-1`] ? 1 : 0;
                checkboxData.tpln_14 = this.state.childCheckedStates[`4-2`] ? 1 : 0;
                checkboxData.tpln_susulan = this.state.childCheckedStates[`4-3`] ? 1 : 0;
                checkboxData.tpln_rapel = this.state.childCheckedStates[`4-4`] ? 1 : 0;
            }
            else if (item.name === "Uang Lembur") {
                checkboxData.uanglembur = this.state.childCheckedStates[`5-0`] ? 1 : 0;
            }
        });

        try {
            const queryParams = new URLSearchParams(this.props.location.search);
            const uuid = queryParams.get('uuid');
            
            const response = await apiRequest("post", masterMappingPajakSaveDetailsCustom, {
                body: {
                    uuid: uuid,
                    employee_uuid: this.state.selectedEmployee,
                    checkboxData: checkboxData
                }
            });

            if (response.status) {
                this.setState({ success: true });
            } else {
                this.setState({ 
                    failed: true,
                    messageError: response.message 
                });
            }
        } catch (error) {
            this.setState({ 
                failed: true,
                messageError: "Terjadi kesalahan saat menyimpan data" 
            });
        }
    }

    handlerOnSubmit = (e) => {
        e.preventDefault();
        this.setState({ confirm: true });
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            dataForm: {
                ...prevState.dataForm,
                [name]: value
            }
        }));
    }

    componentDidMount() {
        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: result.data.status,
                        messageError: result.data.status === false ? result.data.message : ""
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response;
                        
                        // Initialize childCheckedStates based on response data
                        const childCheckedStates = {};
                        
                        // Tunjangan Kinerja
                        childCheckedStates['0-0'] = response.tax_mapping_tunkin_reguler === 1;
                        childCheckedStates['0-1'] = response.tax_mapping_tunkin_13 === 1;
                        childCheckedStates['0-2'] = response.tax_mapping_tunkin_14 === 1;
                        childCheckedStates['0-3'] = response.tax_mapping_tunkin_susulan === 1;
                        childCheckedStates['0-4'] = response.tax_mapping_tunkin_rapel === 1;
                        
                        // Tunjangan Khusus
                        childCheckedStates['1-0'] = response.tax_mapping_tunsus_reguler === 1;
                        childCheckedStates['1-1'] = response.tax_mapping_tunsus_rapel === 1;
                        
                        // Uang Makan
                        childCheckedStates['2-0'] = response.tax_mapping_uangmakan_reguler === 1;
                        childCheckedStates['2-1'] = response.tax_mapping_uangmakan_rapel === 1;
                        
                        // Honorarium Tetap
                        childCheckedStates['3-0'] = response.tax_mapping_honorarium_reguler === 1;
                        childCheckedStates['3-1'] = response.tax_mapping_honorarium_rapel === 1;
                        
                        // TPLN
                        childCheckedStates['4-0'] = response.tax_mapping_tpln_reguler === 1;
                        childCheckedStates['4-1'] = response.tax_mapping_tpln_13 === 1;
                        childCheckedStates['4-2'] = response.tax_mapping_tpln_14 === 1;
                        childCheckedStates['4-3'] = response.tax_mapping_tpln_susulan === 1;
                        childCheckedStates['4-4'] = response.tax_mapping_tpln_rapel === 1;

                        // Uang Lembur
                        childCheckedStates['5-0'] = response.tax_mapping_uanglembur === 1;

                        // Update parent checkboxes based on children
                        const updatedMenuItems = [...this.state.menuItems];
                        updatedMenuItems.forEach((item, index) => {
                            const childKeys = item.children.map((_, childIndex) => `${index}-${childIndex}`);
                            const allChildrenChecked = childKeys.every(key => childCheckedStates[key]);
                            item.checked = allChildrenChecked;
                        });

                        this.setState({
                            dataForm: {
                                ...response
                            },
                            category_name: response.employee_categories_name,
                            childCheckedStates,
                            menuItems: updatedMenuItems
                        });
                    }
                    break;
                case apiResponse.ERROR:

                    break;
                default:
                    break;
            }
        });

        this.updateBloc.fetchGetLayanan();
        const queryParams = new URLSearchParams(this.props.location.search)
        this.updateBloc.fetchDetail({ uuid: queryParams.get('uuid') })

        apiRequest("get", masterMappingPajakEmployees)
            .then(response => {
                if (response.status) {
                    this.setState({ 
                        employees: response.response,
                        loadingEmployees: false
                    });
                }
            })
            .catch(error => {
                console.error("Error fetching employees:", error);
                this.setState({ loadingEmployees: false });
            });
    }

    componentWillUnmount() {
        this.updateBloc.updateChannel?.unsubscribe()
        this.updateBloc.detailChannel?.unsubscribe()
        this.updateBloc.getLayananChannel?.unsubscribe()
    }

    handleEmployeeChange = (event) => {
        this.setState({ selectedEmployee: event.target.value });
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Pajak Ditunjang</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />

                                    <AvForm
                                        className="needs-validation"
                                        onSubmit={this.handlerOnSubmit}
                                    >
                                        {/* Nama Pegawai */}
                                        <Row className="mb-3">
                                            <Col md="2">
                                                <Label className="col-form-label">
                                                    Nama Pegawai<span className="text-danger">*</span>
                                                </Label>
                                            </Col>
                                            <Col md="10">
                                                {this.state.loadingEmployees ? (
                                                    <Shimmer>
                                                        <div className="shimmer" style={{width: '100%', height: 36}}></div>
                                                    </Shimmer>
                                                ) : (
                                                    <AvField
                                                        type="select"
                                                        name="employee"
                                                        value={this.state.selectedEmployee}
                                                        onChange={this.handleEmployeeChange}
                                                        required
                                                    >
                                                        <option value="">Pilih Pegawai</option>
                                                        {this.state.employees.map(employee => (
                                                            <option key={employee.employee_uuid} value={employee.employee_uuid}>
                                                                {employee.employee_name}
                                                            </option>
                                                        ))}
                                                    </AvField>
                                                )}
                                            </Col>
                                        </Row>

                                        {/* Tunjangan */}
                                        <Row>
                                            <Col md="2">
                                                <Label className="col-form-label">
                                                    Tunjangan<span className="text-danger">*</span>
                                                </Label>
                                            </Col>
                                            <Col md="10">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Nama Modul</th>
                                                                <th style={{width: "100px"}}>Pajak Ditunjang</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.menuItems.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr className="bg-light">
                                                                        <td><strong>{item.name}</strong></td>
                                                                        <td className="text-center">
                                                                            <Input 
                                                                                type="checkbox" 
                                                                                checked={item.checked}
                                                                                onChange={() => this.handleParentCheckbox(index)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    {item.children.map((child, childIndex) => (
                                                                        <tr key={`${index}-${childIndex}`}>
                                                                            <td className="ps-4">{child}</td>
                                                                            <td className="text-center">
                                                                                <Input 
                                                                                    type="checkbox"
                                                                                    checked={this.state.childCheckedStates[`${index}-${childIndex}`] || false}
                                                                                    onChange={() => this.handleChildCheckbox(index, childIndex)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <Button color="danger" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/mapping-pajak-custom.html">
                                                    <Button color="success">
                                                        <i className="uil-arrow-left"></i> Kembali
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {this.state.redirect ?
                    <Redirect to='/master/mapping-pajak-custom.html' />
                    : null}
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);