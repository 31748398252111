import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import Shimmer from "react-shimmer-effect"

import MKIDatatable from "../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../services/adapters/base"
import CustomSelect from "../../../../../helpers/ui/CustomSelect"

import DetailBloc from "../Blocs/DetailBloc"
import DownloadBloc from "../Blocs/DownloadBloc"
import apiResponse from "../../../../../services/apiResponse"
import { ConfirmAlert } from "../../../../../helpers/ui/alert"

import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label, ModalFooter, Tooltip } from "reactstrap"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

class TableListComponent extends Component {
    detailBloc = new DetailBloc()
    downloadBloc = new DownloadBloc()

    source
    historyTable = "historyOfficialTravelParticipants_" + this.props.uuid
    defaultOrder = "official_travel_participant_employee_name"
    defaultSize = 10
    defaultSort = "asc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            loadingLevelOption: true,
            optionLevel: {},
            optionTransport: [
                { label: "Angkutan Darat", value: 1 },
                { label: "Angkutan Laut", value: 2 },
                { label: "Angkutan Udara", value: 3 },
            ],
            updateShow: false,
            dataUpdate: {},

            isCheckedAll01: false,
            isCheckedRow01: [],
            tooltip: false,
            defaultSize: 100,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid,
            destinationUuid: this.props.destinationUuid,
        }
        console.log(query)
        await this.detailBloc.fetchParticipant(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.detailBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    this.setTokenAPI()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.detailBloc.participantChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    let isCheckedRow01s = []
                    for (var i = 0; i < response.total; ++i) {
                        isCheckedRow01s[i] = false
                    }
                    this.setState({
                        isCheckedRow01: isCheckedRow01s,
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.detailBloc.levelOptionChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingLevelOption: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionLevel(response)
                    this.setState({
                        loadingLevelOption: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })
        this.detailBloc.fetchLevelOption({ type: this.props.oversea })

        this.downloadBloc.downloadSpdChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })
    }

    setOptionLevel = (list) => {
        const itemLevel = []
        list.map((level) => {
            itemLevel.push(level)
            return null
        })
        this.setState({
            selectedLevel: null,
            optionLevel: itemLevel,
        })
    }

    componentWillUnmount() {
        this.detailBloc.participantChannel.unsubscribe()
        this.detailBloc.levelOptionChannel.unsubscribe()
        this.detailBloc.updateChannel.unsubscribe()
        this.downloadBloc.downloadSpdChannel.unsubscribe()
    }

    columns = [
        {
            key: "official_travel_participant_employee_name",
            text: "Nama",
            sortable: true,
            width: "200",
            cell: (record) => {
                return record.official_travel_participant_employee_fullname
            },
        },
        {
            key: "official_travel_participant_position_name",
            text: "Jabatan",
            sortable: true,
            width: "220",
        },
        {
            key: "official_travel_participant_work_unit_name",
            text: "Unit Kerja",
            sortable: true,
        },
        {
            key: "official_travel_participant_transport_name",
            text: "Angkutan",
            sortable: false,
            align: "center",
            width: "120",
            cell: (record) => {
                return <> {record.official_travel_participant_transport_name !== null ? record.official_travel_participant_transport_name : "-"} </>
            },
        },
        {
            key: "official_travel_participant_official_travel_process_uuid",
            text: "Proses",
            sortable: false,
            align: "center",
            width: "120",
            cell: (record) => {
                var className = (record.official_travel_participant_official_travel_process_uuid !== null ? "bg-success" : "bg-secondary") + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 120,
            sortable: false,
            align: "center",
            cell: (record) => {
                const thisId = record.official_travel_participant_id
                var allowPrint = accessRole("print") && record.official_travel_level_option_uuid !== null && record.official_travel_participant_transport_name !== null
                return (
                    <>
                        {accessRole("update") && record.official_travel_participant_official_travel_process_uuid === null ? (
                            <>
                                {this.state.loadingLevelOption ? (
                                    <>
                                        <Shimmer className="shimmer">
                                            <button
                                                className="btn btn-soft-secondary btn-sm"
                                                disabled={true}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                    width: 24.6,
                                                    height: 24.9,
                                                }}
                                            ></button>
                                        </Shimmer>
                                    </>
                                ) : (
                                    <span>
                                        <button
                                            className="btn btn-warning btn-sm"
                                            id={"update-" + thisId}
                                            onClick={() => {
                                                this.setState({
                                                    selectedUuid: record.official_travel_participant_uuid,
                                                    selectedLevel: record.official_travel_level_option_uuid,
                                                    selectedTransport: record.official_travel_participant_transport_type,
                                                    updateShow: true,
                                                })
                                            }}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-pen"></i>
                                        </button>
                                        <Tooltip
                                            style={{
                                                marginBottom: 2,
                                            }}
                                            placement="top"
                                            isOpen={this.state["update" + thisId]}
                                            target={"update-" + thisId}
                                            toggle={() => {
                                                this.setState({
                                                    ["update" + thisId]: !this.state["update" + thisId],
                                                })
                                            }}
                                        >
                                            Perbaharui
                                        </Tooltip>
                                    </span>
                                )}
                            </>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    marginRight: "2px",
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-pen"></i>
                            </button>
                        )}
                        {allowPrint ? (
                            <>
                                <button
                                    className="btn btn-danger btn-sm"
                                    id={"print-" + thisId}
                                    onClick={() => {
                                        this.downloadBloc.fetchDownloadSpd({
                                            uuid: record.official_travel_participant_uuid,
                                        })
                                    }}
                                    style={{
                                        marginRight: "2px",
                                        padding: "2px 5px",
                                    }}
                                >
                                    <i className="uil-arrow-to-bottom"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2,
                                    }}
                                    placement="top"
                                    isOpen={this.state["print" + thisId]}
                                    target={"print-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["print" + thisId]: !this.state["print" + thisId],
                                        })
                                    }}
                                >
                                    Download SPD
                                </Tooltip>
                            </>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-arrow-to-bottom"></i>
                            </button>
                        )}
                    </>
                )
            },
        },
    ]

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataUpdate: {
                uuid: values.uuid,
                level: values.level,
                transport: values.transport,
            },
            confirm: true,
        })
    }

    submitData = async () => {
        await this.detailBloc.fetchUpdate(this.state.dataUpdate)
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                    updateShow: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={700}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />

                <Modal isOpen={this.state.updateShow} toggle={() => this.setState({ updateShow: !this.state.updateShow })}>
                    <ModalHeader toggle={() => this.setState({ updateShow: !this.state.updateShow })}>Perbaharui Alat Angkutan dan Tingkat Biaya</ModalHeader>
                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}>
                        <ModalBody>
                            <>
                                <AvField name="uuid" type="hidden" value={this.state.selectedUuid} />
                                <Row className="mb-3">
                                    <Label htmlFor="ppk_name" className="col-md-12 col-form-label">
                                        Alat Angkutan<span className="text-danger">*</span>
                                    </Label>
                                    <Col md="12">{this.state.updateShow ? <CustomSelect name="transport" defaultValue={this.state.selectedTransport} errorMessage="Pilih Alat Angkutan" options={this.state.optionTransport} placeholder="Pilih Alat Angkutan" optionLabel="label" optionValue="value" validate={{ required: { value: true } }} /> : <></>}</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="ppk_name" className="col-md-12 col-form-label">
                                        Tingkat Biaya Perjalanan Dinas
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Col md="12">
                                        {this.state.updateShow ? (
                                            <CustomSelect
                                                name="level"
                                                errorMessage="Pilih Tingkat Biaya"
                                                defaultValue={this.state.selectedLevel}
                                                options={this.state.optionLevel}
                                                placeholder="Pilih Tingkat Biaya"
                                                optionLabel="label"
                                                optionValue="value"
                                                validate={{ required: { value: true } }}
                                                onChange={(selected) => {
                                                    //this.handleOnChangeUuid(selected)
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="mb-3">
                                    <Col md="10">
                                        <div className="form-check form-switch mb-3">
                                            <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                            </AvCheckboxGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" type="submit">
                                Simpan
                            </Button>{" "}
                            <Button color="danger" onClick={() => this.setState({ updateShow: !this.state.updateShow })}>
                                Tutup
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </Modal>
                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan menyimpan data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil disimpan" failedTitle="Gagal!" failedInfo="Data gagal disimpan" showConfirm={this.state.confirm} showLoading={this.state.loading} showSuccess={this.state.success} showFailed={this.state.failed} response={this.confirmResponse} />
            </>
        )
    }
}

export default TableListComponent
