import {
    apiRequest,
    masterMappingPpspmCreate,
    masterCmsGetLayanan,
    masterReferenceBankAccountEmployee
} from "../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterMappingPpspmCreate, {
            body: {
                cms_ppspm_operator_employee_uuid: query.operator_uuid,
                cms_ppspm_approver_employee_uuid: query.approver_uuid,
                cms_ppspm_code: query.ppspm_code
            }
        }, true)
    }

    fetchGetLayanan = async () => {
        return await apiRequest("get", masterCmsGetLayanan, {}, true)
    }

    fetchEmployeeList = async () => {
        return await apiRequest("get", masterReferenceBankAccountEmployee, {}, true)
    }
}

export default CreateRepository