import { accessRole } from "../../../../../helpers/access_role"
import classnames from "classnames"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Select from "react-select"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Label,
} from "reactstrap"


import IndexListComponent from './Components/IndexListComponent'

//const IndexView = (props) => {
class IndexView extends Component {
    firstYear = 2021
    thisDate = new Date()
    totalYear = (this.thisDate.getFullYear() - this.firstYear) + 1

    constructor(props) {
        super(props)
        this.state = {
            needReload: false,
            selectedType: { value: 1, label: 'Semua Status' },
            optionType: [
                { value: 1, label: 'Semua Jenis' },
                { value: 2, label: 'Belum Ada Proses' },
                { value: 3, label: 'Sudah Ada Proses' },
                { value: 4, label: 'Sudah Proses' }
            ],
            selectedYear: { value: this.thisDate.getFullYear(), label: this.thisDate.getFullYear() },
            optionYear: Array.from({ length: this.totalYear }, (y, i) => ({ value: (this.firstYear + i), label: (this.firstYear + i) })).reverse(),
        }
    }

    handleOnChangeType = (selectedType) => {
        this.setState({
            selectedType: selectedType,
            needReload: true,
        }, () => {
            this.setState({
                needReload: false,
            })
        });
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            needReload: true,
        }, () => {
            this.setState({
                needReload: false,
            })
        });
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                        {
                                            (accessRole('create')) ?
                                                <Link to="/official-travel/register.html?create">
                                                    <Button color="primary"><i className="uil-folder-medical"></i> Daftarkan ST Baru</Button>
                                                </Link>
                                                : null
                                        }
                                    </div>
                                    <CardTitle>Surat Tugas</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Daftar Surat Tugas Perjalanan Dinas dari aplikasi Persuratan.
                                    </CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <Label
                                            htmlFor="fieldNama"
                                            className="col-md-2 col-form-label"
                                        >
                                            Tahun
                                        </Label>
                                        <Col md="6">
                                            <div className="bd-highlight">
                                                <div style={{ width: 160 }}>
                                                    <Select
                                                        value={this.state.selectedYear}
                                                        onChange={(selected) => {
                                                            this.handleOnChangeYear(selected)
                                                        }}
                                                        options={this.state.optionYear}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label
                                            htmlFor="fieldNama"
                                            className="col-md-2 col-form-label"
                                        >
                                            Status Proses Surat Tugas
                                        </Label>
                                        <Col md="6">
                                            <div className="bd-highlight">
                                                <div style={{ width: 220 }}>
                                                    <Select
                                                        value={this.state.selectedType}
                                                        onChange={(selected) => {
                                                            this.handleOnChangeType(selected)
                                                        }}
                                                        options={this.state.optionType}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    {
                                        !this.state.needReload ? <IndexListComponent type={this.state.selectedType.value} year={this.state.selectedYear.value} /> : <></>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView