import React, { useState, Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect"
import base64 from "base-64"
import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Modal, ModalHeader, ModalBody, Label, ModalFooter } from "reactstrap"
import CustomSelect from "../../../../helpers/ui/CustomSelect"
import { Link, useHistory, useParams } from "react-router-dom"
import { withRouter } from "react-router"
import { Preview } from "../../../../helpers/ui/FileInput"
import { ConfirmAlert } from "../../../../helpers/ui/alert"
import { Nav, NavItem, NavLink } from "reactstrap"

import apiResponse from "../../../../services/apiResponse"

import DetailBloc from "./Blocs/DetailBloc"
import DownloadBloc from "./Blocs/DownloadBloc"
import TableListComponent from "./Components/TableListComponent"
import TableListDestinationComponent from "./Components/TableListDestinationComponent"
import moment from "moment"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

class IndexView extends Component {
    detailBloc = new DetailBloc()
    downloadBloc = new DownloadBloc()

    constructor(props) {
        super(props)
        this.state = {
            loadingLevelOption: true,
            optionLevel: {},
            optionTransport: [
                { label: "Angkutan Darat", value: 1 },
                { label: "Angkutan Laut", value: 2 },
                { label: "Angkutan Udara", value: 3 },
            ],
            updateShow: false,
            dataUpdate: {},

            uuid: null,
            loadingUpdate: false,
            activeTab: 0,
        }
    }

    componentDidMount() {
        this.detailBloc.updateAllChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        loadingUpdate: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                        loadingUpdate: false,
                    })
                    break
                default:
                    break
            }
        })

        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.detail,
                            destination: response.destination,
                            uuid: response.detail.official_travel_uuid,
                        })
                        if (response.detail.total_participant_unprocess_ppk > 0) {
                            this.detailBloc.fetchLevelOption({
                                type: response.detail.official_travel_oversea,
                            })
                        }
                    }
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.detailBloc.fetchDetail({ uuid: this.props.uuid })

        this.detailBloc.levelOptionChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingLevelOption: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionLevel(response)
                    this.setState({
                        loadingLevelOption: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })
        this.downloadBloc.downloadSpdsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })
    }

    setOptionLevel = (list) => {
        const itemLevel = []
        list.map((level) => {
            itemLevel.push(level)
            return null
        })
        this.setState({
            selectedLevel: null,
            optionLevel: itemLevel,
        })
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataUpdate: {
                uuid: this.props.uuid,
                level: values.level,
                transport: values.transport,
                destinationUuid: values.destinationUuid,
            },
            confirm: true,
        })
    }

    submitData = async () => {
        await this.detailBloc.fetchUpdateAll(this.state.dataUpdate)
    }

    componentWillUnmount() {
        this.detailBloc.detailChannel.unsubscribe()
        this.detailBloc.levelOptionChannel.unsubscribe()
        this.detailBloc.updateAllChannel.unsubscribe()
        this.downloadBloc.downloadSpdsChannel.unsubscribe()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                    updateShow: false,
                    loadingUpdate: true,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Detail Perjalanan Dinas</CardTitle>
                                    <CardSubtitle className="mb-3">Informasi data Perjalanan dinas yang bersumber dari persuaratan dan deverifikasi oleh kepegawaian.</CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <label className="col-md-3">Nomor</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_number !== "" ? this.state.detail.official_travel_number : "-"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: 100, height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">Tanggal Mulai</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_start_date !== "" ? moment(this.state.detail.official_travel_start_date).format("LL") : "-"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: 140, height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">Tanggal Selesai</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_end_date !== "" ? moment(this.state.detail.official_travel_end_date).format("LL") : "-"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: 140, height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">Tempat Dinas</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_oversea === 1 ? "Dalam Negeri" + (this.state.detail.official_travel_domicile === 1 ? " (Dalam Kota)" : " (Luar Kota)") : "Luar Negeri"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: 120, height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    {this.state.detail ? (
                                        <>
                                            {this.state.detail.official_travel_oversea === 1 ?
                                                <>
                                                    <Row className="mb-3">
                                                        <label className="col-md-3">
                                                            Tujuan Kunjungan
                                                        </label>
                                                        <Col md="9">
                                                            {
                                                                !this.state.loadingSearch ?
                                                                    <>
                                                                        {this.state.detail.official_travel_city ? 
                                                                            <div>{this.state.detail.official_travel_city}, {this.state.detail.official_travel_province !== "" ? this.state.detail.official_travel_province : '-'}</div>
                                                                            : 
                                                                            this.state.destination && this.state.destination.map((dest, index) => (
                                                                                <div key={index} className="mb-2">
                                                                                    <div>{dest.city_name}, {dest.province_name}</div>
                                                                                    <small className="text-muted">
                                                                                        {moment(dest.official_travel_destination_start_date).format('Do MMM YYYY')}
                                                                                        {dest.official_travel_destination_start_date !== dest.official_travel_destination_end_date && 
                                                                                            <>
                                                                                                <i className="uil-arrow-right mx-1"></i>
                                                                                                {moment(dest.official_travel_destination_end_date).format('Do MMM YYYY')}
                                                                                            </>
                                                                                        }
                                                                                    </small>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    : <Shimmer className="shimmer"><div style={{ width: 100, height: 15 }}></div></Shimmer>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <>
                                                    <Row className="mb-3">
                                                        <label className="col-md-3">
                                                            Negara Tujuan
                                                        </label>
                                                        <Col md="9">
                                                            {
                                                                !this.state.loadingSearch ?
                                                                    <>
                                                                        {this.state.detail.official_travel_country ? 
                                                                            <div>{this.state.detail.official_travel_country}</div>  
                                                                        :
                                                                            this.state.destination && this.state.destination.map((dest, index) => (
                                                                                <div key={index} className="mb-2">
                                                                                    <div>{dest.country_name}</div>
                                                                                    <small className="text-muted">
                                                                                        {moment(dest.official_travel_destination_start_date).format('Do MMM YYYY')}
                                                                                        {dest.official_travel_destination_start_date !== dest.official_travel_destination_end_date && 
                                                                                            <>
                                                                                                <i className="uil-arrow-right mx-1"></i>
                                                                                                {moment(dest.official_travel_destination_end_date).format('Do MMM YYYY')}
                                                                                            </>
                                                                                        }
                                                                                    </small>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    : <Shimmer className="shimmer"><div style={{ width: 100, height: 15 }}></div></Shimmer>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <Row className="mb-3">
                                        <label className="col-md-3">Tujuan</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_destination !== "" ? this.state.detail.official_travel_destination : "-"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: "80%", height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">Keterangan</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_description !== "" ? this.state.detail.official_travel_description : "-"}</>
                                            ) : (
                                                <>
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: "100%", height: 15 }}></div>
                                                    </Shimmer>
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: "20%", height: 15 }}></div>
                                                    </Shimmer>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">File Surat Keputusan</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <Preview
                                                    col={3}
                                                    value={[
                                                        {
                                                            documents_name: this.state.detail.official_travel_letter_name,
                                                            documents_path: this.state.detail.official_travel_letter_path,
                                                            documents_mime_type: this.state.detail.official_travel_letter_mime,
                                                            documents_file_size: this.state.detail.official_travel_letter_size,
                                                            documents_source: "minio",
                                                        },
                                                    ]}
                                                />
                                            ) : (
                                                <Shimmer>
                                                    <div className="shimmer" style={{ width: 80, height: 100 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-3">
                                        <div className="col-md-12">
                                            {this.state.destination && this.state.destination.length > 0 ? (
                                                <>
                                                    <div className="mb-3">
                                                        <Nav tabs>
                                                            {this.state.destination.map((dest, index) => (
                                                                <NavItem key={index}>
                                                                    <NavLink
                                                                        className={this.state.activeTab === index ? "active" : ""}
                                                                        onClick={() => this.toggleTab(index)}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {this.state.detail.official_travel_oversea === 1 
                                                                            ? dest.city_name 
                                                                            : dest.country_name}
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))}
                                                        </Nav>
                                                    </div>

                                                    <div className="mb-3">
                                                        {this.state.destination.map((dest, index) => (
                                                            <div key={index} style={{ display: this.state.activeTab === index ? 'block' : 'none' }}>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card>
                                                                            <CardBody>
                                                                                <CardTitle>Partisipan - {this.state.detail.official_travel_oversea === 1 
                                                                                    ? dest.city_name 
                                                                                    : dest.country_name}
                                                                                </CardTitle>
                                                                                <CardSubtitle className="mb-3">
                                                                                    <small className="text-muted">
                                                                                        {moment(dest.official_travel_destination_start_date).format('Do MMM YYYY')}
                                                                                        {dest.official_travel_destination_start_date !== dest.official_travel_destination_end_date && 
                                                                                            <>
                                                                                                <i className="uil-arrow-right mx-1"></i>
                                                                                                {moment(dest.official_travel_destination_end_date).format('Do MMM YYYY')}
                                                                                            </>
                                                                                        }
                                                                                    </small>
                                                                                </CardSubtitle>

                                                                                <div className="float-start mb-3">
                                                                                    {this.state.detail && this.state.detail.total_participant_unprocess_ppk > 0 ? (
                                                                                        <>
                                                                                            {!this.state.loadingLevelOption && (
                                                                                                <>
                                                                                                    <button
                                                                                                        className="btn btn-warning btn-sm me-2"
                                                                                                        onClick={() => this.setState({ updateShow: true, selectedDestinationUuid: dest.official_travel_destination_uuid })}
                                                                                                    >
                                                                                                        <i className="uil-pen"></i> Perbaharui data semua partisipan
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="btn btn-danger btn-sm"
                                                                                                        onClick={() => this.downloadBloc.fetchDownloadSpds({
                                                                                                            uuid: this.props.uuid
                                                                                                        })}
                                                                                                    >
                                                                                                        <i className="uil-arrow-to-bottom"></i> Download SPD Kolektif
                                                                                                    </button>
                                                                                                </>
                                                                                            )}
                                                                                            {this.state.loadingLevelOption && (
                                                                                                <Shimmer>
                                                                                                    <div className="shimmer" style={{ width: 200, height: 25 }}></div>
                                                                                                </Shimmer>
                                                                                            )}
                                                                                        </>
                                                                                    ) : <>
                                                                                        <button
                                                                                            className="btn btn-danger btn-sm"
                                                                                            onClick={() => this.downloadBloc.fetchDownloadSpds({
                                                                                                uuid: this.props.uuid
                                                                                            })}
                                                                                        >
                                                                                            <i className="uil-arrow-to-bottom"></i> Download SPD Kolektif
                                                                                        </button>
                                                                                    </>}
                                                                                </div>

                                                                                <div className="float-end mb-3">
                                                                                    <div className="d-inline-block me-3">
                                                                                        <div className="bg-success rounded-circle d-inline-block me-1" 
                                                                                            style={{ height: 10, width: 10, verticalAlign: 'middle' }}></div>
                                                                                        <span>Sudah Proses</span>
                                                                                    </div>
                                                                                    <div className="d-inline-block">
                                                                                        <div className="bg-secondary rounded-circle d-inline-block me-1" 
                                                                                            style={{ height: 10, width: 10, verticalAlign: 'middle' }}></div>
                                                                                        <span>Belum Proses</span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="clearfix"></div>

                                                                                {this.state.detail && !this.state.loadingUpdate ? (
                                                                                    <>
                                                                                        {this.state.detail.total_participant > 0 ? (
                                                                                            <TableListDestinationComponent 
                                                                                                oversea={this.state.detail.official_travel_oversea} 
                                                                                                uuid={this.props.uuid}
                                                                                                destinationUuid={dest.official_travel_destination_uuid}
                                                                                            />
                                                                                        ) : (
                                                                                            <div className="text-center">
                                                                                                <b>Tidak ada data pegawai</b>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <Shimmer className="shimmer">
                                                                                        <div style={{ width: "100%", height: 100 }}></div>
                                                                                    </Shimmer>
                                                                                )}
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="float-start">
                                                        <div style={{ paddingTop: 14 }}>
                                                            {this.state.detail ? (
                                                                <>
                                                                    {this.state.detail.total_participant_unprocess_ppk > 0 ? (
                                                                        <>
                                                                            {this.state.loadingLevelOption ? (
                                                                                <>
                                                                                    <Shimmer>
                                                                                        <div className="shimmer" style={{ width: 200, height: 25 }}></div>
                                                                                    </Shimmer>{" "}
                                                                                </>
                                                                            ) : (
                                                                                <button
                                                                                    className="btn btn-warning btn-sm"
                                                                                    id={"update-" + this.props.uuid}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            updateShow: true,
                                                                                        })
                                                                                    }}
                                                                                    style={{
                                                                                        marginRight: "2px",
                                                                                        padding: "2px 5px",
                                                                                    }}
                                                                                >
                                                                                    <i className="uil-pen"></i> Perbaharui data semua partisipan
                                                                                </button>
                                                                            )}
                                                                            {this.state.loadingLevelOption ? (
                                                                                <>
                                                                                    <Shimmer>
                                                                                        <div className="shimmer" style={{ width: 200, height: 25 }}></div>
                                                                                    </Shimmer>
                                                                                </>
                                                                            ) : (
                                                                                <button
                                                                                    className="btn btn-danger btn-sm"
                                                                                    id={"update-" + this.props.uuid}
                                                                                    onClick={() => {
                                                                                        this.downloadBloc.fetchDownloadSpds({
                                                                                            uuid: this.props.uuid,
                                                                                        })
                                                                                    }}
                                                                                    style={{
                                                                                        marginRight: "2px",
                                                                                        padding: "2px 5px",
                                                                                    }}
                                                                                >
                                                                                    <i className="uil-arrow-to-bottom"></i> Download SPD Kolektif
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Shimmer>
                                                                        <div className="shimmer" style={{ width: 200, height: 25 }}></div>
                                                                    </Shimmer>{" "}
                                                                    <Shimmer>
                                                                        <div className="shimmer" style={{ width: 200, height: 25 }}></div>
                                                                    </Shimmer>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="p-2 float-end">
                                                        <div style={{ paddingTop: 8 }}>
                                                            <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sudah Proses
                                                        </div>
                                                    </div>
                                                    <div className="p-2 float-end">
                                                        <div style={{ paddingTop: 8 }}>
                                                            <div className="bg-secondary rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Belum Proses
                                                        </div>
                                                    </div>
                                            
                                                    <div className="clearfix"></div>
                                            
                                                    <Row className="mb-3">
                                                        <Col md="12">
                                                            {this.state.detail && !this.state.loadingUpdate ? (
                                                                <>
                                                                    {this.state.detail.total_participant !== "" ? (
                                                                        <>
                                                                            {this.state.detail.total_participant > 0 ? (
                                                                                <TableListComponent 
                                                                                    oversea={this.state.detail.official_travel_oversea} 
                                                                                    uuid={this.props.uuid}
                                                                                    destinationUuid={null}
                                                                                />
                                                                            ) : (
                                                                                <center>
                                                                                    <b>Tidak ada data pegawai</b>
                                                                                    <hr />
                                                                                </center>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <center>
                                                                            <b>Tidak ada data pegawai</b>
                                                                            <hr />
                                                                        </center>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Shimmer className="shimmer">
                                                                        <div style={{ width: "100%", height: 22 }}></div>
                                                                    </Shimmer>
                                                                    <Shimmer className="shimmer">
                                                                        <div style={{ width: "100%", height: 15 }}></div>
                                                                    </Shimmer>
                                                                </>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </div>
                                    </Row>
                                    <hr />
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <Link to="/official-travel/register.html" className="waves-effect">
                                                <Button color="danger">
                                                    <i className="uil-arrow-left"></i> Kembali
                                                </Button>
                                            </Link>
                                            {/* {" "}
                                            {this.state.uuid !== null && this.state.detail.total_participant_unprocess_ppk > 0 ?
                                                <Link to={'/official-travel/submission.html?create&uuid=' + base64.encode(this.state.uuid + '|' + this.state.detail.official_travel_oversea)} className="waves-effect">
                                                    <Button color="primary"><i className="uil-cog"></i> Ajukan Perjalanan Dinas</Button>
                                                </Link>
                                                : <></>
                                            } */}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={this.state.updateShow} toggle={() => this.setState({ updateShow: !this.state.updateShow })}>
                    <ModalHeader toggle={() => this.setState({ updateShow: !this.state.updateShow })}>Perbaharui Alat Angkutan dan Tingkat Biaya</ModalHeader>
                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}>
                        <ModalBody>
                            <>
                                <AvField name="uuid" type="hidden" value={this.state.selectedUuid} />
                                <AvField name="destinationUuid" type="hidden" value={this.state.selectedDestinationUuid} />
                                <Row className="mb-3">
                                    <Label htmlFor="ppk_name" className="col-md-12 col-form-label">
                                        Alat Angkutan<span className="text-danger">*</span>
                                    </Label>
                                    <Col md="12">{this.state.updateShow ? <CustomSelect name="transport" defaultValue={this.state.selectedTransport} errorMessage="Pilih Alat Angkutan" options={this.state.optionTransport} placeholder="Pilih Alat Angkutan" optionLabel="label" optionValue="value" validate={{ required: { value: true } }} /> : <></>}</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="ppk_name" className="col-md-12 col-form-label">
                                        Tingkat Biaya Perjalanan Dinas
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Col md="12">
                                        {this.state.updateShow ? (
                                            <CustomSelect
                                                name="level"
                                                errorMessage="Pilih Tingkat Biaya"
                                                defaultValue={this.state.selectedLevel}
                                                options={this.state.optionLevel}
                                                placeholder="Pilih Tingkat Biaya"
                                                optionLabel="label"
                                                optionValue="value"
                                                validate={{ required: { value: true } }}
                                                onChange={(selected) => {
                                                    //this.handleOnChangeUuid(selected)
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="mb-3">
                                    <Col md="10">
                                        <div className="form-check form-switch mb-3">
                                            <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                            </AvCheckboxGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" type="submit">
                                Simpan
                            </Button>{" "}
                            <Button color="danger" onClick={() => this.setState({ updateShow: !this.state.updateShow })}>
                                Tutup
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </Modal>
                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan menyimpan data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil disimpan" failedTitle="Gagal!" failedInfo="Data gagal disimpan" showConfirm={this.state.confirm} showLoading={this.state.loading} showSuccess={this.state.success} showFailed={this.state.failed} response={this.confirmResponse} />
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(IndexView)
