import React, { useState, Fragment, Component } from "react"
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import ProcessBloc from "../../../Ppk/Processdata/Blocs/ProcessBloc"

class OfficialTravelUpdateInterComponent extends Component {
    processBloc = new ProcessBloc()
    
    constructor(props) {
        super(props)
        this.state = {
            formData: [],
            maxDay: 0,
            activeTab: 0,
            loading: false
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    numberWithPoint(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    componentDidMount() {
        const formData = this.props.EmployeesDatas.map(destination => {
            const kurs = parseInt(destination.kurs_currency_value || 0)
            const days = parseInt(destination.daily.items[1][1] || 0)
            const dailyValue = parseInt(destination.daily.items[1][2] || 0)
            
            destination.daily.items[1][3] = (days * kurs * dailyValue).toFixed(2)
            destination.daily.total = isNaN(destination.daily.items[1][3]) ? "0.00" : destination.daily.items[1][3]

            const hotelDepart = parseInt(destination.hotel.items[1][2] || 0)
            const hotelArrive = parseInt(destination.hotel.items[4][2] || 0)
            
            destination.hotel.items[1][3] = ((kurs * dailyValue * (hotelDepart / 100))).toFixed(2)
            destination.hotel.items[4][3] = ((kurs * dailyValue * (hotelArrive / 100))).toFixed(2)
            
            const hotelDepartAmount = parseFloat(destination.hotel.items[1][3]) || 0
            const hotelArriveAmount = parseFloat(destination.hotel.items[4][3]) || 0
            const hotelTotal = (hotelDepartAmount + hotelArriveAmount).toFixed(2)
            destination.hotel.total = isNaN(hotelTotal) ? "0.00" : hotelTotal

            return destination
        })

        this.setState(
            {
                formData: formData,
            }
        )
    }

    render() {
        return (
            <Fragment>
                <div className="modal-body">
                    <Col md="12">
                        <Nav tabs className="mb-3">
                            {this.props.EmployeesDatas.map((destination, idx) => (
                                <NavItem key={idx}>
                                    <NavLink
                                        className={this.state.activeTab === idx ? 'active' : ''}
                                        onClick={() => this.toggle(idx)}
                                    >
                                        {destination.destination.country_name}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            {this.props.EmployeesDatas.map((destination, idx) => (
                                <TabPane tabId={idx} key={idx} data-tab={idx}>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered table-hover" style={{ minWidth: 750 }}>
                                            <Thead>
                                                <Tr>
                                                    <Th className="text-center" width="245">
                                                        Uang Harian ($)
                                                    </Th>
                                                    <Th className="text-center" width="170">
                                                        Perjalanan (%)
                                                    </Th>
                                                    <Th className="text-center" width="170">
                                                        Transportasi (Rp)
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <tr>
                                                    <td className="text-end">
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Kurs
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 120, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(String(destination.kurs_currency_value || 0).replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Biasa
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 60, minHeight: 31 }}>
                                                                    {destination ? destination.daily.items[1][1] : 0}
                                                                </label>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 120, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.daily.items[1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 90 }}>Berangkat</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 60, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.hotel.items[1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 90 }}>Pulang</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 60, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.hotel.items[4][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Darat
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 120, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.transport.items[1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 120, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.transport.items[4][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Laut
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 120, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.transport.items[21][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 120, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.transport.items[24][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Udara
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 120, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.transport.items[31][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <label className="form-control text-end" style={{ width: 120, minHeight: 31 }}>
                                                                    {destination ? this.numberWithPoint(destination.transport.items[34][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination.daily.total.replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination.hotel.total.replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination.transport.total.replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                </tr>
                                            </Tbody>
                                        </Table>
                                    </div>
                                </TabPane>
                            ))}
                        </TabContent>
                    </Col>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger waves-effect"
                        onClick={() => this.props.closeModal()}

                    >
                        Tutup
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default OfficialTravelUpdateInterComponent
