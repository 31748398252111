import { Subject } from 'rxjs'
import apiResponse from '../../../../../../../services/apiResponse'
import UpdateRepository from '../Repositories/UpdateRepository'


class UpdateBloc {

    detailChannel = new Subject();
    updateChannel = new Subject();
    getLayananChannel = new Subject();
    repository = new UpdateRepository()

    constructor() {
        this.updateChannel.next({status: apiResponse.INITIAL})
        this.getLayananChannel.next({status: apiResponse.INITIAL})
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        await this.repository.fetchDetail(query).then((result) => {
            const modifiedResult = {
                ...result,
                employee_categories_is_tunkin: Boolean(result.employee_categories_is_tunkin),
                employee_categories_is_uang_makan: Boolean(result.employee_categories_is_uang_makan),
                employee_categories_is_honorarium: Boolean(result.employee_categories_is_honorarium),
                spt_mapping_tunkin: Boolean(result.spt_mapping_tunkin),
                spt_mapping_tunsus: Boolean(result.spt_mapping_tunsus),
                spt_mapping_gaji: Boolean(result.spt_mapping_gaji)
            }
            this.detailChannel.next({status: apiResponse.COMPLETED, data: modifiedResult})
        }).catch((error) => {
            this.detailChannel.next({status: apiResponse.ERROR, data: error})
        })
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({status: apiResponse.LOADING})
        await this.repository.fetchUpdate(query).then((result) => {
            this.updateChannel.next({status: apiResponse.COMPLETED, data: result})
        }).catch((error) => {
            this.updateChannel.next({status: apiResponse.ERROR, data: error})
        })
    }

    fetchGetLayanan = async () => {
        this.getLayananChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetLayanan().then((result) => {
                this.getLayananChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getLayananChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Layanan Unsubscribed') 
        }
    }
}

export default UpdateBloc