import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import moment from 'moment'
import {
    Tooltip,
    Modal
} from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../services/adapters/base"

import IndexViewBloc from '../Blocs/IndexViewBloc'
//import PpkDetailComponent from './PpkDetailComponent'
import apiResponse from '../../../../../services/apiResponse'

class RegisterListComponent extends Component {

    indexViewBloc = new IndexViewBloc();

    source
    historyTable = 'historyRegisterListComponent'
    defaultOrder = 'official_travel_start_date'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showDetail: false,
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order
        }
        await this.indexViewBloc.fetchRowPpkListActive(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => { }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowRegisterListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowRegisterListChannel.unsubscribe()
    }

    columns = [
        {
            key: "official_travel_number",
            text: "Nomor ST",
            sortable: true,
            width: '190',
        },
        {
            key: "total_participant_ppk",
            text: "Partisipan",
            sortable: true,
            width: '140',
            cell: record => {
                return <>{record.total_participant_ppk} dari {record.total_participant} pegawai</>
            }
        },
        /* {
            key: "official_travel_domicile",
            text: "Tujuan",
            sortable: true,
            width: '180',
            cell: record => {
                let oversea = record.official_travel_oversea == 1 ? 'Dalam Negeri' : 'Luar Negeri'
                let domicile = record.official_travel_domicile == 1 ? 'Dalam Kota' : 'Luar Kota'
                return (
                    <span>
                        {record.official_travel_oversea !== 1 &&
                            <div className="p-1">
                                {oversea}
                            </div>
                        }
                        {record.official_travel_domicile &&
                            <div className="p-1">
                                <label>Domisili:</label> {domicile}
                            </div>
                        }
                        {record.official_travel_province &&
                            <div className="p-1">
                                <label>Provinsi:</label> {record.official_travel_province}
                            </div>
                        }
                        {record.official_travel_city &&
                            <div className="p-1">
                                <label>Kota:</label> {record.official_travel_city}
                            </div>
                        }
                        {record.official_travel_destination &&
                            <div className="p-1">
                                <label>Tempat:</label> {record.official_travel_destination}
                            </div>
                        }
                    </span>
                )
            }
        }, */
        {
            key: "official_travel_start_date",
            text: "Tanggal Mulai",
            sortable: true,
            width: '180',
            cell: record => {
                return moment(record.official_travel_start_date).format('LL')
            }
        },
        {
            key: "official_travel_end_date",
            text: "Tanggal Selesai",
            sortable: true,
            width: '180',
            cell: record => {
                return moment(record.official_travel_end_date).format('LL')
            }
        },
        {
            key: "official_travel_description",
            text: "Keterangan",
            sortable: true,
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: '90',
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.official_travel_id
                return (
                    <>
                        <span>
                            {accessRole('create') ?
                                <>
                                    <Link
                                        className="btn btn-primary btn-sm"
                                        id={"create-" + thisId}
                                        style={{
                                            marginRight: '2px',
                                            padding: '2px 5px'
                                        }}
                                        to={"/official-travel/register.html?detail&uuid=" + record.official_travel_ppk_uuid}
                                    >
                                        <i className="uil-align-left"></i>
                                    </Link>
                                    <Tooltip
                                        style={{
                                            marginBottom: 2
                                        }}
                                        placement="top" isOpen={this.state["create" + thisId]} target={"create-" + thisId}
                                        toggle={() => {
                                            this.setState({
                                                ["create" + thisId]: !this.state["create" + thisId]
                                            })
                                        }}
                                    >Lihat Perjalanan Dinas</Tooltip>
                                </>
                                : null
                            }
                        </span>
                    </>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={700}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="xl"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Detail PPK
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.showDetail ?
                            {/* <PpkDetailComponent uuid={this.state.ppkUuid} /> */ }
                            : <></>}
                    </div>
                </Modal>
            </>
        )
    }
}

export default RegisterListComponent