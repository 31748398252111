import React, { useState, Fragment, Component } from "react"
import { Row, Col, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import ProcessBloc from "./../Blocs/ProcessBloc"

class OfficialTravelUpdateInterComponent extends Component {
    processBloc = new ProcessBloc()
    
    constructor(props) {
        super(props)
        this.state = {
            formData: [],
            maxDay: 0,
            activeTab: 0,
            loading: false
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    handleKeyPress = (event) => {
        var last = event.target.value.replace(".", "").replace(".", "").replace(",", ".")
        if (last.length >= 10) {
            event.preventDefault()
        }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault()
            }
        }
    }

    handleKeyUp = (event, id) => {
        const _this = document.querySelector("#" + id)
        var value = event.target.value
        value = value.charAt(0) === "0" ? value.substring(1) : value
        value = value === "" || value === null ? "0" : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }

    updateStateFormTemp = (event, category, type, init) => {
        var _formData = [...this.state.formData]
        var currentDestination = _formData[this.state.activeTab]
        
        var value = event.target.value.replace(/\./g, "").replace(",", ".")
        if (value.substring(value.length - 1) === ".") {
            value = value.substring(0, value.length - 1)
        }
        value = value === "" ? 0 : value

        if (category === "daily") {
            if (init === 1) {
                const tabContent = document.querySelector(`[data-tab="${this.state.activeTab}"]`)
                var maxDay = this.state.maxDay === 0 ? currentDestination.official_travel_process_emp_daily_max_day : this.state.maxDay
                var totalDaily = parseInt(tabContent.querySelector("#modalForm_1_1_1").value.replace(/\./g, ""))
                if (maxDay < totalDaily) {
                    var gap = totalDaily - maxDay
                    if (type === 1) {
                        const thisValue = parseInt(tabContent.querySelector("#modalForm_1_1_1").value.replace(/\./g, ""))
                        value = thisValue - gap
                    }
                }
            }
            if (init === 2) {
                var maxValue = parseInt(currentDestination.daily_max_value_1)
                var dailyValue = parseInt(document.querySelector("#modalForm_1_1_2").value.replace(/\./g, "").replace(",", "."))
                if (maxValue < dailyValue) {
                    value = maxValue
                }
            }
        }

        if (category === "daily" || category === "hotel" || category === "kurs") {
            if (category === "kurs") {
                currentDestination["kurs_currency_value"] = value
                currentDestination["kurs"] = value
                category = "daily"
                type = 1
                init = 1
            } else {
                if (category === "hotel") {
                    const tabContent1 = document.querySelector(`[data-tab="${this.state.activeTab}"]`)
                    var day = parseInt(tabContent1.querySelector("#modalForm_1_1_1").value.replace(/\./g, ""))
                    value = value > 100 ? 100 : parseInt(value)

                    var depart = parseInt(tabContent1.querySelector("#modalForm_2_1_2").value.replace(/\./g, ""))
                    var arrive = parseInt(tabContent1.querySelector("#modalForm_2_4_2").value.replace(/\./g, ""))

                    day = isNaN(day) ? 0 : day
                    depart = isNaN(depart) ? 0 : depart
                    arrive = isNaN(arrive) ? 0 : arrive

                    if (type === 1) {
                        depart = value
                        if (depart > 0) {
                            --minDayMax
                        }
                    }
                    if (type === 4) {
                        arrive = value
                        if (arrive > 0) {
                            --minDayMax
                        }
                    }
                    var minDayMax = parseInt(currentDestination.official_travel_process_emp_daily_max_day === 0 ? 1 : currentDestination.official_travel_process_emp_daily_max_day)
                    if (depart > 0) {
                        --minDayMax
                    }
                    if (arrive > 0) {
                        --minDayMax
                    }

                    if (day > minDayMax) {
                        currentDestination["daily"]["items"][1][1] = minDayMax < 0 ? "0" : minDayMax.toFixed(0)
                    }
                    this.setState({
                        maxDay: minDayMax,
                    })
                }
                currentDestination[category]["items"][type][init] = parseInt(value).toFixed(0)
            }

            var kurs = parseInt(currentDestination["kurs_currency_value"])
            var days = parseInt(currentDestination["daily"]["items"][1][1])
            var dailyValue = parseInt(currentDestination["daily"]["items"][1][2])

            currentDestination["daily"]["items"][1][3] = (days * kurs * dailyValue).toFixed(2)
            currentDestination["daily"]["total"] = isNaN(currentDestination["daily"]["items"][1][3]) ? "0.00" : currentDestination["daily"]["items"][1][3]

            var hotelDepart = parseInt(currentDestination["hotel"]["items"][1][2]) || 0
            var hotelArrive = parseInt(currentDestination["hotel"]["items"][4][2]) || 0
            var hotelValue = parseInt(currentDestination["daily"]["items"][1][2]) || 0

            currentDestination["hotel"]["items"][1][3] = ((kurs * hotelValue * (hotelDepart / 100))).toFixed(2)
            currentDestination["hotel"]["items"][4][3] = ((kurs * hotelValue * (hotelArrive / 100))).toFixed(2)

            const hotelDepartAmount = parseFloat(currentDestination["hotel"]["items"][1][3]) || 0
            const hotelArriveAmount = parseFloat(currentDestination["hotel"]["items"][4][3]) || 0
            const hotelTotal = (hotelDepartAmount + hotelArriveAmount).toFixed(2)
            currentDestination["hotel"]["total"] = isNaN(hotelTotal) ? "0.00" : hotelTotal
        } else {
            currentDestination[category]["items"][type][init] = value
            currentDestination[category]["items"][type][3] = (currentDestination[category]["items"][type][1] * currentDestination[category]["items"][type][2]).toFixed(2)

            if (init === 2) {
                currentDestination[category]["items"][type][init] = parseInt(value).toFixed(2)
            } else {
                currentDestination[category]["items"][type][init] = parseInt(value).toFixed(0)
            }
            var total = 0
            currentDestination[category]["items"].map((item) => {
                total += parseInt(item[3]) || 0
            })
            currentDestination[category]["total"] = isNaN(total) ? "0.00" : total.toFixed(2)
        }

        _formData[this.state.activeTab] = currentDestination
        this.setState({
            formData: _formData
        })
    }

    numberWithPoint(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    componentDidMount() {
        const formData = this.props.EmployeesDatas.map(destination => {
            const kurs = parseInt(destination.kurs_currency_value || 0)
            const days = parseInt(destination.daily.items[1][1] || 0)
            const dailyValue = parseInt(destination.daily.items[1][2] || 0)
            
            destination.daily.items[1][3] = (days * kurs * dailyValue).toFixed(2)
            destination.daily.total = isNaN(destination.daily.items[1][3]) ? "0.00" : destination.daily.items[1][3]

            const hotelDepart = parseInt(destination.hotel.items[1][2] || 0)
            const hotelArrive = parseInt(destination.hotel.items[4][2] || 0)
            
            destination.hotel.items[1][3] = ((kurs * dailyValue * (hotelDepart / 100))).toFixed(2)
            destination.hotel.items[4][3] = ((kurs * dailyValue * (hotelArrive / 100))).toFixed(2)
            
            const hotelDepartAmount = parseFloat(destination.hotel.items[1][3]) || 0
            const hotelArriveAmount = parseFloat(destination.hotel.items[4][3]) || 0
            const hotelTotal = (hotelDepartAmount + hotelArriveAmount).toFixed(2)
            destination.hotel.total = isNaN(hotelTotal) ? "0.00" : hotelTotal

            return destination
        })

        this.setState(
            {
                formData: formData,
            }
        )
    }

    handleSubmit = async () => {
        try {
            this.setState({ loading: true })
            const { formData } = this.state
            
            // Prepare data for API
            const payload = {
                process_uuid: this.props.processUuid,
                ...formData.reduce((acc, destination) => {
                    acc[`${destination.official_travel_process_emp_uuid}__kurs`] = destination.kurs_currency_value

                    // Daily allowance mapping (category 1)
                    destination.daily.items.forEach((item, type) => {
                        if (type === 1) {
                            acc[`${item[0]}_1_${type}_q`] = item[1]
                            acc[`${item[0]}_1_${type}_a`] = item[2]
                            acc[`${item[0]}_1_${type}_t`] = item[3]
                        }
                    })

                    // Hotel mapping (category 2)
                    destination.hotel.items.forEach((item, type) => {
                        if ([1, 4].includes(type)) {
                            acc[`${item[0]}_2_${type}_q`] = item[1]
                            acc[`${item[0]}_2_${type}_a`] = item[2]
                            acc[`${item[0]}_2_${type}_t`] = item[3]
                        }
                    })

                    // Transport mapping (category 3)
                    destination.transport.items.forEach((item, type) => {
                        if ([1, 4, 21, 24, 31, 34].includes(type)) {
                            acc[`${item[0]}_3_${type}_q`] = item[1]
                            acc[`${item[0]}_3_${type}_a`] = item[2]
                            acc[`${item[0]}_3_${type}_t`] = item[3]
                        }
                    })
                    
                    return acc
                }, {})
            }

            await this.processBloc.fetchUpdateMultiDestination(payload)
            
            this.props.closeModal()
            this.props.onSuccess()
            
        } catch (error) {
            console.error('Error updating data:', error)
        } finally {
            this.setState({ loading: false })
        }
    }

    componentWillUnmount() {}

    render() {
        return (
            <Fragment>
                <div className="modal-body">
                    <Col md="12">
                        <Nav tabs className="mb-3">
                            {this.props.EmployeesDatas.map((destination, idx) => (
                                <NavItem key={idx}>
                                    <NavLink
                                        className={this.state.activeTab === idx ? 'active' : ''}
                                        onClick={() => this.toggle(idx)}
                                    >
                                        {destination.destination.country_name}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            {this.props.EmployeesDatas.map((destination, idx) => (
                                <TabPane tabId={idx} key={idx} data-tab={idx}>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered table-hover" style={{ minWidth: 750 }}>
                                            <Thead>
                                                <Tr>
                                                    <Th className="text-center" width="245">
                                                        Uang Harian ($)
                                                    </Th>
                                                    <Th className="text-center" width="170">
                                                        Perjalanan (%)
                                                    </Th>
                                                    <Th className="text-center" width="170">
                                                        Transportasi (Rp)
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <tr>
                                                    <td className="text-end">
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Kurs
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    style={{ width: 120 }}
                                                                    name={"modalForm_0_0_0"}
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(String(destination.official_travel_process_emp_kurs_value || 0).replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id={"modalForm_0_0_0"}
                                                                    onKeyDown={(e) => this.handleKeyPress(e)}
                                                                    onKeyUp={(e) => this.handleKeyUp(e, "modalForm_0_0_0")}
                                                                    onChange={(e) => this.updateStateFormTemp(e, "kurs", 0, 0)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Biasa
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][0] === 0}
                                                                    style={{ width: 60 }}
                                                                    name="modalForm_1_1_1"
                                                                    placeholder="0"
                                                                    value={destination ? destination.daily.items[1][1] : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_1_1_1"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_1_1_1")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "daily", 1, 1)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][0] === 0}
                                                                    style={{ width: 120 }}
                                                                    name="modalForm_1_1_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.daily.items[1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_1_1_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_1_1_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "daily", 1, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 90 }}>Berangkat</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][1] === 0}
                                                                    style={{ width: 60 }}
                                                                    name="modalForm_2_1_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.hotel.items[1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_2_1_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_2_1_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "hotel", 1, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 90 }}>Pulang</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={this.state.formData.status == 9 || destination["allowed"][1] === 0}
                                                                    style={{ width: 60 }}
                                                                    name="modalForm_2_4_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.hotel.items[4][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_2_4_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_2_4_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "hotel", 4, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Darat
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 1}
                                                                    style={{ width: 120 }}
                                                                    name="modalForm_3_1_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.transport.items[1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_1_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_1_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 1, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 1}
                                                                    style={{ width: 120 }}
                                                                    name="modalForm_3_4_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.transport.items[4][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_4_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_4_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 4, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Laut
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 2}
                                                                    style={{ width: 120 }}
                                                                    name="modalForm_3_21_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.transport.items[21][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_21_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_21_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 21, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 2}
                                                                    style={{ width: 120 }}
                                                                    name="modalForm_3_24_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.transport.items[24][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_24_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_24_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 24, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Udara
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 3}
                                                                    style={{ width: 120 }}
                                                                    name="modalForm_3_31_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.transport.items[31][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_31_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_31_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 31, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Input
                                                                    autocomplete="off"
                                                                    disabled={destination["allowed"][2] === 0 || destination.destination.o_t_participant_destination_transport_type !== 3}
                                                                    style={{ width: 120 }}
                                                                    name="modalForm_3_34_2"
                                                                    placeholder="0"
                                                                    value={destination ? this.numberWithPoint(destination.transport.items[34][2].replace(".00", "").replace(".", ",")) : 0}
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="modalForm_3_34_2"
                                                                    onKeyDown={(e) => {
                                                                        this.handleKeyPress(e)
                                                                    }}
                                                                    onKeyUp={(e) => {
                                                                        this.handleKeyUp(e, "modalForm_3_34_2")
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.updateStateFormTemp(e, "transport", 34, 2)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination.daily.total.replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination.hotel.total.replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination.transport.total.replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                </tr>
                                            </Tbody>
                                        </Table>
                                    </div>
                                </TabPane>
                            ))}
                        </TabContent>
                    </Col>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={this.handleSubmit}
                        className="btn btn-success waves-effect"
                        disabled={this.state.loading}
                    >
                        {this.state.loading ? (
                            <span>
                                <i className="fa fa-spinner fa-spin me-1"></i> 
                                Loading...
                            </span>
                        ) : (
                            'Simpan'
                        )}
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default OfficialTravelUpdateInterComponent
