import { apiWrapper, apiCancel } from '../wrapper'

export const initial = 'master'
const baseUrl = process.env.REACT_APP_SERVICE_MASTER_URL
const appCode = process.env.REACT_APP_SERVICE_MASTER_APPCODE
const outputType = 'json'


export const masterReferenceCountrylist = 'reference/country/lists/'

export const masterTplnKurslist = 'tpln/kurs/lists/'
export const masterTplnKursCurrencies = 'tpln/kurs/currencies/'
export const masterTplnKursCreate = 'tpln/kurs/create/'

export const masterReferenceSignerlist = 'reference/signer/lists/'
export const masterReferenceSignerDetail = 'reference/signer/detail/'
export const masterReferenceSignerActive = 'reference/signer/active/'
export const masterReferenceSignerInactive = 'reference/signer/inactive/'
export const masterReferenceSignerType = 'reference/signer/types/'
export const masterReferenceSignerEmployee = 'reference/signer/employees/'
export const masterReferenceSignerCreate = 'reference/signer/create/'
export const masterReferenceSignerUpdate = 'reference/signer/update/'

export const masterReferenceKurslist = 'reference/kurs/lists/'
export const masterReferenceKursCurrencies = 'reference/kurs/currencies/'
export const masterReferenceKursCreate = 'reference/kurs/create/'

export const masterReferenceMappingNipList = 'reference/mappingnip/lists/'
export const masterReferenceMappingNipEmployee = 'reference/mappingnip/employees/'
export const masterReferenceMappingNipCreate = 'reference/mappingnip/create/'
export const masterReferenceMappingNipDelete = 'reference/mappingnip/delete/'
export const masterReferenceMappingNipUpload = 'reference/mappingnip/upload/'

export const masterReferenceBankAccountList = 'reference/mappingbank/lists/'
export const masterReferenceBankAccountEmployee = 'reference/mappingbank/employees/'
export const masterReferenceBankAccountCreate = 'reference/mappingbank/create/'
export const masterReferenceBankAccountDelete = 'reference/mappingbank/delete/'
export const masterReferenceBankAccountUpload = 'reference/mappingbank/upload/'

export const masterTunkinClassCurrent = 'tunkin/tunkin/getcurrent/'
export const masterTunkinClassValue = 'tunkin/tunkin/allvalue/'
export const masterTunkinClassGrade = 'tunkin/tunkin/positiongrade/'
export const masterTunkinClassRegulationList = 'tunkin/tunkin/alllist/'
export const masterTunkinClassRegulationDetail = 'tunkin/tunkin/detail/'
export const masterTunkinClassMasterGrade = 'tunkin/tunkin/masterGrade/'
export const masterTunkinClassRegulationCreate = 'tunkin/tunkin/create/'

export const masterTunkinCountryList = 'tunkin/country/lists/'
export const masterTunkinCountryDetail = 'tunkin/country/detail/'
export const masterTunkinCountryCountries = 'tunkin/country/countries/'
export const masterTunkinCountryCreate = 'tunkin/country/create/'

export const masterTunkinTplnCountries = 'tunkin/tpln/countries/'
export const masterTunkinTplnValue = 'tunkin/tpln/allvalue/'
export const masterTunkinTplnCurrent = 'tunkin/tpln/getcurrent/'
export const masterTunkinTplnRegulationList = 'tunkin/tpln/alllist/'
export const masterTunkinTplnRegulationDetail = 'tunkin/tpln/detail/'
export const masterTunkinTplnMasterRank = 'tunkin/tpln/masterRank/'
export const masterTunkinTplnRegulationCreate = 'tunkin/tpln/create/'


export const masterUangMakanCurrent = 'uangmakan/getcurrent/'
export const masterUangMakanValue = 'uangmakan/allvalue/'
export const masterUangMakanRegulationList = 'uangmakan/alllist/'
export const masterUangMakanRegulationDetail = 'uangmakan/detail/'
export const masterUangMakanMasterRank = 'uangmakan/masterRank/'
export const masterUangMakanRegulationCreate = 'uangmakan/create/'

// Tunjangan Khusus
export const masterTunjanganKhususCurrent = 'tunjangankhusus/getcurrent/'
export const masterTunjanganKhususValue = 'tunjangankhusus/allvalue/'
export const masterTunjanganKhususRegulationList = 'tunjangankhusus/alllist/'
export const masterTunjanganKhususRegulationDetail = 'tunjangankhusus/detail/'
export const masterTunjanganKhususMasterGrade = 'tunjangankhusus/masterGrade/'
export const masterTunjanganKhususRegulationCreate = 'tunjangankhusus/create/'

export const masterOvertimeCurrent = 'overtime/getcurrent/'
export const masterOvertimeValue = 'overtime/allvalue/'
export const masterOvertimeRegulationList = 'overtime/alllist/'
export const masterOvertimeRegulationDetail = 'overtime/detail/'
export const masterOvertimeMasterRank = 'overtime/masterRank/'
export const masterOvertimeRegulationCreate = 'overtime/create/'


export const masterTaxPkpList = 'tax/pkp/lists/'
export const masterTaxPtkpList = 'tax/ptkp/lists/'
export const masterTaxFinalList = 'tax/final/lists/'
export const masterTaxPtkp2024A = 'tax/ptkp2024/lists_a/'
export const masterTaxPtkp2024B = 'tax/ptkp2024/lists_b/'
export const masterTaxPtkp2024C = 'tax/ptkp2024/lists_c/'
export const masterTaxPtkp2024Detail = 'tax/ptkp2024/detail/'
export const masterTaxPtkp2024Create = 'tax/ptkp2024/create/'
export const masterTaxPtkp2024Update = 'tax/ptkp2024/update/'

export const masterPtspHonor = 'ptsp/honorarium/'
export const masterPtspHonorCreate = 'ptsp/honorarium/create/'
export const masterPtspHonorMasterGrade = 'ptsp/honorarium/masterGrade/'
export const masterPtspHonorGrade = 'ptsp/honorarium/positionGrade/'
export const masterPtspHonorCurrent = 'ptsp/honorarium/getCurrent/'
export const masterPtspHonorValue = 'ptsp/honorarium/allvalue/'
export const masterPtspHonorRegulationList = 'ptsp/honorarium/alllist/'
export const masterPtspHonorRegulationDetail = 'ptsp/honorarium/detail/'

export const masterPtspTunkinMinistry = 'ptsp/tunkin/ministryList/'
export const masterPtspTunkinCurrent = 'ptsp/tunkin/getCurrent/'
export const masterPtspTunkinCreate = 'ptsp/tunkin/create/'
export const masterPtspTunkinGrade = 'ptsp/tunkin/positionGrade/'
export const masterPtspTunkinRankList = 'ptsp/tunkin/rankList/'
export const masterPtspTunkinMasterGrade = 'ptsp/tunkin/masterGrade/'
export const masterPtspTunkinValue = 'ptsp/tunkin/allvalue/'
export const masterPtspTunkinRegulationList = 'ptsp/tunkin/alllist/'
export const masterPtspTunkinRegulationDetail = 'ptsp/tunkin/detail/'

export const masterSpdCountry           = 'reference/SpdCountry/list/'
export const masterSpdCountryActive     = 'reference/SpdCountry/active/'
export const masterSpdCountryInactive   = 'reference/SpdCountry/inactive/'
export const masterSpdCountryDetail     = 'reference/SpdCountry/detail/'
export const masterSpdCountryCreate     = 'reference/SpdCountry/create/'
export const masterSpdCountryupdate     = 'reference/SpdCountry/update/'
export const masterSpdCountryCountries  = 'reference/SpdCountry/country/'


export const masterSpdProvince          = 'reference/SpdProvince/list/'
export const masterSpdProvinceCreate    = 'reference/SpdProvince/create/'
export const masterSpdProvinceprovince  = 'reference/SpdProvince/province/'
export const masterSpdProvinceInActive  = 'reference/SpdProvince/inactive/'
export const masterSpdProvinceActive    = 'reference/SpdProvince/active/'
export const masterSpdProvinceDetail    = 'reference/SpdProvince/detail/'
export const masterSpdProvinceUpdate    = 'reference/SpdProvince/update/'

export const masterSpdProvinceprovinceCheck = 'reference/SpdProvince/check/'
export const masterSpdCountryprovinceCheck  = 'reference/SpdCountry/check/'

export const masterReferenceNrsList = 'reference/nrs/lists/'
export const masterReferenceNrsCreate = 'reference/nrs/create/'
export const masterReferenceNrsEdit = 'reference/nrs/edit/'
export const masterReferenceNrsDetail = 'reference/nrs/detail/'
export const masterReferenceNrsActivated = 'reference/nrs/activated/'

// master mapping pajak
export const masterMappingPajakList = 'reference/mappingpajak/lists/'
export const masterMappingPajakSaveDetails = 'reference/mappingpajak/save_details/'
export const masterMappingPajakDetail = 'reference/mappingpajak/detail_mapping_pajak/'

export const masterMappingPajakListCustom = 'reference/mappingpajak/lists_custom/'
export const masterMappingPajakSaveDetailsCustom = 'reference/mappingpajak/save_details_custom/'
export const masterMappingPajakDetailCustom = 'reference/mappingpajak/detail_mapping_pajak_custom/'

// master mapping spt
export const masterMappingSptList = 'reference/mappingspt/lists/'
export const masterMappingSptSaveDetails = 'reference/mappingspt/save_details/'
export const masterMappingSptDetail = 'reference/mappingspt/detail_mapping_spt/'

export const masterMappingSptListCustom = 'reference/mappingspt/lists_custom/'
export const masterMappingSptSaveDetailsCustom = 'reference/mappingspt/save_details_custom/'
export const masterMappingSptDetailCustom = 'reference/mappingspt/detail_mapping_spt_custom/'

export const masterMappingPajakCreate = 'reference/mappingpajak/create/'
export const masterMappingPajakEdit = 'reference/mappingpajak/edit/'
export const masterMappingPajakDelete = 'reference/mappingpajak/delete/'

export const masterMappingPpspmCreate = 'reference/cms/create_ppspm/'
export const masterMappingPpspmListOperator = 'reference/cms/lists_ppspm/'
export const masterMappingPpspmListApprover = 'reference/cms/lists_approver_ppspm/'
export const masterMappingPpspmDelete = 'reference/cms/delete_ppspm/'

// CMS
export const masterCmsDPListLayanan = 'reference/cms/lists_layanan/'
export const masterCmsDPCreate = 'reference/cms/create_dashboard_pegawai/'
export const masterCmsGetLayanan = 'reference/cms/get_layanan/'
export const masterCmsDPEdit = 'reference/cms/update_dashboard_pegawai/'
export const masterCmsDPDetail= 'reference/cms/detail_dashboard_pegawai/'
export const masterCmsDPDelete = 'reference/cms/delete_dashboard_pegawai/'

export const masterCmsInformasi = 'reference/cms/create_informasi/'
export const masterCmsGetInformasi = 'reference/cms/get_informasi/'
export const masterCmsInformasiEdit = 'reference/cms/update_informasi/'
export const masterCmsInformasiDetail= 'reference/cms/detail_informasi/'
export const masterCmsInformasiDelete = 'reference/cms/delete_informasi/'
export const masterCmsListPengumuman = 'reference/cms/list_pengumuman/'
export const masterCmsListPeraturan = 'reference/cms/list_peraturan/'
export const masterCmsListfaq = 'reference/cms/list_faq/'

// mapping iipc
export const masterMappingIIPC = 'reference/mappingiipc/create_mapping_iipc/'
export const masterGetMappingIIPC = 'reference/mappingiipc/get_mapping_iipc/'
export const masterMappingIIPCEdit = 'reference/mappingiipc/update_mapping_iipc/'
export const masterMappingIIPCDetail= 'reference/mappingiipc/detail_mapping_iipc/'
export const masterMappingIIPCDelete = 'reference/mappingiipc/delete_mapping_iipc/'
export const masterMappingIIPCList = 'reference/mappingiipc/list_mapping_iipc/'

export const masterCmsDPListSLayanan = 'reference/cms/lists_slayanan/'
export const masterCmsDPCreateSLayanan = 'reference/cms/create_slayanan/'
export const masterCmsDPEditSLayanan = 'reference/cms/edit_slayanan/'
export const masterCmsDPDetailSLayanan = 'reference/cms/detail_slayanan/'

export const masterCmsDPListInformasi = 'reference/cms/lists_informasi/'
export const masterCmsDPCreateInformasi = 'reference/cms/create_informasi/'
export const masterCmsDPEditInformasi = 'reference/cms/edit_informasi/'
export const masterCmsDPDetailInformasi = 'reference/cms/detail_informasi/'

export const masterMappingNrsList = 'reference/mappingnrs/lists/'
export const masterMappingNrsCreate = 'reference/mappingnrs/create/'
export const masterMappingNrsEdit = 'reference/mappingnrs/edit/'
export const masterMappingNrsDetail = 'reference/mappingnrs/detail/'
export const masterMappingNrsDelete = 'reference/mappingnrs/delete/'
export const masterMappingNrsListNrs = 'reference/mappingnrs/listnrs/'
export const masterMappingNrsEmployee = 'reference/mappingnrs/employees/'
export const masterMappingNrsUpload = 'reference/mappingnrs/upload/'

export const masterMappingPajakEmployees = 'reference/mappingpajak/employees/'

export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken = true) => {
    if (needToken) {
        const thisTime = (Math.floor(Date.now() / 1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if (expire < thisTime) {
            await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/', data)
                .then(result => {
                    localStorage.setItem(initial + 'accessToken', result.response.access_token)
                    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
                })
                .catch(result => {
                    return Promise.reject('Tidak dapat mengambil accessToken');
                })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}