import React, { useState, Fragment, Component } from "react"
import { Row, Col, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import ProcessBloc from "../../../Ppk/Processdata/Blocs/ProcessBloc"

class OfficialTravelUpdateLocalComponent extends Component {
    processBloc = new ProcessBloc()
    
    constructor(props) {
        super(props)
        this.state = {
            formData: [],
            maxDay: 0,
            activeTab: 0,
            loading: false
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    numberWithPoint(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    componentDidMount() {
        this.setState({
            formData: this.props.EmployeesDatas
        })
    }

    componentWillUnmount() {}

    handleSubmit = async () => {
        try {
            this.setState({ loading: true })
            const { formData } = this.state
            
            // Prepare data for API
            const payload = {
                process_uuid: this.props.processUuid,
                ...formData.reduce((acc, destination) => {
                    // Daily allowance mapping (category 1)
                    destination.daily.items.forEach((item, type) => {
                        if (type > 0) {
                            acc[`${item[0]}_1_${type}_q`] = item[1]
                            acc[`${item[0]}_1_${type}_a`] = item[2]
                            acc[`${item[0]}_1_${type}_t`] = item[3]
                        }
                    })

                    // Hotel mapping (category 2)
                    destination.hotel.items.forEach((item, type) => {
                        if ([1, 4].includes(type)) {
                            acc[`${item[0]}_2_${type}_q`] = item[1]
                            acc[`${item[0]}_2_${type}_a`] = item[2]
                            acc[`${item[0]}_2_${type}_t`] = item[3]
                        }
                    })

                    // Transport mapping (category 3)
                    destination.transport.items.forEach((item, type) => {
                        if ([1, 4, 21, 24, 31, 34].includes(type)) {
                            acc[`${item[0]}_3_${type}_q`] = item[1]
                            acc[`${item[0]}_3_${type}_a`] = item[2]
                            acc[`${item[0]}_3_${type}_t`] = item[3]
                        }
                    })

                    // Representative mapping (category 4)
                    destination.representative.items.forEach((item, type) => {
                        if (type === 1) {
                            acc[`${item[0]}_4_${type}_q`] = item[1]
                            acc[`${item[0]}_4_${type}_a`] = item[2]
                            acc[`${item[0]}_4_${type}_t`] = item[3]
                        }
                    })
                    
                    return acc
                }, {})
            }

            await this.processBloc.fetchUpdateMultiDestination(payload)
            
            this.props.closeModal()
            this.props.onSuccess()
            
        } catch (error) {
            console.error('Error updating data:', error)
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <Fragment>
                <div className="modal-body">
                    <Col md="12">
                        <Nav tabs className="mb-3">
                            {this.props.EmployeesDatas.map((destination, idx) => (
                                <NavItem key={idx}>
                                    <NavLink
                                        className={this.state.activeTab === idx ? 'active' : ''}
                                        onClick={() => this.toggle(idx)}
                                    >
                                        {destination.destination.city_name}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            {this.props.EmployeesDatas.map((destination, idx) => (
                                <TabPane tabId={idx} key={idx} data-tab={idx}>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered table-hover" style={{ minWidth: 750 }}>
                                            <Thead>
                                                <Tr>
                                                    <Th className="text-center" width="245">
                                                        Uang Harian
                                                    </Th>
                                                    <Th className="text-center" width="170">
                                                        Penginapan
                                                    </Th>
                                                    <Th className="text-center" width="170">
                                                        Transportasi
                                                    </Th>
                                                    <Th className="text-center" width="140">
                                                        Representatif
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <tr>
                                                    <td className="text-end">
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Biasa
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 40 }}>
                                                                    {destination ? destination.daily.items[1][1] : 0}
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination.daily.items[1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Fullday
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 40 }}>
                                                                    {destination ? destination.daily.items[2][1] : 0}
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination.daily.items[2][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1" style={{ display: this.state.formData.oversea === 2 ? "none" : "" }}>
                                                            <Col className="col-auto">
                                                                <div className="text-start" style={{ width: 65 }}>
                                                                    Fullboard
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 40 }}>
                                                                    {destination ? destination.daily.items[3][1] : 0}
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination.daily.items[3][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination["hotel"]["items"][1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination["hotel"]["items"][4][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Darat
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination["transport"]["items"][1][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination["transport"]["items"][4][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Laut
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination["transport"]["items"][21][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination["transport"]["items"][24][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-12">
                                                                <label className="m-0 card ps-2 me-2" style={{ fontSize: 10, textTransform: "uppercase", fontWeight: 600, letterSpacing: 4 }}>
                                                                    Udara
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Biasa</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination["transport"]["items"][31][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 align-items-center mb-1">
                                                            <Col className="col-auto">
                                                                <div style={{ width: 40 }}>Riil</div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div className="form-control text-end" style={{ width: 100 }}>
                                                                    {destination ? this.numberWithPoint(destination["transport"]["items"][34][2].replace(".00", "").replace(".", ",")) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <div 
                                                            style={{ 
                                                                display: this.state.formData.oversea === 2 ? "none" : "",
                                                                width: 100 
                                                            }}
                                                            className="form-control text-end"
                                                        >
                                                            {destination ? this.numberWithPoint(destination["representative"]["items"][1][2].replace(".00", "").replace(".", ",")) : 0}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination["daily"]["total"].replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination["hotel"]["total"].replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination["transport"]["total"].replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                    <td className="text-end">
                                                        <b>Rp. {destination ? this.numberWithPoint(destination["representative"]["total"].replace(".00", "").replace(".", ",")) : 0},-</b>
                                                    </td>
                                                </tr>
                                            </Tbody>
                                        </Table>
                                    </div>
                                </TabPane>
                            ))}
                        </TabContent>
                    </Col>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger waves-effect"
                        onClick={() => this.props.closeModal()}

                    >
                        Tutup
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default OfficialTravelUpdateLocalComponent
