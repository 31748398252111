import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect"
import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal,
    Alert,
    Spinner,
} from "reactstrap"

import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import { generateFormData } from "../../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect, withRouter } from "react-router-dom"
import apiResponse from "../../../../../services/apiResponse"
import EmployeeListComponent from "./Components/EmployeeListComponent"
import CreateBloc from "./Blocs/CreateBloc"
import CustomSelect from "../../../../../helpers/ui/CustomSelect"

import LoadingComponent from "./Components/LoadingComponent"
import { timesSeries } from "async"

class CreateView extends Component {
    createBloc = new CreateBloc()

    source
    constructor(props) {
        super(props)
        this.state = {
            tplnStatus: null,
            tplnKurs: 0,
            tplnReason: "",
            tplnStatusName: "",
            uuid: null,
            dataForm: {},
            loadingCheckAll: false,
            //loadingCheckKurs: true,
            loadingCheckGpp: true,
            loadingCheckPtkp: true,
            loadingCheckPkp: true,
            loadingCheckCountry: true,
            loadingCheckRank: true,

            //loadingCheckKursStatus: false,
            loadingCheckPtkpStatus: false,
            loadingCheckPkpStatus: false,
            loadingCheckCountryStatus: false,
            loadingCheckRankStatus: false,
            loadingCheckGppStatus: false,

            loadingSignerLeft: true,
            loadingSignerRight: true,
        }
        this.createBloc.fetchCheckStatus({ uuid: this.props.uuid })
    }

    allPAss = () => {
        if (
            //this.state.loadingCheckKursStatus === true &&
            this.state.loadingCheckPtkpStatus === true &&
            this.state.loadingCheckPkpStatus === true &&
            this.state.loadingCheckCountryStatus === true &&
            this.state.loadingCheckRankStatus === true &&
            this.state.loadingCheckGppStatus === true &&
            this.state.loadingSignerLeft === false &&
            this.state.loadingSignerRight === false
        ) {
            this.setState({
                loadingCheckAll: true,
            })
        } else {
            this.setState({
                loadingCheckAll: false,
            })
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm)
        await this.createBloc.fetchCreate(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            confirm: true,
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.createBloc.checkStatusChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    if (response.status === 1 || response.status === 3) {
                        this.setState({
                            tplnKurs: (response.kurs ?? "0").replace(".00", ""),
                            tplnStatus: response.status,
                            tplnReason: response.reason,
                        })
                        //this.createBloc.fetchCheckKurs({uuid: this.props.uuid})
                        this.createBloc.fetchCheckGpp({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckPtkp({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckPkp({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckCountry({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckRank({ uuid: this.props.uuid })
                        this.createBloc.fetchGetSignerLeft({ uuid: "8e76798e-b27f-4276-a375-60a001d3c225" })
                        this.createBloc.fetchGetSignerRight({ uuid: "2f087de1-7714-441c-9255-61279bdd87f2" })
                    } else {
                        this.setState({
                            tplnStatusName: "Proses tidak dapat dilakukan",
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })

        this.createBloc.getSignerLeft.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingSignerLeft: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingSignerLeft: false,
                        signerLeft: response,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })

        this.createBloc.getSignerRight.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingSignerRight: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingSignerRight: false,
                        signerRight: response,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })

        /* this.createBloc.checkKursChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if(response.status === true) {
                        this.setState({
                            loadingCheckKurs: false,
                            loadingCheckKursStatus: true,
                            loadingCheckKursText: 'Data Kurs tersedia'
                        });
                    } else {
                        this.setState({
                            loadingCheckKurs: false,
                            loadingCheckKursStatus: false,
                            loadingCheckKursText: response.message
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
            this.allPAss()
        }) */
        this.createBloc.checkGppChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckGpp: false,
                            loadingCheckGppStatus: true,
                            loadingCheckGppText: "Data GPP tersedia",
                            loadingCheckGppDetail: [],
                        })
                    } else {
                        this.setState({
                            loadingCheckGpp: false,
                            loadingCheckGppStatus: false,
                            loadingCheckGppText: response.message,
                            loadingCheckGppDetail: response.response,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkPtkpChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckPtkp: false,
                            loadingCheckPtkpStatus: true,
                            loadingCheckPtkpText: "Data PTKP tersedia",
                        })
                    } else {
                        this.setState({
                            loadingCheckPtkp: false,
                            loadingCheckPtkpStatus: false,
                            loadingCheckPtkpText: response.message,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkPkpChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckPkp: false,
                            loadingCheckPkpStatus: true,
                            loadingCheckPkpText: "Data PKP tersedia",
                        })
                    } else {
                        this.setState({
                            loadingCheckPkp: false,
                            loadingCheckPkpStatus: false,
                            loadingCheckPkpText: response.message,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkCountryChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckCountry: false,
                            loadingCheckCountryStatus: true,
                            loadingCheckCountryText: "Data Nilai untuk ADTLN masing-masing Negara tersedia",
                            loadingCheckCountryDetail: [],
                        })
                    } else {
                        this.setState({
                            loadingCheckCountry: false,
                            loadingCheckCountryStatus: false,
                            loadingCheckCountryText: response.message,
                            loadingCheckCountryDetail: response.response,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkRankChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckRank: false,
                            loadingCheckRankStatus: true,
                            loadingCheckRankText: "Data Nilai untuk APTLN masing-masing Negara tersedia",
                            loadingCheckRankDetail: [],
                        })
                    } else {
                        this.setState({
                            loadingCheckRank: false,
                            loadingCheckRankStatus: false,
                            loadingCheckRankText: response.message,
                            loadingCheckRankDetail: response.response,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        //this.createBloc.verifyChannel?.unsubscribe()
        this.createBloc.checkStatusChannel?.unsubscribe()
        //this.createBloc.checkKursChannel?.unsubscribe()
        this.createBloc.checkGppChannel?.unsubscribe()
        this.createBloc.checkPtkpChannel?.unsubscribe()
        this.createBloc.checkCountryChannel?.unsubscribe()
        this.createBloc.checkRankChannel?.unsubscribe()
        this.createBloc.getSignerLeft?.unsubscribe()
        this.createBloc.getSignerRight?.unsubscribe()
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if (last.length >= 10) {
            event.preventDefault()
        }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault()
            }
        }
    }
    handleKeyUp = (event, id) => {
        const _this = document.querySelector("#" + id)
        var value = event.target.value
        value = value.charAt(0) === "0" ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Proses Tunjangan TPLN Rapel</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Pengecekan data Tunjangan TPLN Rapel perpegawain dan pemrosesan data Tunjangan
                                        Kinerja
                                    </CardSubtitle>
                                    <hr />
                                    {this.state.tplnStatus ? (
                                        <>
                                            {this.state.tplnStatus === 3 ? (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col md="12">
                                                            <Alert color="warning">
                                                                <b>Data sebelumnya ditolak:</b> {this.state.tplnReason}
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                            <AvForm
                                                className="needs-validation"
                                                onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                            >
                                                <AvField name="uuid" type="hidden" id="uuid" value={this.props.uuid} />
                                                <Row className="mb-3">
                                                    <Col md="12">
                                                        {/* {this.state.loadingCheckKurs === true ? (
                                                            <LoadingComponent message="Memeriksa Informasi Kurs..." />
                                                        ) : (
                                                            <Alert color={this.state.loadingCheckKursStatus ? "success" : "danger"}>
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td width="30px" style={{ verticalAlign: "top" }}>
                                                                            <i className={"uil " + (this.state.loadingCheckKursStatus ? "uil-check" : "uil-times")}></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>{this.state.loadingCheckKursText}</td>
                                                                        {!this.state.loadingCheckKursStatus ? (
                                                                            <td className="text-end" style={{ verticalAlign: "top" }}>
                                                                                <Link to="/master/referensi-kurs.html">Master Kurs</Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )} */}
                                                        {this.state.loadingCheckPtkp === true ? (
                                                            <LoadingComponent message="Memeriksa Data Referensi PTKP..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckPtkpStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckPtkpStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckPtkpText}
                                                                        </td>
                                                                        {!this.state.loadingCheckPtkpStatus ? (
                                                                            <td
                                                                                className="text-end"
                                                                                style={{ verticalAlign: "top" }}
                                                                            >
                                                                                <Link to="/master/referensi-ptkp.html">
                                                                                    Master PTKP
                                                                                </Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                        {this.state.loadingCheckPkp === true ? (
                                                            <LoadingComponent message="Memeriksa Data Referensi PKP..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckPkpStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckPkpStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckPkpText}
                                                                        </td>
                                                                        {!this.state.loadingCheckPkpStatus ? (
                                                                            <td
                                                                                className="text-end"
                                                                                style={{ verticalAlign: "top" }}
                                                                            >
                                                                                <Link to="/master/pajak-ptkp.html">
                                                                                    Master PTKP
                                                                                </Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                        {this.state.loadingCheckCountry === true ? (
                                                            <LoadingComponent message="Memeriksa Nilai ADTLN masing-masing Negara..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckCountryStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state
                                                                                        .loadingCheckCountryStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckCountryText}
                                                                            <br />
                                                                            {this.state.loadingCheckCountryDetail.map(
                                                                                (item, key) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <small>{item}</small>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </td>
                                                                        {!this.state.loadingCheckCountryStatus ? (
                                                                            <td
                                                                                className="text-end"
                                                                                style={{ verticalAlign: "top" }}
                                                                            >
                                                                                <Link to="/master/negara.html">
                                                                                    Referensi ADTLN
                                                                                </Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                        {this.state.loadingCheckRank === true ? (
                                                            <LoadingComponent message="Memeriksa Nilai APTLN masing-masing Negara..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckRankStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckRankStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckRankText}
                                                                            <br />
                                                                            {this.state.loadingCheckRankDetail.map(
                                                                                (item, key) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <small>{item}</small>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </td>
                                                                        {!this.state.loadingCheckRankStatus ? (
                                                                            <td
                                                                                className="text-end"
                                                                                style={{ verticalAlign: "top" }}
                                                                            >
                                                                                <Link to="/master/tpln.html">
                                                                                    Referensi APTLN
                                                                                </Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                        {this.state.loadingCheckGpp === true ? (
                                                            <LoadingComponent message="Memeriksa data Gaji Pokok dari GPP..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckGppStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckGppStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckGppText}
                                                                            <br />
                                                                            {this.state.loadingCheckGppDetail.map(
                                                                                (item, key) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <small>{item}</small>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </td>
                                                                        {!this.state.loadingCheckGppStatus ? (
                                                                            <td
                                                                                className="text-end"
                                                                                style={{ verticalAlign: "top" }}
                                                                            >
                                                                                <Link to="/integration/sigap-gaji.html">
                                                                                    Data GPP
                                                                                </Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                        <hr style={{ marginTop: 0 }} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="12">
                                                        <EmployeeListComponent uuid={this.props.uuid} />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Label htmlFor="fieldNama" className="col-md-3 col-form-label">
                                                        Bendahara Pengeluaran
                                                    </Label>
                                                    <Col md="5">
                                                        {this.state.loadingSignerLeft ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "100%", height: 38 }}
                                                                    ></div>
                                                                </Shimmer>{" "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CustomSelect
                                                                    name="signer_left"
                                                                    errorMessage="Pilih Penandatangan"
                                                                    options={this.state.signerLeft}
                                                                    defaultValue={null}
                                                                    placeholder="Pilih Penandatangan"
                                                                    optionLabel="signer_employee_name"
                                                                    optionValue="signer_uuid"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="fieldNama" className="col-md-3 col-form-label">
                                                        Pejabat Pembuat Komitmen
                                                    </Label>
                                                    <Col md="5">
                                                        {this.state.loadingSignerRight ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "100%", height: 38 }}
                                                                    ></div>
                                                                </Shimmer>{" "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CustomSelect
                                                                    name="signer_right"
                                                                    errorMessage="Pilih Penandatangan"
                                                                    options={this.state.signerRight}
                                                                    defaultValue={null}
                                                                    placeholder="Pilih Penandatangan"
                                                                    optionLabel="signer_employee_name"
                                                                    optionValue="signer_uuid"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="fieldNama" className="col-md-3 col-form-label">
                                                        Nilai Kurs
                                                    </Label>
                                                    <Col md="2">
                                                        <AvField
                                                            name="kurs_value"
                                                            placeholder="0"
                                                            align="right"
                                                            type="text"
                                                            defaultValue={this.state.tplnKurs
                                                                .replace(/\D/g, "")
                                                                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                            errorMessage="Masukkan Nilai"
                                                            className="form-control text-end"
                                                            id="fieldValue"
                                                            validate={{ required: { value: true } }}
                                                            onKeyPress={(e) => {
                                                                this.handleKeyPress(e, "fieldValue")
                                                            }}
                                                            onKeyUp={(e) => {
                                                                this.handleKeyUp(e, "fieldValue")
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <div className="form-check form-switch mb-3">
                                                            <AvCheckboxGroup
                                                                name="checkboxExample"
                                                                required
                                                                errorMessage="Agreement harus di centang"
                                                            >
                                                                <AvCheckbox
                                                                    label="Saya yakin dan bertanggung jawab atas data ini"
                                                                    value="1"
                                                                />
                                                            </AvCheckboxGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <Link to="/payroll/tpln-rapel.html">
                                                            <Button color="danger" type="button">
                                                                <i className="uil-arrow-left"></i> Kembali
                                                            </Button>
                                                        </Link>{" "}
                                                        <Button
                                                            color="success"
                                                            type="submit"
                                                            disabled={!this.state.loadingCheckAll}
                                                        >
                                                            <i className="uil-arrow-up-right"></i> Proses Data TPLN
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </>
                                    ) : (
                                        <>
                                            {this.state.tplnStatusName === "" ? (
                                                <LoadingComponent message="Memeriksa status TPLN..." />
                                            ) : (
                                                <>
                                                    <Row>
                                                        <Col md="12">
                                                            <Alert color="danger">
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i className="uil uil-times"></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.tplnStatusName}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            <hr style={{ marginTop: 0 }} />
                                            <Row>
                                                <Col md="10">
                                                    <Link to="/payroll/tpln-rapel.html">
                                                        <Button color="danger" type="button">
                                                            <i className="uil-arrow-left"></i> Kembali
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"
                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                {this.state.redirect ? <Redirect to="/payroll/tpln-rapel.html" /> : null}
            </Fragment>
        )
    }
}

export default CreateView
