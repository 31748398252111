import { accessRole } from "../../../../../../helpers/access_role"
import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button
} from "reactstrap"

import TableActiveComponent from "./Components/TableActiveComponent"

const IndexView = (props) => {

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Mapping SPT</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Informasi Mapping SPT Per Kategori
                                </CardSubtitle>
                                <hr />
                                <div className="p-3">
                                    <TableActiveComponent />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView