import {
    apiRequest,
    masterCmsDPDetail,
    masterCmsDPEdit,
    masterCmsGetLayanan
} from "../../../../../services/adapters/master"

class UpdateRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", masterCmsDPDetail, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterCmsDPEdit, {
            body: query
        }, true)
    }

    fetchGetLayanan = async () => {
        return await apiRequest("get", masterCmsGetLayanan, {}, true)
    }
}

export default UpdateRepository