import React, { Fragment, Component } from "react"
import base64 from "base-64"
import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Label, Modal, Alert } from "reactstrap"
import Select from "react-select"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Shimmer from "react-shimmer-effect"
import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import CustomSelect from "../../../../../helpers/ui/CustomSelect"
import { FileInput, generateFormData } from "../../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect } from "react-router-dom"
import CreateBloc from "./Blocs/CreateBloc"
import apiResponse from "../../../../../services/apiResponse"

import OfficialTravelDetailComponent from "./Components/OfficialTravelDetailComponent"
import OfficialTravelConflictComponent from "./Components/OfficialTravelConflictComponent"
import OfficialTravelRefundComponent from "./Components/OfficialTravelRefundComponent"
import OfficialTravelTransportComponent from "./Components/OfficialTravelTransportComponent"

import moment from "moment"

class CreateView extends Component {
    createBloc = new CreateBloc()

    source
    constructor(props) {
        super(props)
        this.state = {
            loadingUnprocessList: false,
            unprocessList: [],
            routeUuid: null,
            loadingParticipantList: false,
            participantList: {},

            isCheckedAll01: false,
            isCheckedRow01: {},
            selectedParticipant: {},

            showInfoSBM: false,

            detailUuid: null,
            showDetail: false,
            processUuid: null,

            dataForm: {},
            submissionCheck: [0, 0, 0, 0],

            conflictOfficialTravelUuid: null,
            lastOptionValue: {},
            showConflict: false,
            participantUuid: null,

            showRefund: false,
            refundConflictUuid: null,
            refundProcessUuid: null,
            refundConflictUse: "",
            refundConfirmText: "",
            refundConflictData: {},
            multipe_destination: null,

            ppkUuid: null,
            directPpk: false,

            showTransport: false,
            overseaType: null,

            selectedType: null,
            optionType: [
                { value: 1, label: "Dalam Negeri" },
                { value: 2, label: "Luar Negeri" },
            ],
        }
    }

    handleOnChangeType = (selectedType) => {
        if (this.state.selectedType === null || this.state.selectedType.value !== selectedType.value) {
            this.setState(
                {
                    selectedType: selectedType,
                    loadingUnprocessList: true,
                    participantList: {},
                    isCheckedRow01: {},
                    isCheckedAll01: false,
                    selectedParticipant: {},
                    submissionCheck: [0, 0, 0, 0],
                },
                () => {
                    this.createBloc.fetchUnprocessList({
                        type: this.state.selectedType.value,
                    })
                }
            )
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, {
            field: "document",
            name: "Trash/Unknown",
        })
        await this.createBloc.fetchCreateInitial(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        var participants = []
        Object.entries(this.state.selectedParticipant).map((a) => {
            participants.push(a[0])
        })
        this.setState({
            dataForm: {
                ...values,
                submissions: this.state.submissionCheck,
                collective: 0, //values.collective[0] === undefined ? 0 : 1,
                participants: participants,
                type: this.state.selectedType.value,
            },
            confirm: true,
        })
    }

    openRefundForm = (cUuid, pUuid, cUse, cText, cData) => {
        this.setState(
            {
                refundConflictUuid: cUuid,
                refundProcessUuid: pUuid,
                refundConflictUse: cUse,
                refundConfirmText: cText,
                refundConflictData: cData,
                showConflict: false,
            },
            () => {
                this.setState({
                    showRefund: true,
                })
            }
        )
    }

    cancelRefund = () => {
        this.setState({
            showRefund: false,
            refundConflictUuid: null,
            refundProcessUuid: null,
            refundConflictUse: "",
            refundConfirmText: "",
            refundConflictData: {},
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    handlerConflictSolved = () => {
        var _tempParticipantList = this.state.participantList
        var _tempIsCheckedRow01 = this.state.isCheckedRow01
        var resetUuid = this.state.conflictOfficialTravelUuid
        var value = this.state.lastOptionValue
        this.setState(
            {
                showConflict: false,
                showRefund: false,
                showTransport: false,
                participantList: {},
                isCheckedRow01: {},
            },
            function () {
                for (var v = 0; v < value.length; ++v) {
                    if (typeof _tempParticipantList[value[v].official_travel_uuid] != "undefined" && value[v].official_travel_uuid !== resetUuid) {
                        var _participantList = this.state.participantList
                        _participantList[value[v].official_travel_uuid] = _tempParticipantList[value[v].official_travel_uuid]

                        var _isCheckedRow01 = this.state.isCheckedRow01
                        for (var p = 0; p < _tempParticipantList[value[v].official_travel_uuid].length; ++p) {
                            if (_tempParticipantList[value[v].official_travel_uuid][p].official_travel_conflict_status === 0 && _tempParticipantList[value[v].official_travel_uuid][p].official_travel_participant_transport_type !== null) {
                                _isCheckedRow01[_tempParticipantList[value[v].official_travel_uuid][p].official_travel_participant_uuid] = _tempIsCheckedRow01[_tempParticipantList[value[v].official_travel_uuid][p].official_travel_participant_uuid]
                            }
                        }
                        this.setState({
                            participantList: _participantList,
                            isCheckedRow01: _isCheckedRow01,
                        })
                    }
                }
                this.handleChangeUnprocessList(this.state.lastOptionValue)
            }
        )
    }

    handleChangeUnprocessList = (value) => {
        var _tempParticipantList = this.state.participantList
        var _tempIsCheckedRow01 = this.state.isCheckedRow01
        this.setState(
            {
                lastOptionValue: value,
                participantList: {},
                isCheckedRow01: {},
                conflictOfficialTravelUuid: null,
            },
            function () {
                if (value !== null) {
                    for (var v = 0; v < value.length; ++v) {
                        if (typeof _tempParticipantList[value[v].official_travel_uuid] != "undefined") {
                            var _participantList = this.state.participantList
                            _participantList[value[v].official_travel_uuid] = _tempParticipantList[value[v].official_travel_uuid]

                            var _isCheckedRow01 = this.state.isCheckedRow01
                            for (var p = 0; p < _tempParticipantList[value[v].official_travel_uuid].length; ++p) {
                                if (_tempParticipantList[value[v].official_travel_uuid][p].official_travel_conflict_status === 0 && _tempParticipantList[value[v].official_travel_uuid][p].official_travel_participant_transport_type !== null) {
                                    _isCheckedRow01[_tempParticipantList[value[v].official_travel_uuid][p].official_travel_participant_uuid] = _tempIsCheckedRow01[_tempParticipantList[value[v].official_travel_uuid][p].official_travel_participant_uuid]
                                }
                            }
                            this.setState({
                                participantList: _participantList,
                                isCheckedRow01: _isCheckedRow01,
                            })
                        } else {
                            this.createBloc.fetchUnprocessParticipantList({
                                uuid: value[v].official_travel_uuid,
                            })
                        }
                    }
                }
            }
        )
    }

    componentDidMount() {
        this.createBloc.createInitialChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    var response = result.data.response
                    this.setState({
                        processUuid: response,
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.createBloc.unprocessListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({})
                    break
                case apiResponse.COMPLETED:
                    if (this.props.uuid) {
                        this.createBloc.fetchUnprocessParticipantList({
                            uuid: this.props.uuid,
                        })
                    }
                    this.setState({
                        loadingUnprocessList: false,
                        unprocessList: result.data.response.length > 0 ? result.data.response : [],
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loadingUnprocessList: false,
                    })
                    break
                default:
                    break
            }
        })

        this.createBloc.unprocessParticipantListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingParticipantList: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    var response = result.data.response
                    var _participantList = this.state.participantList
                    _participantList[response.uuid] = response.participant

                    var _isCheckedRow01 = this.state.isCheckedRow01
                    for (var p = 0; p < response.participant.length; ++p) {
                        if (response.participant[p].official_travel_conflict_status === 0 && response.participant[p].official_travel_participant_transport_type !== null) {
                            _isCheckedRow01[response.participant[p].official_travel_participant_uuid] = false
                        }
                    }
                    this.setState({
                        loadingParticipantList: false,
                        participantList: _participantList,
                        isCheckedRow01: _isCheckedRow01,
                        isCheckedAll01: response.participant.length > 0 ? false : this.state.isCheckedAll01,
                        multipe_destination: response?.multipe_destination
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })

        /* if (this.props.uuid) {
            var uuid = base64.decode(this.props.uuid)
            const props = uuid.split('|');
            this.setState({
                routeUuid: props[0],
                selectedType: { value: props[1], label: props[1] === 1 ? 'Dalam Negeri' : 'Dalam Negeri' }
            })
        } */
    }

    componentWillUnmount() {
        this.createBloc.createInitialChannel?.unsubscribe()
        this.createBloc.unprocessListChannel?.unsubscribe()
        this.createBloc.unprocessParticipantListChannel?.unsubscribe()
    }

    objectLength = (object) => {
        var length = 0
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                ++length
            }
        }
        return length
    }

    closeTransport = () => {
        this.setState({
            showTransport: false,
        })
    }

    handleSubmissionChange = (index) => {
        var lastSubmissionCheck = this.state.submissionCheck
        lastSubmissionCheck[index] = lastSubmissionCheck[index] === 1 ? 0 : 1
        
        // Update isCheckedRow01 based on submission types
        let _isCheckedRow01 = {...this.state.isCheckedRow01}
        let _selectedParticipant = {...this.state.selectedParticipant}

        // Jika semua submission unchecked, hapus semua checklist
        if (lastSubmissionCheck.every(check => check === 0)) {
            _isCheckedRow01 = {}
            _selectedParticipant = {}
        } else {
            // Proses normal untuk mengecek konflik
            Object.entries(this.state.participantList).forEach(([key, participants]) => {
                participants.forEach(participant => {
                    const uuid = participant.official_travel_participant_uuid
                    
                    const shouldBeDisabled = (
                        (index === 0 && participant.official_travel_process_type1_official_travel_other_uuid !== null) ||
                        (index === 1 && participant.official_travel_process_type2_official_travel_other_uuid !== null) ||
                        (index === 2 && participant.official_travel_process_type3_official_travel_other_uuid !== null) ||
                        (index === 3 && participant.official_travel_process_type4_official_travel_other_uuid !== null)
                    )

                    if (lastSubmissionCheck[index] === 1 && shouldBeDisabled) {
                        _isCheckedRow01[uuid] = false
                        delete _selectedParticipant[uuid]
                    }
                })
            })
        }

        this.setState({
            submissionCheck: lastSubmissionCheck,
            isCheckedRow01: _isCheckedRow01,
            selectedParticipant: _selectedParticipant,
            isCheckedAll01: false // Reset check all ketika ada perubahan submission
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Tambah Proses Pengajuan Perjalanan Dinas</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Tujuan<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="3">
                                                <Select
                                                    value={this.state.selectedType}
                                                    onChange={(selected) => {
                                                        this.handleOnChangeType(selected)
                                                    }}
                                                    options={this.state.optionType}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Surat Tugas<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6" style={{ height: 38 }}>
                                                {this.state.loadingUnprocessList ? (
                                                    <>
                                                        <Shimmer>
                                                            <div
                                                                className="shimmer"
                                                                style={{
                                                                    width: "100%",
                                                                    height: 38,
                                                                }}
                                                            ></div>
                                                        </Shimmer>
                                                    </>
                                                ) : (
                                                    <>
                                                        {this.state.unprocessList.length > 0 ? (
                                                            <CustomSelect
                                                                isMulti={true}
                                                                name="unprocessedList"
                                                                isDisabled={this.state.loadingParticipantList}
                                                                isLoading={this.state.loadingParticipantList}
                                                                errorMessage="Pilih Surat Tugas"
                                                                options={this.state.unprocessList}
                                                                //defaultValue={this.state.routeUuid}
                                                                noOptionsMessage={() => "Tidak ada Pilihan"}
                                                                placeholder="Pilih Surat Tugas"
                                                                optionLabel="official_travel_number_text"
                                                                optionValue="official_travel_uuid"
                                                                onChange={this.handleChangeUnprocessList}
                                                            />
                                                        ) : (
                                                            <>
                                                                {!this.state.selectedType ? (
                                                                    <Select value={null} placeholder="Pilih Surat Tugas" isDisabled={true} options={{}} classNamePrefix="select2-selection" />
                                                                ) : (
                                                                    <Alert color="warning" className="text-center" style={{ padding: 7, marginBottom: 6 }}>
                                                                        Belum ada surat tugas {this.state.selectedType.label} yang dapat diproses, silahkan <Link to="/official-travel/register.html?create">daftarkan</Link> terlebih dahulu
                                                                    </Alert>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="description" className="col-md-2 col-form-label">
                                                Keterangan
                                            </Label>
                                            <Col md="6">
                                                <AvField name="description" placeholder="Keterangan..." type="textarea" errorMessage="Masukkan Keterangan" className="form-control" id="fielDescription" />
                                            </Col>
                                        </Row>
                                        {/* <Row className="mb-3">
                                            <Label htmlFor="description" className="col-md-2 col-form-label">
                                                Jenis Pengajuan
                                            </Label>
                                            <Col md="6">
                                                <div className="form-check form-switch mb-3 mt-2">
                                                    <AvCheckboxGroup name="collective">
                                                        <AvCheckbox label="Pengajuan Kolektif" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <Row className="mb-3">
                                            <Label htmlFor="description" className="col-md-2 col-form-label">
                                                Data Pengajuan
                                            </Label>
                                            <Col md="6">
                                                <div className="form-check mb-3 mt-2">
                                                    <AvCheckboxGroup name="submissions" inline>
                                                        <span style={{ paddingRight: 20 }}>
                                                            <AvCheckbox
                                                                label="Uang Harian"
                                                                checked={this.state.submissionCheck[0] === 1}
                                                                value="1"
                                                                onChange={() => {
                                                                    this.handleSubmissionChange(0)
                                                                }}
                                                            />
                                                        </span>
                                                        <span style={{ paddingRight: 20 }}>
                                                            <AvCheckbox
                                                                label={this.state.selectedType !== null && this.state.selectedType.value === 2 ? "Perjalanan" : "Penginapan"}
                                                                checked={this.state.submissionCheck[1] === 1}
                                                                value="2"
                                                                onChange={() => {
                                                                    this.handleSubmissionChange(1)
                                                                }}
                                                            />
                                                        </span>
                                                        <span style={{ paddingRight: 20 }}>
                                                            <AvCheckbox
                                                                label="Transportasi"
                                                                checked={this.state.submissionCheck[2] === 1}
                                                                value="3"
                                                                onChange={() => {
                                                                    this.handleSubmissionChange(2)
                                                                }}
                                                            />
                                                        </span>
                                                        {this.state.selectedType !== null && this.state.selectedType.value === 2 ? (
                                                            <></>
                                                        ) : (
                                                            <span style={{ paddingRight: 20 }}>
                                                                <AvCheckbox
                                                                    label="Representatif"
                                                                    checked={this.state.submissionCheck[3] === 1}
                                                                    value="4"
                                                                    onChange={() => {
                                                                        this.handleSubmissionChange(3)
                                                                    }}
                                                                />
                                                            </span>
                                                        )}
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Partisipan<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                <div className="p-2 bd-highlight float-start" style={{ paddingLeft: 0 }}>
                                                    <span
                                                        className="btn btn-warning btn-sm"
                                                        style={{
                                                            padding: "0px 3px",
                                                            cursor: "default",
                                                            marginBottom: 4,
                                                        }}
                                                    >
                                                        <i className="uil-car-sideview"></i>
                                                    </span>{" "}
                                                    Tingkat biaya belum ditentukan
                                                </div>
                                                <div className="p-2 bd-highlight float-start">
                                                    <span
                                                        className="btn btn-danger btn-sm"
                                                        style={{
                                                            padding: "0px 3px",
                                                            cursor: "default",
                                                            marginBottom: 4,
                                                        }}
                                                    >
                                                        <i className="uil-link"></i>
                                                    </span>{" "}
                                                    Irisan tanggal
                                                </div>
                                                <div className="p-2 bd-highlight float-start">
                                                    <span
                                                        className="btn btn-secondary btn-sm"
                                                        style={{
                                                            padding: "0px 3px",
                                                            cursor: "default",
                                                            marginBottom: 4,
                                                        }}
                                                    >
                                                        <i className="uil-align-center-alt"></i>
                                                    </span>{" "}
                                                    Belum memiliki data SBM
                                                </div>
                                                <Table className="table table-striped table-bordered table-hover">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="20%" data-priority="1">
                                                                Nomor ST
                                                            </Th>
                                                            <Th width="50px" data-priority="1" className="text-center">
                                                                {/* <div className="form-check form-switch" style={{paddingLeft: '3em'}}> */}
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    name="thisCheck"
                                                                    disabled={this.state.submissionCheck.every(check => check === 0)}
                                                                    checked={this.state.isCheckedAll01}
                                                                    onChange={() => {
                                                                        let newValue = !this.state.isCheckedAll01
                                                                        let isCheckedRow01s = {}
                                                                        var _selectedParticipant = {}
                                                                        Object.entries(this.state.isCheckedRow01).map((a) => {
                                                                            isCheckedRow01s[a[0]] = newValue
                                                                            if (newValue) {
                                                                                _selectedParticipant[a[0]] = true
                                                                            }
                                                                        })
                                                                        this.setState({
                                                                            isCheckedRow01: isCheckedRow01s,
                                                                            isCheckedAll01: newValue,
                                                                            selectedParticipant: _selectedParticipant,
                                                                        })
                                                                    }}
                                                                />
                                                                {/* </div> */}
                                                            </Th>
                                                            <Th width="*" data-priority="1">
                                                                Nama
                                                            </Th>
                                                            <Th width="30%" data-priority="1" className="text-center">
                                                                Tanggal
                                                            </Th>
                                                            <Th width="10%" data-priority="1" className="text-center">
                                                                Total
                                                            </Th>
                                                            <Th width="20*" data-priority="1">
                                                                Jabatan
                                                            </Th>
                                                            <Th width="15%" data-priority="1" className="text-center">
                                                                Proses
                                                            </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <>
                                                            {Object.entries(this.state.participantList).map((item, key) => {
                                                                var _trGroup = []
                                                                Object.entries(item[1]).map((employee, i) => {
                                                                    var uuid = employee[1].official_travel_participant_uuid
                                                                    _trGroup.push(
                                                                        <tr key={i}>
                                                                            {i === 0 ? (
                                                                                <td
                                                                                    className="text-center"
                                                                                    style={{
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                    rowSpan={item[1].length}
                                                                                >
                                                                                    <span
                                                                                        className="link-primary"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    detailUuid: item[0],
                                                                                                },
                                                                                                function () {
                                                                                                    this.setState({
                                                                                                        showDetail: true,
                                                                                                    })
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {employee[1].official_travel_number}
                                                                                    </span>
                                                                                </td>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            <td className="text-center">
                                                                                {employee[1].official_travel_master_exist ? (
                                                                                    <>
                                                                                        {employee[1].official_travel_transport_exist ? (
                                                                                            <span
                                                                                                className="btn btn-warning btn-sm"
                                                                                                style={{
                                                                                                    padding: "0px 3px",
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        conflictOfficialTravelUuid: employee[1].official_travel_uuid,
                                                                                                        overseaType: employee[1].official_travel_oversea,
                                                                                                        participantUuid: employee[1].official_travel_participant_uuid,
                                                                                                        showTransport: true,
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                <i className="uil-car-sideview"></i>
                                                                                            </span>
                                                                                        ) : (
                                                                                            <>
                                                                                                {employee[1].official_travel_conflict_status === 0 ? (
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        value="1"
                                                                                                        name="xthisCheck"
                                                                                                        disabled={
                                                                                                            (this.state.submissionCheck.every(check => check === 0)) ||
                                                                                                            (this.state.submissionCheck[0] === 1 && employee[1].official_travel_process_type1_official_travel_other_uuid !== null) ||
                                                                                                            (this.state.submissionCheck[1] === 1 && employee[1].official_travel_process_type2_official_travel_other_uuid !== null) ||
                                                                                                            (this.state.submissionCheck[2] === 1 && employee[1].official_travel_process_type3_official_travel_other_uuid !== null) ||
                                                                                                            (this.state.submissionCheck[3] === 1 && employee[1].official_travel_process_type4_official_travel_other_uuid !== null)
                                                                                                        }
                                                                                                        checked={this.state.isCheckedRow01[uuid]}
                                                                                                        onChange={() => {
                                                                                                            let newValue = !this.state.isCheckedRow01[uuid]
                                                                                                            var _isCheckedRow01 = this.state.isCheckedRow01
                                                                                                            var _selectedParticipant = this.state.selectedParticipant
                                                                                                            _isCheckedRow01[uuid] = newValue
                                                                                                            if (!newValue) {
                                                                                                                delete _selectedParticipant[uuid]
                                                                                                            } else {
                                                                                                                _selectedParticipant[uuid] = true
                                                                                                            }

                                                                                                            var allChecked = true
                                                                                                            Object.entries(_isCheckedRow01).map((a) => {
                                                                                                                if (!a[1]) {
                                                                                                                    allChecked = false
                                                                                                                }
                                                                                                            })
                                                                                                            this.setState({
                                                                                                                isCheckedRow01: _isCheckedRow01,
                                                                                                                selectedParticipant: _selectedParticipant,
                                                                                                                isCheckedAll01: allChecked,
                                                                                                            })
                                                                                                        }}
                                                                                                    ></input>
                                                                                                ) : (
                                                                                                    <span
                                                                                                        className="btn btn-danger btn-sm"
                                                                                                        style={{
                                                                                                            padding: "0px 3px",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                conflictOfficialTravelUuid: employee[1].official_travel_uuid,
                                                                                                                participantUuid: employee[1].official_travel_participant_uuid,
                                                                                                                showConflict: true,
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="uil-link"></i>
                                                                                                    </span>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <Button
                                                                                        className="btn btn-secondary btn-sm"
                                                                                        style={{
                                                                                            padding: "0px 3px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                showInfoSBM: true,
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <i className="uil-align-center-alt"></i>
                                                                                    </Button>
                                                                                )}
                                                                            </td>
                                                                            <td className="text-left">{employee[1].official_travel_participant_employee_name}</td>
                                                                            <td className="text-center">
                                                                                {moment(employee[1].official_travel_start_date).format("Do MMM YYYY")} <i className="uil-arrow-right"></i> {moment(employee[1].official_travel_end_date).format("Do MMM YYYY")}
                                                                            </td>
                                                                            <td className="text-center">{employee[1].official_travel_participant_days} hari</td>
                                                                            <td className="text-left">{employee[1].official_travel_participant_position_name}</td>
                                                                            <td className="text-center">
                                                                                <div className="small" style={{ fontSize: '14px' }}>
                                                                                    {employee[1].official_travel_process_type1_official_travel_other_uuid !== null && (
                                                                                        <span className="badge bg-info me-1" title="Uang Harian sudah diproses">Uang Harian</span>
                                                                                    )}
                                                                                    {employee[1].official_travel_process_type2_official_travel_other_uuid !== null && (
                                                                                        <span className="badge bg-info me-1" title="Penginapan">Penginapan</span>
                                                                                    )}
                                                                                    {employee[1].official_travel_process_type3_official_travel_other_uuid !== null && (
                                                                                        <span className="badge bg-info me-1" title="Transportasi">Transportasi</span>
                                                                                    )}
                                                                                    {employee[1].official_travel_process_type4_official_travel_other_uuid !== null && employee[1].official_travel_oversea === 1 && (
                                                                                        <span className="badge bg-info me-1" title="Representatif">Representatif</span>
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                return _trGroup
                                                            })}
                                                            {this.state.loadingParticipantList ? (
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    </Tbody>
                                                </Table>
                                                <div className="bd-highlight">
                                                    <AvField name="participant_selected" errorMessage="Pilih minimal 1 partisipan" type="hidden" value={this.objectLength(this.state.selectedParticipant) === 0 ? "" : "1"} className="form-control" id="fieldNumber" validate={{ required: { value: true } }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                {this.state.unprocessList.length > 0 ? (
                                                    <>
                                                        <Button color="success" type="submit">
                                                            <i className="uil-arrow-up-right"></i> Simpan Data
                                                        </Button>{" "}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <Link to="/official-travel/submission.html">
                                                    <Button color="danger" type="button">
                                                        <i className="uil-arrow-left"></i> Kembali
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Detail Surat Tugas
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <OfficialTravelDetailComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.showConflict}
                    toggle={() => {
                        this.setState({
                            showConflict: !this.state.showConflict,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Lihat irisan tanggal Perjalanan Dinas
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showConflict: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">{this.state.showConflict ? <OfficialTravelConflictComponent uuid={this.state.participantUuid} onRefund={this.openRefundForm} onSolved={this.handlerConflictSolved} /> : <></>}</div>
                </Modal>

                <Modal
                    isOpen={this.state.showRefund}
                    /* toggle={() => {
                    this.setState({
                        showRefund: !this.state.showRefund
                    })
                }} */
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Form Pengembalian
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showRefund: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {this.state.showRefund ? <OfficialTravelRefundComponent cancelRefund={this.cancelRefund} conflictUuid={this.state.refundConflictUuid} processUuid={this.state.refundProcessUuid} conflictUse={this.state.refundConflictUse} confirmText={this.state.refundConfirmText} conflictData={this.state.refundConflictData} uuid={this.state.participantUuid} onSolved={this.handlerConflictSolved} /> : <></>}
                </Modal>

                <Modal
                    isOpen={this.state.showTransport}
                    toggle={() => {
                        this.setState({
                            showTransport: !this.state.showTransport,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Perbaharui Alat Angkutan dan Tingkat Biaya
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTransport: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <OfficialTravelTransportComponent participantUuid={this.state.participantUuid} overseaType={this.state.overseaType} closeTransport={this.closeTransport} onSolved={this.handlerConflictSolved} />
                </Modal>

                <Modal
                    isOpen={this.state.showInfoSBM}
                    toggle={() => {
                        this.setState({
                            showInfoSBM: !this.state.showInfoSBM,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Belum memiliki data SBM
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showInfoSBM: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <center>Silahkan hubungi bagian keuangan untuk melengkapi data SBM</center>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showInfoSBM: !this.state.showInfoSBM,
                                })
                            }}
                            className="btn btn-danger waves-effect"
                        >
                            Tutup
                        </button>
                    </div>
                </Modal>

                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan menyimpan data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil disimpan" failedTitle="Gagal!" failedInfo="Data gagal disimpan" showConfirm={this.state.confirm} showLoading={this.state.loading} showSuccess={this.state.success} showFailed={this.state.failed} response={this.confirmResponse} />
                {this.state.redirect ? <Redirect to={"/official-travel/submission.html?"+ (this.state.multipe_destination == 2 ? "processdata&uuid=" : "process&uuid=") + this.state.processUuid} /> : null}
                {this.state.directPpk ? <Redirect to={"/official-travel/register.html?detail&uuid=" + this.state.ppkUuid} /> : null}
            </Fragment>
        )
    }
}

export default CreateView
