import React, { Component } from "react"
import Shimmer from "react-shimmer-effect"
import { Row, Col, Alert } from "reactstrap"
import { Link } from "react-router-dom"
import MinioClient from "../../../../../../services/minioClient"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../../services/apiResponse"

import { Table, Thead, Tbody, Tr, Th, Td, Tfoot } from "react-super-responsive-table"

class SalaryDetailComponent extends Component {
    indexViewBloc = new IndexViewBloc()
    monthName = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ]

    constructor(props) {
        super(props)
        this.state = {
            detailData: {},
            loadingDetail: true,
        }
    }

    componentDidMount() {
        this.indexViewBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingDetail: true,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingDetail: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        detailData: response,
                        loadingDetail: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.fetchDetail({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.indexViewBloc.detailChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Col>
                    <Row className="mb-3">
                        <label className="col-md-4">NIP</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>{this.state.detailData.nip !== null ? this.state.detailData.nip : "-"}</>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-4">NPWP</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>{this.state.detailData.gpp_npwp !== null ? this.state.detailData.gpp_npwp : "-"}</>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-4">Kode Bank</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>
                                    {this.state.detailData.gpp_bank_code !== null
                                        ? this.state.detailData.gpp_bank_code
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-4">Nama Bank</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>
                                    {this.state.detailData.gpp_bank_name !== null
                                        ? this.state.detailData.gpp_bank_name
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-4">Nama Cabang Bank</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>
                                    {this.state.detailData.gpp_bank_branch_name !== null
                                        ? this.state.detailData.gpp_bank_branch_name
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-4">Nomor Rekening</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>
                                    {this.state.detailData.gpp_bank_account_number !== null
                                        ? this.state.detailData.gpp_bank_account_number
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-4">Nama Rekening</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>
                                    {this.state.detailData.gpp_bank_account_name !== null
                                        ? this.state.detailData.gpp_bank_account_name
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-4">Kode Jabatan</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>{this.state.detailData.kdjab !== null ? this.state.detailData.kdjab : "-"}</>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-4">Kode Golongan</label>
                        <Col md="8">
                            {!this.state.loadingDetail ? (
                                <>{this.state.detailData.kdgol !== null ? this.state.detailData.kdgol : "-"}</>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <div
                        style={{
                            color: !this.state.loadingDetail && this.state.detailData.nogaji !== null ? "" : "#aaaaaa",
                        }}
                    >
                        <Row className="mb-3">
                            <label className="col-md-4">Bulan Gaji</label>
                            <Col md="8">
                                {!this.state.loadingDetail ? (
                                    <>
                                        {this.state.detailData.gpp_month !== null
                                            ? this.monthName[this.state.detailData.gpp_month - 1] +
                                              " " +
                                              this.state.detailData.tahun
                                            : "-"}
                                    </>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </Col>
                        </Row>
                        {/* <Row className="mb-3">
                            <label className="col-md-4">
                                Bulan Gaji
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.gpp_month !== null ? this.state.detailData.bulan : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row> */}
                        {/* <Row className="mb-3">
                            <label className="col-md-4">
                                Tahun
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tahun !== null ? this.state.detailData.tahun : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row> */}
                        <Row className="mb-3">
                            <label className="col-md-4">Nomor Gaji</label>
                            <Col md="8">
                                {!this.state.loadingDetail ? (
                                    <>{this.state.detailData.nogaji !== null ? this.state.detailData.nogaji : "-"}</>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">Kode Jenis</label>
                            <Col md="8">
                                {!this.state.loadingDetail ? (
                                    <>{this.state.detailData.kdjns !== null ? this.state.detailData.kdjns : "-"}</>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">Kode Satker</label>
                            <Col md="8">
                                {!this.state.loadingDetail ? (
                                    <>
                                        {this.state.detailData.kdsatker !== null ? this.state.detailData.kdsatker : "-"}
                                    </>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">Kode Anak Satker</label>
                            <Col md="8">
                                {!this.state.loadingDetail ? (
                                    <>{this.state.detailData.kdanak !== null ? this.state.detailData.kdanak : "-"}</>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">Kode Sub-anak Satker</label>
                            <Col md="8">
                                {!this.state.loadingDetail ? (
                                    <>
                                        {this.state.detailData.kdsubanak !== null
                                            ? this.state.detailData.kdsubanak
                                            : "-"}
                                    </>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">Kode Kedudukan</label>
                            <Col md="8">
                                {!this.state.loadingDetail ? (
                                    <>{this.state.detailData.kdduduk !== null ? this.state.detailData.kdduduk : "-"}</>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">Kode Gaji Pokok</label>
                            <Col md="8">
                                {!this.state.loadingDetail ? (
                                    <>{this.state.detailData.kdgapok !== null ? this.state.detailData.kdgapok : "-"}</>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mb-3">
                            <Col md="12">
                                <Table
                                    className={
                                        "table table-bordered " +
                                        (!this.state.loadingDetail && this.state.detailData.nogaji !== null
                                            ? "table-striped table-hover"
                                            : "") +
                                        " "
                                    }
                                    style={{
                                        color:
                                            !this.state.loadingDetail && this.state.detailData.nogaji !== null
                                                ? ""
                                                : "#aaaaaa",
                                    }}
                                >
                                    <Thead>
                                        <Tr>
                                            <Th width="*" data-priority="1">
                                                Penghasilan
                                            </Th>
                                            <Th width="160px" data-priority="3" className="text-center">
                                                Jumlah
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <tr>
                                            <td className="ps-5">Gaji Pokok</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.gapok !== null
                                                            ? this.state.detailData.gapok
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Istri</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tistri !== null
                                                            ? this.state.detailData.tistri
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Anak</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tanak !== null
                                                            ? this.state.detailData.tanak
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Umum</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tumum !== null
                                                            ? this.state.detailData.tumum
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Struktur</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tstruktur !== null
                                                            ? this.state.detailData.tstruktur
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Fungsional</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tfungsi !== null
                                                            ? this.state.detailData.tfungsi
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Pembulatan</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.bulat !== null
                                                            ? this.state.detailData.bulat
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Beras</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tberas !== null
                                                            ? this.state.detailData.tberas
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Pajak</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tpajak !== null
                                                            ? this.state.detailData.tpajak
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Terpencil</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tpencil !== null
                                                            ? this.state.detailData.tpencil
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tunjangan Lain</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.tlain !== null
                                                            ? this.state.detailData.tlain
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                    </Tbody>
                                    <Thead>
                                        <Tr>
                                            <Th className="">Jumlah Penghasilan</Th>
                                            <Th className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {(
                                                            (parseInt(this.state.detailData.gapok) ?? 0) +
                                                            (parseInt(this.state.detailData.tistri) ?? 0) +
                                                            (parseInt(this.state.detailData.tanak) ?? 0) +
                                                            (parseInt(this.state.detailData.tumum) ?? 0) +
                                                            (parseInt(this.state.detailData.tstruktur) ?? 0) +
                                                            (parseInt(this.state.detailData.tfungsi) ?? 0) +
                                                            (parseInt(this.state.detailData.bulat) ?? 0) +
                                                            (parseInt(this.state.detailData.tberas) ?? 0) +
                                                            (parseInt(this.state.detailData.tpajak) ?? 0) +
                                                            (parseInt(this.state.detailData.tpencil) ?? 0) +
                                                            (parseInt(this.state.detailData.tlain) ?? 0)
                                                        )
                                                            .toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </Th>
                                        </Tr>
                                    </Thead>
                                </Table>
                                <Table
                                    className={
                                        "table table-bordered " +
                                        (!this.state.loadingDetail && this.state.detailData.nogaji !== null
                                            ? "table-striped table-hover"
                                            : "") +
                                        " "
                                    }
                                    style={{
                                        color:
                                            !this.state.loadingDetail && this.state.detailData.nogaji !== null
                                                ? ""
                                                : "#aaaaaa",
                                    }}
                                >
                                    <Thead>
                                        <Tr>
                                            <Th width="*" data-priority="1">
                                                Potongan
                                            </Th>
                                            <Th width="160px" data-priority="3" className="text-center">
                                                Jumlah
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <tr>
                                            <td className="ps-5">Iuran Wajib Pegawai</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.iwp !== null
                                                            ? this.state.detailData.iwp
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Sewa Rumah</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.sewarmh !== null
                                                            ? this.state.detailData.sewarmh
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Potongan Beras</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.pberas !== null
                                                            ? this.state.detailData.pberas
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Potongan Lain</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.potlain !== null
                                                            ? this.state.detailData.potlain
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">Tabungan Perumahan</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.taperum !== null
                                                            ? this.state.detailData.taperum
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">BPJS</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.bpjs !== null
                                                            ? this.state.detailData.bpjs
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">BPJS2</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.bpjs2 !== null
                                                            ? this.state.detailData.bpjs2
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-5">PPh</td>
                                            <td className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {this.state.detailData.pph !== null
                                                            ? this.state.detailData.pph
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </td>
                                        </tr>
                                    </Tbody>
                                    <Thead>
                                        <Tr>
                                            <Th className="">Jumlah Potongan</Th>
                                            <Th className=" text-end">
                                                {!this.state.loadingDetail ? (
                                                    <>
                                                        {(
                                                            (parseInt(this.state.detailData.g) ?? 0) +
                                                            (parseInt(this.state.detailData.g) ?? 0) +
                                                            (parseInt(this.state.detailData.g) ?? 0) +
                                                            (parseInt(this.state.detailData.g) ?? 0) +
                                                            (parseInt(this.state.detailData.g) ?? 0) +
                                                            (parseInt(this.state.detailData.g) ?? 0) +
                                                            (parseInt(this.state.detailData.g) ?? 0) +
                                                            (parseInt(this.state.detailData.g) ?? 0)
                                                        )
                                                            .toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                    </>
                                                ) : (
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "100%", height: 15 }}
                                                        ></div>
                                                    </Shimmer>
                                                )}
                                            </Th>
                                        </Tr>
                                    </Thead>
                                </Table>
                                <Table
                                    className={
                                        "table table-bordered " +
                                        (!this.state.loadingDetail && this.state.detailData.nogaji !== null
                                            ? "table-striped table-hover"
                                            : "") +
                                        " "
                                    }
                                    style={{
                                        color:
                                            !this.state.loadingDetail && this.state.detailData.nogaji !== null
                                                ? ""
                                                : "#aaaaaa",
                                    }}
                                >
                                    <Thead>
                                        <Tr>
                                            <Th width="*" data-priority="1">
                                                <h4>Gaji Bersih</h4>
                                            </Th>
                                            <Th width="160px" data-priority="3" className="text-end">
                                                <h4>
                                                    {!this.state.loadingDetail ? (
                                                        <>
                                                            {(
                                                                (parseInt(this.state.detailData.gapok) ?? 0) +
                                                                (parseInt(this.state.detailData.tistri) ?? 0) +
                                                                (parseInt(this.state.detailData.tanak) ?? 0) +
                                                                (parseInt(this.state.detailData.tumum) ?? 0) +
                                                                (parseInt(this.state.detailData.tstruktur) ?? 0) +
                                                                (parseInt(this.state.detailData.tfungsi) ?? 0) +
                                                                (parseInt(this.state.detailData.bulat) ?? 0) +
                                                                (parseInt(this.state.detailData.tberas) ?? 0) +
                                                                (parseInt(this.state.detailData.tpajak) ?? 0) +
                                                                (parseInt(this.state.detailData.tpencil) ?? 0) +
                                                                (parseInt(this.state.detailData.tlain) ?? 0) -
                                                                ((parseInt(this.state.detailData.iwp) ?? 0) +
                                                                    (parseInt(this.state.detailData.sewarmh) ?? 0) +
                                                                    (parseInt(this.state.detailData.pberas) ?? 0) +
                                                                    (parseInt(this.state.detailData.potlain) ?? 0) +
                                                                    (parseInt(this.state.detailData.taperum) ?? 0) +
                                                                    (parseInt(this.state.detailData.bpjs) ?? 0) +
                                                                    (parseInt(this.state.detailData.bpjs2) ?? 0) +
                                                                    (parseInt(this.state.detailData.pph) ?? 0))
                                                            )
                                                                .toString()
                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                        </>
                                                    ) : (
                                                        <Shimmer>
                                                            <div
                                                                className="shimmer"
                                                                style={{ width: "100%", height: 15 }}
                                                            ></div>
                                                        </Shimmer>
                                                    )}
                                                </h4>
                                            </Th>
                                        </Tr>
                                    </Thead>
                                </Table>
                            </Col>
                        </Row>
                        {/* <Row className="mb-3">
                            <label className="col-md-4">
                                Gaji Pokok
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.gapok !== null ? this.state.detailData.gapok : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Istri
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tistri !== null ? this.state.detailData.tistri : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Anak
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tanak !== null ? this.state.detailData.tanak : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Umum
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tumum !== null ? this.state.detailData.tumum : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Struktur
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tstruktur !== null ? this.state.detailData.tstruktur : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Fungsional
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tfungsi !== null ? this.state.detailData.tfungsi : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Pembulatan
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.bulat !== null ? this.state.detailData.bulat : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Beras
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tberas !== null ? this.state.detailData.tberas : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Pajak
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tpajak !== null ? this.state.detailData.tpajak : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Potongan Beras
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.pberas !== null ? this.state.detailData.pberas : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Terpencil
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tpencil !== null ? this.state.detailData.tpencil : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tunjangan Lain
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.tlain !== null ? this.state.detailData.tlain : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                PPh
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.pph !== null ? this.state.detailData.pph : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Sewa Rumah
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.sewarmh !== null ? this.state.detailData.sewarmh : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Potongan Lain
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.potlain !== null ? this.state.detailData.potlain : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Tabungan Perumahan
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.taperum !== null ? this.state.detailData.taperum : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                Kode Golongan
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.kdgol !== null ? this.state.detailData.kdgol : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                BPJS
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.bpjs !== null ? this.state.detailData.bpjs : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4">
                                BPJS2
                            </label>
                            <Col md="8">
                                {
                                    !this.state.loadingDetail ?
                                        <>{this.state.detailData.bpjs2 !== null ? this.state.detailData.bpjs2 : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                }
                            </Col>
                        </Row> */}
                    </div>
                </Col>
            </>
        )
    }
}
export default SalaryDetailComponent
