import { apiWrapper, apiCancel } from "../wrapper"

export const initial = "process"
const baseUrl = process.env.REACT_APP_SERVICE_PROCESS_URL
const appCode = process.env.REACT_APP_SERVICE_PROCESS_APPCODE
const outputType = "json"

// Tunkin Reguler
export const tunkinRegulerEmployeeYears = "tunkin/reguler/employeeYears/"
export const tunkinRegulerEmployeeMonths = "tunkin/reguler/employeeMonths/"
export const tunkinRegulerYears = "tunkin/reguler/years/"
export const tunkinRegulerNrss = "tunkin/reguler/nrss/"
export const tunkinRegulerMonths = "tunkin/reguler/months/"
export const tunkinRegulerHistory = "tunkin/reguler/history/"
export const tunkinRegulerProcessEmployees = "tunkin/reguler/employee/"
export const tunkinRegulerProcessEmployeeDetail = "tunkin/reguler/employeeDetail/"
export const tunkinRegulerProcessSubmit = "tunkin/reguler/process/"
export const tunkinRegulerApproveSubmit = "tunkin/reguler/approve/"
export const tunkinRegulerRejectSubmit = "tunkin/reguler/reject/"
export const tunkinRegulerGetSigner = "tunkin/reguler/getSigner/"
export const tunkinRegulerCheckStatus = "tunkin/reguler/checkStatus/"
export const tunkinRegulerCheckPresence = "tunkin/reguler/checkEmployeePresence/"
export const tunkinRegulerCheckLevel = "tunkin/reguler/checkLevelReference/"
export const tunkinRegulerCheckOtherMonth = "tunkin/reguler/checkOtherMonth/"
export const tunkinRegulerCheckGpp = "tunkin/reguler/checkEmployeeGpp/"
export const tunkinRegulerCheckPtkp = "tunkin/reguler/checkPtkp/"
export const tunkinRegulerCheckPkp = "tunkin/reguler/checkPkp/"
export const tunkinRegulerCheckEmployeeNew = "tunkin/reguler/checkEmployeeNew/"
export const tunkinRegulerCheckEmployeeChange = "tunkin/reguler/checkEmployeeChange/"
export const tunkinRegulerResultEmployee = "tunkin/reguler/result/"
export const tunkinRegulerResultEmployeeDetail = "tunkin/reguler/resultDetail/"
export const tunkinRegulerPushSPM = "tunkin/reguler/spminsert/"
export const tunkinRegulerUpdateSPM = "tunkin/reguler/spmupdate/"
export const tunkinRegulerReset = "tunkin/reguler/reset/"
export const tunkinRegulerVerifyList = "tunkin/reguler/verify/"
export const tunkinRegulerCompareList = "tunkin/reguler/compare/"
export const tunkinRegulerResetRequest = "tunkin/reguler/requestReset/"
export const tunkinRegulerResetApprove = "tunkin/reguler/approveReset/"
export const tunkinRegulerResetReject = "tunkin/reguler/rejectReset/"

// Tunkine ke-13
export const tunkinT13EmployeeMonths = "tunkin/t13/employeeMonths/"
export const tunkinT13RegulerMonth = "tunkin/t13/regulerMonth/"
export const tunkinT13Create = "tunkin/t13/create/"
export const tunkinT13Months = "tunkin/t13/months/"
export const tunkinT13History = "tunkin/t13/history/"
export const tunkinT13ProcessEmployees = "tunkin/t13/employee/"
export const tunkinT13ProcessEmployeeDetail = "tunkin/t13/employeeDetail/"
export const tunkinT13ProcessSubmit = "tunkin/t13/process/"
export const tunkinT13ApproveSubmit = "tunkin/t13/approve/"
export const tunkinT13RejectSubmit = "tunkin/t13/reject/"
export const tunkinT13GetSigner = "tunkin/t13/getSigner/"
export const tunkinT13CheckStatus = "tunkin/t13/checkStatus/"
export const tunkinT13CheckPresence = "tunkin/t13/checkEmployeePresence/"
export const tunkinT13CheckLevel = "tunkin/t13/checkLevelReference/"
export const tunkinT13CheckGpp = "tunkin/t13/checkEmployeeGpp/"
export const tunkinT13CheckPtkp = "tunkin/t13/checkPtkp/"
export const tunkinT13CheckPkp = "tunkin/t13/checkPkp/"
export const tunkinT13CheckEmployeeNew = "tunkin/t13/checkEmployeeNew/"
export const tunkinT13CheckEmployeeChange = "tunkin/t13/checkEmployeeChange/"
export const tunkinT13ResultEmployee = "tunkin/t13/result/"
export const tunkinT13ResultEmployeeDetail = "tunkin/t13/resultDetail/"
export const tunkinT13PushSPM = "tunkin/t13/spminsert/"
export const tunkinT13UpdateSPM = "tunkin/t13/spmupdate/"

// Tunkin ke-14
export const tunkinT14EmployeeMonths = "tunkin/t14/employeeMonths/"
export const tunkinT14RegulerMonth = "tunkin/t14/regulerMonth/"
export const tunkinT14Create = "tunkin/t14/create/"
export const tunkinT14Months = "tunkin/t14/months/"
export const tunkinT14History = "tunkin/t14/history/"
export const tunkinT14ProcessEmployees = "tunkin/t14/employee/"
export const tunkinT14ProcessEmployeeDetail = "tunkin/t14/employeeDetail/"
export const tunkinT14ProcessSubmit = "tunkin/t14/process/"
export const tunkinT14ApproveSubmit = "tunkin/t14/approve/"
export const tunkinT14RejectSubmit = "tunkin/t14/reject/"
export const tunkinT14GetSigner = "tunkin/t14/getSigner/"
export const tunkinT14CheckStatus = "tunkin/t14/checkStatus/"
export const tunkinT14CheckPresence = "tunkin/t14/checkEmployeePresence/"
export const tunkinT14CheckLevel = "tunkin/t14/checkLevelReference/"
export const tunkinT14CheckGpp = "tunkin/t14/checkEmployeeGpp/"
export const tunkinT14CheckPtkp = "tunkin/t14/checkPtkp/"
export const tunkinT14CheckPkp = "tunkin/t14/checkPkp/"
export const tunkinT14CheckEmployeeNew = "tunkin/t14/checkEmployeeNew/"
export const tunkinT14CheckEmployeeChange = "tunkin/t14/checkEmployeeChange/"
export const tunkinT14ResultEmployee = "tunkin/t14/result/"
export const tunkinT14ResultEmployeeDetail = "tunkin/t14/resultDetail/"
export const tunkinT14PushSPM = "tunkin/t14/spminsert/"
export const tunkinT14UpdateSPM = "tunkin/t14/spmupdate/"

// Tunkin Susulan
export const tunkinSusulanEmployeeMonths = "tunkin/susulan/employeeMonths/"
export const tunkinSusulanMonths = "tunkin/susulan/months/"
export const tunkinSusulanHistory = "tunkin/susulan/history/"
export const tunkinSusulanProcessEmployees = "tunkin/susulan/employee/"
export const tunkinSusulanProcessEmployeeDetail = "tunkin/susulan/employeeDetail/"
export const tunkinSusulanProcessSubmit = "tunkin/susulan/process/"
export const tunkinSusulanApproveSubmit = "tunkin/susulan/approve/"
export const tunkinSusulanRejectSubmit = "tunkin/susulan/reject/"
export const tunkinSusulanGetSigner = "tunkin/susulan/getSigner/"
export const tunkinSusulanCheckStatus = "tunkin/susulan/checkStatus/"
export const tunkinSusulanCheckPresence = "tunkin/susulan/checkEmployeePresence/"
export const tunkinSusulanCheckLevel = "tunkin/susulan/checkLevelReference/"
export const tunkinSusulanCheckGpp = "tunkin/susulan/checkEmployeeGpp/"
export const tunkinSusulanCheckPtkp = "tunkin/susulan/checkPtkp/"
export const tunkinSusulanCheckPkp = "tunkin/susulan/checkPkp/"
export const tunkinSusulanCheckEmployeeNew = "tunkin/susulan/checkEmployeeNew/"
export const tunkinSusulanCheckEmployeeChange = "tunkin/susulan/checkEmployeeChange/"
export const tunkinSusulanResultEmployee = "tunkin/susulan/result/"
export const tunkinSusulanResultEmployeeDetail = "tunkin/susulan/resultDetail/"
export const tunkinSusulanPushSPM = "tunkin/susulan/spminsert/"
export const tunkinSusulanUpdateSPM = "tunkin/susulan/spmupdate/"

// Tunkin Rapel
export const tunkinRapelEmployeeMonths = "tunkin/rapel/employeeMonths/"
export const tunkinRapelMonths = "tunkin/rapel/months/"
export const tunkinRapelHistory = "tunkin/rapel/history/"
export const tunkinRapelProcessEmployees = "tunkin/rapel/employee/"
export const tunkinRapelProcessEmployeeDetail = "tunkin/rapel/employeeDetail/"
export const tunkinRapelProcessSubmit = "tunkin/rapel/process/"
export const tunkinRapelApproveSubmit = "tunkin/rapel/approve/"
export const tunkinRapelRejectSubmit = "tunkin/rapel/reject/"
export const tunkinRapelGetSigner = "tunkin/rapel/getSigner/"
export const tunkinRapelCheckStatus = "tunkin/rapel/checkStatus/"
export const tunkinRapelCheckPresence = "tunkin/rapel/checkEmployeePresence/"
export const tunkinRapelCheckLevel = "tunkin/rapel/checkLevelReference/"
export const tunkinRapelCheckGpp = "tunkin/rapel/checkEmployeeGpp/"
export const tunkinRapelCheckPtkp = "tunkin/rapel/checkPtkp/"
export const tunkinRapelCheckPkp = "tunkin/rapel/checkPkp/"
export const tunkinRapelCheckEmployeeNew = "tunkin/rapel/checkEmployeeNew/"
export const tunkinRapelCheckEmployeeChange = "tunkin/rapel/checkEmployeeChange/"
export const tunkinRapelResultEmployee = "tunkin/rapel/result/"
export const tunkinRapelResultEmployeeDetail = "tunkin/rapel/resultDetail/"
export const tunkinRapelPushSPM = "tunkin/rapel/spminsert/"
export const tunkinRapelUpdateSPM = "tunkin/rapel/spmupdate/"

// Tunsus Reguler
export const tunsusRegulerEmployeeYears = "tunsus/reguler/employeeYears/"
export const tunsusRegulerEmployeeMonths = "tunsus/reguler/employeeMonths/"
export const tunsusRegulerYears = "tunsus/reguler/years/"
export const tunsusRegulerNrss = "tunsus/reguler/nrss/"
export const tunsusRegulerMonths = "tunsus/reguler/months/"
export const tunsusRegulerHistory = "tunsus/reguler/history/"
export const tunsusRegulerProcessEmployees = "tunsus/reguler/employee/"
export const tunsusRegulerProcessEmployeeDetail = "tunsus/reguler/employeeDetail/"
export const tunsusRegulerProcessSubmit = "tunsus/reguler/process/"
export const tunsusRegulerApproveSubmit = "tunsus/reguler/approve/"
export const tunsusRegulerRejectSubmit = "tunsus/reguler/reject/"
export const tunsusRegulerGetSigner = "tunsus/reguler/getSigner/"
export const tunsusRegulerCheckStatus = "tunsus/reguler/checkStatus/"
export const tunsusRegulerCheckLevel = "tunsus/reguler/checkSpecialLevel/"
export const tunsusRegulerCheckPtkp = "tunsus/reguler/checkPtkp/"
export const tunsusRegulerCheckOtherMonth = "tunsus/reguler/checkOtherMonth/"
export const tunsusRegulerResultEmployee = "tunsus/reguler/result/"
export const tunsusRegulerResultEmployeeDetail = "tunsus/reguler/resultDetail/"
export const tunsusRegulerPushSPM = "tunsus/reguler/spminsert/"
export const tunsusRegulerUpdateSPM = "tunsus/reguler/spmupdate/"
export const tunsusRegulerReset = "tunsus/reguler/reset/"
export const tunsusRegulerVerifyList = "tunsus/reguler/verify/"
export const tunsusRegulerCompareList = "tunsus/reguler/compare/"
export const tunsusRegulerResetRequest = "tunsus/reguler/requestReset/"
export const tunsusRegulerResetApprove = "tunsus/reguler/approveReset/"
export const tunsusRegulerResetReject = "tunsus/reguler/rejectReset/"

// Tunsus Rapel
export const tunsusRapelEmployeeYears = "tunsus/rapel/employeeYears/"
export const tunsusRapelEmployeeMonths = "tunsus/rapel/employeeMonths/"
export const tunsusRapelNrss = "tunsus/rapel/nrss/"
export const tunsusRapelMonths = "tunsus/rapel/months/"
export const tunsusRapelHistory = "tunsus/rapel/history/"
export const tunsusRapelProcessEmployees = "tunsus/rapel/employee/"
export const tunsusRapelProcessEmployeeDetail = "tunsus/rapel/employeeDetail/"
export const tunsusRapelProcessSubmit = "tunsus/rapel/process/"
export const tunsusRapelApproveSubmit = "tunsus/rapel/approve/"
export const tunsusRapelRejectSubmit = "tunsus/rapel/reject/"
export const tunsusRapelGetSigner = "tunsus/rapel/getSigner/"
export const tunsusRapelCheckStatus = "tunsus/rapel/checkStatus/"
export const tunsusRapelCheckLevel = "tunsus/rapel/checkSpecialLevel/"
export const tunsusRapelCheckPtkp = "tunsus/rapel/checkPtkp/"
export const tunsusRapelResultEmployee = "tunsus/rapel/result/"
export const tunsusRapelResultEmployeeDetail = "tunsus/rapel/resultDetail/"
export const tunsusRapelPushSPM = "tunsus/rapel/spminsert/"
export const tunsusRapelUpdateSPM = "tunsus/rapel/spmupdate/"

// TPLN Reguler
export const tplnRegulerEmployeeYears = "tpln/reguler/employeeYears/"
export const tplnRegulerEmployeeMonths = "tpln/reguler/employeeMonths/"
export const tplnRegulerYears = "tpln/reguler/years/"
export const tplnRegulerMonths = "tpln/reguler/months/"
export const tplnRegulerHistory = "tpln/reguler/history/"
export const tplnRegulerProcessEmployees = "tpln/reguler/employee/"
export const tplnRegulerProcessEmployeeDetail = "tpln/reguler/employeeDetail/"
export const tplnRegulerProcessSubmit = "tpln/reguler/process/"
export const tplnRegulerApproveSubmit = "tpln/reguler/approve/"
export const tplnRegulerRejectSubmit = "tpln/reguler/reject/"
export const tplnRegulerGetSigner = "tpln/reguler/getSigner/"
export const tplnRegulerCheckStatus = "tpln/reguler/checkStatus/"
export const tplnRegulerCheckKurs = "tpln/reguler/checkKurs/"
export const tplnRegulerCheckGpp = "tpln/reguler/checkEmployeeGpp/"
export const tplnRegulerCheckPtkp = "tpln/reguler/checkPtkp/"
export const tplnRegulerCheckPkp = "tpln/reguler/checkPkp/"
export const tplnRegulerCheckCountry = "tpln/reguler/checkTplnCountry/"
export const tplnRegulerCheckRank = "tpln/reguler/checkTplnRank/"
export const tplnRegulerCheckOtherMonth = "tpln/reguler/checkOtherMonth/"
export const tplnRegulerCheckEmployeeNew = "tpln/reguler/checkEmployeeNew/"
export const tplnRegulerCheckEmployeeChange = "tpln/reguler/checkEmployeeChange/"
export const tplnRegulerResultEmployee = "tpln/reguler/result/"
export const tplnRegulerResultEmployeeDetail = "tpln/reguler/resultDetail/"
export const tplnRegulerUpdate = "tpln/reguler/update/"
export const tplnRegulerPushSPM = "tpln/reguler/spminsert/"
export const tplnRegulerUpdateSPM = "tpln/reguler/spmupdate/"

// TPLN Ke-13
export const tplnT13RegulerMonth = "tpln/t13/regulerMonth/"
export const tplnT13Create = "tpln/t13/create/"
export const tplnT13Months = "tpln/t13/months/"
export const tplnT13History = "tpln/t13/history/"
export const tplnT13ProcessEmployees = "tpln/t13/employee/"
export const tplnT13ProcessEmployeeDetail = "tpln/t13/employeeDetail/"
export const tplnT13ProcessSubmit = "tpln/t13/process/"
export const tplnT13ApproveSubmit = "tpln/t13/approve/"
export const tplnT13RejectSubmit = "tpln/t13/reject/"
export const tplnT13GetSigner = "tpln/t13/getSigner/"
export const tplnT13CheckStatus = "tpln/t13/checkStatus/"
export const tplnT13CheckKurs = "tpln/t13/checkKurs/"
export const tplnT13CheckGpp = "tpln/t13/checkEmployeeGpp/"
export const tplnT13CheckPtkp = "tpln/t13/checkPtkp/"
export const tplnT13CheckPkp = "tpln/t13/checkPkp/"
export const tplnT13CheckCountry = "tpln/t13/checkTplnCountry/"
export const tplnT13CheckRank = "tpln/t13/checkTplnRank/"
export const tplnT13CheckEmployeeNew = "tpln/t13/checkEmployeeNew/"
export const tplnT13CheckEmployeeChange = "tpln/t13/checkEmployeeChange/"
export const tplnT13ResultEmployee = "tpln/t13/result/"
export const tplnT13ResultEmployeeDetail = "tpln/t13/resultDetail/"
export const tplnT13PushSPM = "tpln/t13/spminsert/"
export const tplnT13UpdateSPM = "tpln/t13/spmupdate/"

// TPLN Ke-14
export const tplnT14RegulerMonth = "tpln/t14/regulerMonth/"
export const tplnT14Create = "tpln/t14/create/"
export const tplnT14Months = "tpln/t14/months/"
export const tplnT14History = "tpln/t14/history/"
export const tplnT14ProcessEmployees = "tpln/t14/employee/"
export const tplnT14ProcessEmployeeDetail = "tpln/t14/employeeDetail/"
export const tplnT14ProcessSubmit = "tpln/t14/process/"
export const tplnT14ApproveSubmit = "tpln/t14/approve/"
export const tplnT14RejectSubmit = "tpln/t14/reject/"
export const tplnT14GetSigner = "tpln/t14/getSigner/"
export const tplnT14CheckStatus = "tpln/t14/checkStatus/"
export const tplnT14CheckKurs = "tpln/t14/checkKurs/"
export const tplnT14CheckGpp = "tpln/t14/checkEmployeeGpp/"
export const tplnT14CheckPtkp = "tpln/t14/checkPtkp/"
export const tplnT14CheckPkp = "tpln/t14/checkPkp/"
export const tplnT14CheckCountry = "tpln/t14/checkTplnCountry/"
export const tplnT14CheckRank = "tpln/t14/checkTplnRank/"
export const tplnT14CheckEmployeeNew = "tpln/t14/checkEmployeeNew/"
export const tplnT14CheckEmployeeChange = "tpln/t14/checkEmployeeChange/"
export const tplnT14ResultEmployee = "tpln/t14/result/"
export const tplnT14ResultEmployeeDetail = "tpln/t14/resultDetail/"
export const tplnT14PushSPM = "tpln/t14/spminsert/"
export const tplnT14UpdateSPM = "tpln/t14/spmupdate/"

// TPLN Susulan
export const tplnSusulanYears = "tpln/susulan/years/"
export const tplnSusulanMonths = "tpln/susulan/months/"
export const tplnSusulanHistory = "tpln/susulan/history/"
export const tplnSusulanProcessEmployees = "tpln/susulan/employee/"
export const tplnSusulanProcessEmployeeDetail = "tpln/susulan/employeeDetail/"
export const tplnSusulanProcessSubmit = "tpln/susulan/process/"
export const tplnSusulanApproveSubmit = "tpln/susulan/approve/"
export const tplnSusulanRejectSubmit = "tpln/susulan/reject/"
export const tplnSusulanGetSigner = "tpln/susulan/getSigner/"
export const tplnSusulanCheckStatus = "tpln/susulan/checkStatus/"
export const tplnSusulanCheckKurs = "tpln/susulan/checkKurs/"
export const tplnSusulanCheckGpp = "tpln/susulan/checkEmployeeGpp/"
export const tplnSusulanCheckPtkp = "tpln/susulan/checkPtkp/"
export const tplnSusulanCheckPkp = "tpln/susulan/checkPkp/"
export const tplnSusulanCheckCountry = "tpln/susulan/checkTplnCountry/"
export const tplnSusulanCheckRank = "tpln/susulan/checkTplnRank/"
export const tplnSusulanCheckEmployeeNew = "tpln/susulan/checkEmployeeNew/"
export const tplnSusulanCheckEmployeeChange = "tpln/susulan/checkEmployeeChange/"
export const tplnSusulanResultEmployee = "tpln/susulan/result/"
export const tplnSusulanResultEmployeeDetail = "tpln/susulan/resultDetail/"

// TPLN Rapel
export const tplnRapelYears = "tpln/rapel/years/"
export const tplnRapelMonths = "tpln/rapel/months/"
export const tplnRapelHistory = "tpln/rapel/history/"
export const tplnRapelProcessEmployees = "tpln/rapel/employee/"
export const tplnRapelProcessEmployeeDetail = "tpln/rapel/employeeDetail/"
export const tplnRapelProcessSubmit = "tpln/rapel/process/"
export const tplnRapelApproveSubmit = "tpln/rapel/approve/"
export const tplnRapelRejectSubmit = "tpln/rapel/reject/"
export const tplnRapelGetSigner = "tpln/rapel/getSigner/"
export const tplnRapelCheckStatus = "tpln/rapel/checkStatus/"
export const tplnRapelCheckKurs = "tpln/rapel/checkKurs/"
export const tplnRapelCheckGpp = "tpln/rapel/checkEmployeeGpp/"
export const tplnRapelCheckPtkp = "tpln/rapel/checkPtkp/"
export const tplnRapelCheckPkp = "tpln/rapel/checkPkp/"
export const tplnRapelCheckCountry = "tpln/rapel/checkTplnCountry/"
export const tplnRapelCheckRank = "tpln/rapel/checkTplnRank/"
export const tplnRapelCheckEmployeeNew = "tpln/rapel/checkEmployeeNew/"
export const tplnRapelCheckEmployeeChange = "tpln/rapel/checkEmployeeChange/"
export const tplnRapelResultEmployee = "tpln/rapel/result/"
export const tplnRapelResultEmployeeDetail = "tpln/rapel/resultDetail/"

// Uang Makan Reguler
export const uangMakanRegularEmployeeYears = "uangmakan/regular/employeeYears/"
export const uangMakanRegularEmployeeMonths = "uangmakan/regular/employeeMonths/"
export const uangMakanRegularYears = "uangmakan/regular/years/"
export const uangMakanRegularMonths = "uangmakan/regular/months/"
export const uangMakanRegularHistory = "uangmakan/regular/history/"
export const uangMakanRegularProcessEmployees = "uangmakan/regular/employee/"
export const uangMakanRegularProcessEmployeeDetail = "uangmakan/regular/employeeDetail/"
export const uangMakanRegularProcessSubmit = "uangmakan/regular/process/"
export const uangMakanRegularApproveSubmit = "uangmakan/regular/approve/"
export const uangMakanRegularRejectSubmit = "uangmakan/regular/reject/"
export const uangMakanRegularGetSigner = "uangmakan/regular/getSigner/"
export const uangMakanRegularCheckStatus = "uangmakan/regular/checkStatus/"
export const uangMakanRegularCheckPresence = "uangmakan/regular/checkEmployeePresence/"
export const uangMakanRegularCheckRank = "uangmakan/regular/checkRankReference/"
export const uangMakanRegularCheckTaxFinal = "uangmakan/regular/checkTaxFinal/"
export const uangMakanRegularCheckEmployeeNew = "uangmakan/regular/checkEmployeeNew/"
export const uangMakanRegularCheckEmployeeChange = "uangmakan/regular/checkEmployeeChange/"
export const uangMakanRegularResultEmployee = "uangmakan/regular/result/"
export const uangMakanRegularResultEmployeeDetail = "uangmakan/regular/resultDetail/"
export const uangMakanRegularVerifyList = "uangmakan/regular/verify/"
export const uangMakanRegulerCompareList = "uangmakan/regular/compare/"

export const uangmakanRegulerPushSPM = "uangmakan/regular/spminsert/"
export const uangmakanRegulerUpdateSPM = "uangmakan/regular/spmupdate/"
export const uangmakanRegulerResetApprove = "uangmakan/regular/approveReset/"
export const uangmakanRegulerResetReject = "uangmakan/regular/rejectReset/"

// Uang Makan Rapel
export const uangMakanRapelEmployeeYears = "uangmakan/rapel/employeeYears/"
export const uangMakanRapelEmployeeMonths = "uangmakan/rapel/employeeMonths/"
export const uangMakanRapelYears = "uangmakan/rapel/years/"
export const uangMakanRapelMonths = "uangmakan/rapel/months/"
export const uangMakanRapelHistory = "uangmakan/rapel/history/"
export const uangMakanRapelProcessEmployees = "uangmakan/rapel/employee/"
export const uangMakanRapelProcessEmployeeDetail = "uangmakan/rapel/employeeDetail/"
export const uangMakanRapelProcessSubmit = "uangmakan/rapel/process/"
export const uangMakanRapelApproveSubmit = "uangmakan/rapel/approve/"
export const uangMakanRapelRejectSubmit = "uangmakan/rapel/reject/"
export const uangMakanRapelGetSigner = "uangmakan/rapel/getSigner/"
export const uangMakanRapelCheckStatus = "uangmakan/rapel/checkStatus/"
export const uangMakanRapelCheckPresence = "uangmakan/rapel/checkEmployeePresence/"
export const uangMakanRapelCheckRank = "uangmakan/rapel/checkRankReference/"
export const uangMakanRapelCheckTaxFinal = "uangmakan/rapel/checkTaxFinal/"
export const uangMakanRapelCheckEmployeeNew = "uangmakan/rapel/checkEmployeeNew/"
export const uangMakanRapelCheckEmployeeChange = "uangmakan/rapel/checkEmployeeChange/"
export const uangMakanRapelResultEmployee = "uangmakan/rapel/result/"
export const uangMakanRapelResultEmployeeDetail = "uangmakan/rapel/resultDetail/"

// Lembur
export const overtimeEmployeeYears = "overtime/employeeYears/"
export const overtimeEmployeeMonths = "overtime/employeeMonths/"
export const overtimeYears = "overtime/years/"
export const overtimeMonths = "overtime/months/"
export const overtimeHistory = "overtime/history/"
export const overtimePushSPM = "overtime/spminsert/"
export const overtimeUpdateSPM = "overtime/spmupdate/"
export const overtimeProcessEmployees = "overtime/employee/"
export const overtimeProcessEmployeeDetail = "overtime/employeeDetail/"
export const overtimeProcessSubmit = "overtime/process/"
export const overtimeApproveSubmit = "overtime/approve/"
export const overtimeRejectSubmit = "overtime/reject/"
export const overtimeGetSigner = "overtime/getSigner/"
export const overtimeCheckStatus = "overtime/checkStatus/"
export const overtimeCheckPresence = "overtime/checkEmployeePresence/"
export const overtimeCheckRank = "overtime/checkRankReference/"
export const overtimeCheckTaxFinal = "overtime/checkTaxFinal/"
export const overtimeCheckEmployeeNew = "overtime/checkEmployeeNew/"
export const overtimeCheckEmployeeChange = "overtime/checkEmployeeChange/"
export const overtimeResultEmployee = "overtime/result/"
export const overtimeResultEmployeeDetail = "overtime/resultDetail/"

//Tunjab
export const tunjabList = "tunjab/lists/"
export const tunjabEmployeeList = "tunjab/listEmployee/"
export const tunjabCreate = "tunjab/create/"
export const tunjabYearAvailable = "tunjab/yearAvailable/"
export const tunjabAllEmployeeList = "tunjab/allEmployees/"

//PTSP Manual
export const krkoList = "krko/lists/"
export const krkoEmployeeList = "krko/listEmployee/"
export const krkoCreate = "krko/create/"
export const krkoYearAvailable = "krko/yearAvailable/"
export const krkoAllEmployeeList = "krko/allEmployees/"

// Honorarium Reguler
export const ptspRegulerYears = "ptsp/reguler/years/"
export const ptspRegulerBkpmMonths = "ptsp/reguler/months/"
export const ptspRegulerPpklMonths = "ptsp/reguler/months/"
export const ptspRegulerHistory = "ptsp/reguler/history/"
export const ptspRegulerProcessEmployees = "ptsp/reguler/employee/"
export const ptspRegulerProcessEmployeeDetail = "ptsp/reguler/employeeDetail/"
export const ptspRegulerProcessSubmit = "ptsp/reguler/process/"
export const ptspRegulerApproveSubmit = "ptsp/reguler/approve/"
export const ptspRegulerRejectSubmit = "ptsp/reguler/reject/"
export const ptspRegulerGetSigner = "ptsp/reguler/getSigner/"
export const ptspRegulerCheckStatus = "ptsp/reguler/checkStatus/"
export const ptspRegulerCheckPresence = "ptsp/reguler/checkEmployeePresence/"
export const ptspRegulerCheckTunkin = "ptsp/reguler/checkTunkin/"
export const ptspRegulerCheckTaxFinal = "ptsp/reguler/checkTaxFinal/"
export const ptspRegulerCheckEmployeeNew = "ptsp/reguler/checkEmployeeNew/"
export const ptspRegulerCheckEmployeeChange = "ptsp/reguler/checkEmployeeChange/"
export const ptspRegulerResultEmployee = "ptsp/reguler/result/"
export const ptspRegulerResultEmployeeDetail = "ptsp/reguler/resultDetail/"
export const ptspRegulerReset = "ptsp/reguler/reset/"
export const ptspRegulerVerifyList = "ptsp/reguler/verify/"
export const ptspRegulerCompareList = "ptsp/reguler/compare/"
export const ptspRegulerResetRequest = "ptsp/reguler/requestReset/"
export const ptspRegulerResetApprove = "ptsp/reguler/approveReset/"
export const ptspRegulerResetReject = "ptsp/reguler/rejectReset/"

export const ptspRegulerProcessVerify = "ptsp/reguler/verify/"
export const ptspRegulerMonths = "ptsp/reguler/months/"

// Honorarium Rapel
export const ptspRapelYears = "ptsp/rapel/years/"
export const ptspRapelBkpmMonths = "ptsp/rapel/months/"
export const ptspRapelPpklMonths = "ptsp/rapel/months/"
export const ptspRapelHistory = "ptsp/rapel/history/"
export const ptspRapelProcessEmployees = "ptsp/rapel/employee/"
export const ptspRapelProcessEmployeeDetail = "ptsp/rapel/employeeDetail/"
export const ptspRapelProcessSubmit = "ptsp/rapel/process/"
export const ptspRapelApproveSubmit = "ptsp/rapel/approve/"
export const ptspRapelRejectSubmit = "ptsp/rapel/reject/"
export const ptspRapelGetSigner = "ptsp/rapel/getSigner/"
export const ptspRapelCheckStatus = "ptsp/rapel/checkStatus/"
export const ptspRapelCheckPresence = "ptsp/rapel/checkEmployeePresence/"
export const ptspRapelCheckTunkin = "ptsp/rapel/checkTunkin/"
export const ptspRapelCheckTaxFinal = "ptsp/rapel/checkTaxFinal/"
export const ptspRapelCheckEmployeeNew = "ptsp/rapel/checkEmployeeNew/"
export const ptspRapelCheckEmployeeChange = "ptsp/rapel/checkEmployeeChange/"
export const ptspRapelResultEmployee = "ptsp/rapel/result/"
export const ptspRapelResultEmployeeDetail = "ptsp/rapel/resultDetail/"
export const ptspRapelReset = "ptsp/rapel/reset/"
export const ptspRapelVerifyList = "ptsp/rapel/verify/"
export const ptspRapelCompareList = "ptsp/rapel/compare/"
export const ptspRapelResetRequest = "ptsp/rapel/requestReset/"
export const ptspRapelResetApprove = "ptsp/rapel/approveReset/"
export const ptspRapelResetReject = "ptsp/rapel/rejectReset/"

export const ptspRapelProcessVerify = "ptsp/rapel/verify/"
export const ptspRapelMonths = "ptsp/rapel/months/"

// Perjalanan Dinas
export const officialTravelPpkList = "officialtravel/ppk/lists/"
export const officialTravelPpkDetail = "officialtravel/ppk/detail/"
export const officialTravelPpkEmployee = "officialtravel/ppk/employee/"
export const officialTravelPpkEmployeeOperator = "officialtravel/ppk/employee_operator/"
export const officialTravelPpkEmployeeApprover = "officialtravel/ppk/employee_approver/"
export const officialTravelPpkCreate = "officialtravel/ppk/create/"
export const officialTravelPpkUpdate = "officialtravel/ppk/update/"
export const officialTravelPpkInactive = "officialtravel/ppk/inactive/"
export const officialTravelPpkActive = "officialtravel/ppk/active/"

export const officialTravelRegisterList = "officialtravel/register/lists/"
export const officialTravelRegisterDetail = "officialtravel/register/detail/"
export const officialTravelRegisterParticipant = "officialtravel/register/participant/"
export const officialTravelRegisterSearch = "officialtravel/register/search/"
export const officialTravelRegisterSearchParticipant = "officialtravel/register/searchparticipant/"
export const officialTravelRegisterCreate = "officialtravel/register/create/"
export const officialTravelRegisterLevelOption = "officialtravel/register/levels/"
export const officialTravelRegisterUpdate = "officialtravel/register/update/"
export const officialTravelRegisterUpdateAll = "officialtravel/register/updateall/"

export const officialTravelSubmissionList = "officialtravel/submission/lists/"
export const officialTravelSubmissionDetail = "officialtravel/submission/detail/"
export const officialTravelSubmissionParticipant = "officialtravel/submission/participant/"
export const officialTravelSubmissionUnprocessList = "officialtravel/submission/unprocesslist/"
export const officialTravelSubmissionUnprocessParticipantList = "officialtravel/submission/unprocessparticipantlist/"
export const officialTravelSubmissionCreateInitial = "officialtravel/submission/createinitial/"
export const officialTravelSubmissionProcessDetail = "officialtravel/submission/processdetail/"
export const officialTravelSubmissionProcessParticipant = "officialtravel/submission/processparticipant/"
export const officialTravelSubmissionProcessDeleteParticipant = "officialtravel/submission/processdeleteparticipant/"
export const officialTravelSubmissionProcessUpdate = "officialtravel/submission/update/"
export const officialTravelSubmissionSource = "officialtravel/submission/source/"
export const officialTravelSubmissionConflictDetail = "officialtravel/submission/conflictdetail/"
export const officialTravelSubmissionConflictSolver_0 = "officialtravel/submission/conflictsolver0/"
export const officialTravelSubmissionConflictSolver_1 = "officialtravel/submission/conflictsolver1/"
export const officialTravelSubmissionConflictSolver_2 = "officialtravel/submission/conflictsolver2/"
export const officialTravelSubmissionLevelList = "officialtravel/submission/levellists/"
export const officialTravelSubmissionLevelUpdate = "officialtravel/submission/levelupdate/"
export const officialTravelSubmissionUpdateMultiDestination = "officialtravel/submission/updatemultidestination/"
export const officialTravelSubmissionUpdateMultiDestinationData = "officialtravel/submission/updatemultidestinationdata/"

export const officialTravelRapelSubmissionList = "officialtravel/rapelsubmission/lists/"
export const officialTravelRapelSubmissionUnprocessList = "officialtravel/rapelsubmission/unprocesslist/"
export const officialTravelRapleUnprocessParticipantList = "officialtravel/rapelsubmission/unprocessparticipantlist/"
export const officialTravelRapelSubmissionSource = "officialtravel/rapelsubmission/source/"
export const officialTravelRapelSubmissionCreateInitial = "officialtravel/rapelsubmission/createinitial/"
export const officialTravelRapelSubmissionProcessUpdate = "officialtravel/rapelsubmission/update/"
export const officialTravelRapelSubmissionProcessDetail = "officialtravel/rapelsubmission/processdetail/"
export const officialTravelRapelSubmissionProcessParticipant = "officialtravel/rapelsubmission/processparticipant/"

export const officialTravelRapelVerificationList = "officialtravel/rapelverification/lists/"
export const officialTravelRapelVerificationDetail = "officialtravel/rapelverification/detail/"
export const officialTravelRapelVerificationParticipant = "officialtravel/rapelverification/participant/"
export const officialTravelRapelVerificationProcessDetail = "officialtravel/rapelverification/verifydetail/"
export const officialTravelRapelVerificationProcessParticipant = "officialtravel/rapelverification/verifyparticipant/"
export const officialTravelRapelVerificationProcessVerify = "officialtravel/rapelverification/verify/"
export const officialTravelRapelVerificationProcessReject = "officialtravel/rapelverification/reject/"
export const officialTravelRapelVerificationProcessApprove = "officialtravel/rapelverification/approve/"
export const officialTravelRapelVerificationSource = "officialtravel/rapelverification/source/"

export const officialTravelVerificationList = "officialtravel/verification/lists/"
export const officialTravelVerificationDetail = "officialtravel/verification/detail/"
export const officialTravelVerificationParticipant = "officialtravel/verification/participant/"

export const officialTravelVerificationProcessDetail = "officialtravel/verification/verifydetail/"
export const officialTravelVerificationProcessParticipant = "officialtravel/verification/verifyparticipant/"
export const officialTravelVerificationProcessVerify = "officialtravel/verification/verify/"
export const officialTravelVerificationProcessReject = "officialtravel/verification/reject/"
export const officialTravelVerificationProcessApprove = "officialtravel/verification/approve/"
export const officialTravelVerificationSource = "officialtravel/verification/source/"

export const officialTravelMonitoringEmployeeEmployee = "officialtravel/monitoringemployee/employees/"
export const officialTravelMonitoringEmployeeUnit = "officialtravel/monitoringemployee/units/"
export const officialTravelMonitoringEmployeeList = "officialtravel/monitoringemployee/list/"
export const officialTravelMonitoringEmployeeDetail = "officialtravel/monitoringemployee/detail/"

export const officialTravelMonitoringStList = "officialtravel/monitoringst/lists/"
export const officialTravelMonitoringStDetail = "officialtravel/monitoringst/detail/"
export const officialTravelMonitoringStParticipant = "officialtravel/monitoringst/participant/"

export const officialTravelRefundList = "officialtravel/refund/lists/"
export const officialTravelRefundCancel = "officialtravel/refund/cancel/"
export const officialTravelRefundUpload = "officialtravel/refund/upload/"
export const officialTravelRefundDetail = "officialtravel/refund/detail/"

// SPT
export const payrollSptYears = "payroll/spt/years/"
export const payrollSptCheckStatusRegular = "payroll/spt/checkStatusRegular/"
export const payrollSptCheckStatusFinal = "payroll/spt/checkStatusFinal/"
export const payrollSptListRegular = "payroll/spt/listRegular/"
export const payrollSptListFinal = "payroll/spt/listFinal/"
export const payrollSptCreateRegular = "payroll/spt/createRegular/"
export const payrollSptCreateFinal = "payroll/spt/createFinal/"
export const payrollSptUpdate = "payroll/spt/update/"

// Payroll SKP
export const payrollSkpYears = "payroll/skp/years/"
export const payrollSkpGetSigner = "payroll/skp/getSigner/"
export const payrollSkpHistory = "payroll/skp/history/"

export const payrollSkpList = "payroll/skp/skplist/"
export const payrollSkpEmployee = "payroll/skp/employees/"
export const payrollSkpReady = "payroll/skp/skpready/"
export const payrollSkpCreate = "payroll/skp/skpcreate/"
export const payrollSkpCheckData = "payroll/skp/checkDataSkp/"
export const payrollSkpCheckDataCreate = "payroll/skp/checkDataSkpCreate/"
export const payrollSkpProcess = "payroll/skp/skpprocess/"
export const payrollSkpDetail = "payroll/skp/detailSkp/"

export const payrollPayrollList = "payroll/skp/payrolllist/"
export const payrollPayrollEmployee = "payroll/skp/employees/"
export const payrollPayrollReady = "payroll/skp/payrollready/"
export const payrollPayrollCreate = "payroll/skp/payrollcreate/"
export const payrollPayrollCheckData = "payroll/skp/checkDataPayroll/"
export const payrollPayrollCheckDataCreate = "payroll/skp/checkDataPayrollCreate/"
export const payrollPayrollProcess = "payroll/skp/payrollprocess/"
export const payrollPayrollDetail = "payroll/skp/detailPayroll/"

// Honorarium Tetap
export const payrollHonorariumList = "payroll/skp/honorariumlist/"
export const payrollHonorariumEmployee = "payroll/skp/employees/"
export const payrollHonorariumReady = "payroll/skp/honorariumready/"
export const payrollHonorariumCreate = "payroll/skp/honorariumcreate/"
export const payrollHonorariumCheckData = "payroll/skp/checkDataHonorarium/"
export const payrollHonorariumCheckDataCreate = "payroll/skp/checkDataHonorariumCreate/"
export const payrollHonorariumProcess = "payroll/skp/honorariumprocess/"
export const payrollHonorariumDetail = "payroll/skp/detailHonorarium/"

export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (
    method,
    route,
    data = { params: null, body: null, cancelToken: null },
    needToken = true
) => {
    if (needToken) {
        const thisTime = Math.floor(Date.now() / 1000)
        const expire = localStorage.getItem(initial + "accessExpired")
        if (expire < thisTime) {
            await apiWrapper(baseUrl, initial, appCode, outputType, "post", "auth/getAccessToken/", data)
                .then((result) => {
                    localStorage.setItem(initial + "accessToken", result.response.access_token)
                    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
                })
                .catch((result) => {
                    return Promise.reject("Tidak dapat mengambil accessToken")
                })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}
