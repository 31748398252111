import { accessRole } from "../../../../helpers/access_role"
import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import ErrorComingSoon from '../../../../components/Error/Content/ComengSoon'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import apiResponse from '../../../../services/apiResponse'
import IndexBloc from './Blocs/IndexBloc'
import Shimmer from "react-shimmer-effect"
import moment from 'moment'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import {
    Container,
    Row, Col,
    CardHeader, CardBody, Card, CardTitle, CardSubtitle,
    Button,
    CardFooter,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Input
} from "reactstrap"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

class IndexView extends Component {

    indexBloc = new IndexBloc();

    constructor(props) {
        super(props)
        this.state = {
            messageError: "",
            modalShow: false,
            dashboardFilterLoading: true,
            dashboardReportLoading: true,
            dashboardIconLoading: true,
            dashboardGppLoading: true,
            dashboardSigapLoading: true,
            dashboardDaftarPekerjaanLoading: true,
            dashboardPengajuanLoading: true,
            dashboardReport: [],
            dashboardIcon: [],
            dashboardGpp: [],
            dashboardSigap: [],
            dashboardDaftarPekerjaan: [
                {
                    title: "Uang Makan Bulan Oktober 2021",
                    message: "Data dukung tidak diapprove. Lakukan proses pengajuan.",
                    url: "#",
                    type: "proses"
                },
                {
                    title: "Tunjangan Pengkhidmatan Luar Negeri Bulan Desember 2021",
                    message: "Pengajuan ditolak oleh verifikator tanggal 02-Nov-2021. Revisi proses pengajuan.",
                    url: "#",
                    type: "revisi"
                },
                {
                    title: "Uang Makan Bulan Oktober 2021",
                    message: "Data dukung tidak diapprove. Lakukan proses pengajuan.",
                    url: "#",
                    type: "proses"
                },
                {
                    title: "Tunjangan Pengkhidmatan Luar Negeri Bulan Desember 2021",
                    message: "Pengajuan ditolak oleh verifikator tanggal 02-Nov-2021. Revisi proses pengajuan.",
                    url: "#",
                    type: "revisi"
                }
            ],
            dashboardPengajuan: [],
            jenisPengajuanOption: [],
            bulanPengajuanOption: [],
            tahunPengajuanOption: [],
            jenisPengajuan: "",
            jenisPengajuanDueDate: ""
        }
    }

    componentDidMount() {

        this.indexBloc.dashboardReportChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dashboardReportLoading: false,
                        dashboardReport: result.data.response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardReportLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.indexBloc.dashboardIconChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dashboardIconLoading: false,
                        dashboardIcon: result.data.response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardIconLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.indexBloc.dashboardGppChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dashboardGppLoading: false,
                        dashboardGpp: result.data.response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardGppLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.indexBloc.dashboardSigapChanel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dashboardSigapLoading: false,
                        dashboardSigap: result.data.response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardSigapLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.indexBloc.dashboardDaftarPekerjaanChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dashboardDaftarPekerjaanLoading: false,
                        dashboardDaftarPekerjaan: result.data.response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardDaftarPekerjaanLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.indexBloc.dashboardPengajuanChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dashboardPengajuanLoading: false,
                        dashboardPengajuan: result.data.response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardPengajuanLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.indexBloc.dashboardFilterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    let response = result.data.response
                    this.setState({
                        dashboardFilterLoading: false,
                        jenisPengajuanOption: response.jenis,
                        bulanPengajuanOption: response.bulan,
                        tahunPengajuanOption: response.tahun
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardFilterLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.loadData()
    }

    componentWillUnmount() {
        this.indexBloc.dashboardReportChannel?.unsubscribe()
        this.indexBloc.dashboardIconChannel?.unsubscribe()
        this.indexBloc.dashboardGppChannel?.unsubscribe()
        this.indexBloc.dashboardSigapChanel?.unsubscribe()
        this.indexBloc.dashboardDaftarPekerjaanChannel?.unsubscribe()
        this.indexBloc.dashboardPengajuanChannel?.unsubscribe()
        this.indexBloc.dashboardFilterChannel?.unsubscribe()
    }

    loadData = () => {
        this.indexBloc.fetchDashboardReport()
        this.indexBloc.fetchDashboardIcon()
        this.indexBloc.fetchDashboardGpp()
        this.indexBloc.fetchDashboardSigap()
        this.indexBloc.fetchDashboardDaftarPekerjaan()
        this.indexBloc.fetchDashboarPengajuan()
        this.indexBloc.fetchFilter()
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        let formPencarian = {
            ...values
        }
        this.setState({ dashboardPengajuanLoading: true })
        this.indexBloc.fetchDashboarPengajuan(formPencarian)
    }

    editBatasWaktu = (e, item) => {
        this.setState({
            modalShow: true,
            jenisPengajuan: item.name,
            jenisPengajuanDueDate: item.target
        })
    }

    simpanBatasWaktu = (e) => {
        this.setState({
            modalShow: false
        })
    }

    scrollContainer = React.createRef();

    handleScroll = (direction) => {
        const container = this.scrollContainer.current;
        const scrollAmount = container.offsetWidth;
        
        if (direction === 'left') {
            container.scrollBy({
                left: -scrollAmount,
                behavior: 'smooth'
            });
        } else {
            container.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid className="py-4">
                    <Row className="mb-4">
                        <Col>
                            <h2 className="page-title mb-0"></h2>
                        </Col>
                        <Col xs="auto">
                            <Link className="btn btn-warning" to={"/home/notification.html"}>
                                <i className="uil-bell me-2"></i>Notifikasi
                            </Link>
                        </Col>
                    </Row>

                    {/* Report Cards */}
                    <Card className="mb-5" style={{ boxShadow: '0 4px 12px rgba(45, 59, 150, 0.15)' }}>
                        <CardBody className="cards-outer-wrapper">
                            <CardTitle tag="h4" className="mb-4 text-center">Pengajuan Bulan November 2021</CardTitle>
                            <div className="position-relative" style={{ padding: '15px 40px' }}>
                                {this.state.dashboardReportLoading ? (
                                    <Col md={12}>
                                        <Shimmer><div className="shimmer-card" style={{ height: 250 }}></div></Shimmer>
                                    </Col>
                                ) : (
                                    <>
                                        <Button 
                                            className="scroll-button scroll-left" 
                                            onClick={() => this.handleScroll('left')}
                                            style={{
                                                display: this.state.dashboardReport.length <= 4 ? 'none' : 'block',
                                                position: 'absolute',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                left: '10px',
                                                zIndex: 1
                                            }}
                                        >
                                            <i className="uil-angle-left-b"></i>
                                        </Button>
                                        <div className="cards-container" ref={this.scrollContainer} style={{
                                            display: 'flex',
                                            gap: '17px',
                                            overflowX: 'hidden',
                                            scrollBehavior: 'smooth',
                                            padding: '10 20px'
                                        }}>
                                            {this.state.dashboardReport.map((item, i) => (
                                                <div key={i} className="card-wrapper" style={{
                                                    flex: '0 0 calc(25% - 11.25px)',
                                                    minWidth: 'calc(25% - 11.25px)',
                                                    maxWidth: 'calc(25% - 11.25px)',
                                                }}>
                                                    <Card className="h-100 shadow-sm hover-card">
                                                        <CardHeader className="text-white" style={{ backgroundColor: '#2D3B96' }}>
                                                            <h6 className="mb-0 text-white">{item.name}</h6>
                                                            <small>{item.month}</small>
                                                        </CardHeader>
                                                        <CardBody className="d-flex flex-column justify-content-between">
                                                            <div className="text-center mb-3">
                                                                <h2 className="mb-0">H-12</h2>
                                                                <p className="text-muted mb-0">
                                                                    Batas: {moment(item.target).format('dddd, LL')}
                                                                </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <Button style={{ backgroundColor: '#4CA85F', borderColor: '#4CA85F' }} size="sm">
                                                                    Success
                                                                </Button>
                                                                <Button color="secondary" size="sm" onClick={(e) => this.editBatasWaktu(e, item)}>
                                                                    Edit
                                                                </Button>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                        <Button 
                                            className="scroll-button scroll-right"
                                            onClick={() => this.handleScroll('right')}
                                            style={{
                                                display: this.state.dashboardReport.length <= 4 ? 'none' : 'block',
                                                position: 'absolute',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                right: '10px',
                                                zIndex: 1
                                            }}
                                        >
                                            <i className="uil-angle-right-b"></i>
                                        </Button>
                                    </>
                                )}
                            </div>
                        </CardBody>
                    </Card>

                    {/* Icon Cards */}
                    <Row className="mb-5">
                        {this.state.dashboardIconLoading ? (
                            <Col md={12}>
                                <Shimmer><div className="shimmer-card" style={{ height: 100 }}></div></Shimmer>
                            </Col>
                        ) : (
                            this.state.dashboardIcon.map((item, i) => (
                                <Col key={i} md={4} lg={3} xl={2} className="mb-4">
                                    <Link to={item.url} style={{ textDecoration: 'none' }}>
                                        <Card className="text-center h-100 shadow-sm hover-card border-0" 
                                            style={{
                                                transition: 'all 0.3s ease',
                                                cursor: 'pointer',
                                                boxShadow: '0 4px 12px rgba(45, 59, 150, 0.15)'
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.transform = 'translateY(-5px)';
                                                e.currentTarget.style.boxShadow = '0 8px 15px rgba(45, 59, 150, 0.2)';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.transform = 'translateY(0)';
                                                e.currentTarget.style.boxShadow = '0 4px 12px rgba(45, 59, 150, 0.15)';
                                            }}
                                        >
                                            <CardBody className="d-flex flex-column align-items-center justify-content-center py-4">
                                                <div className="icon-wrapper mb-3" 
                                                    style={{
                                                        backgroundColor: '#EEF2FF',
                                                        width: '70px',
                                                        height: '70px',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <i className={`${item.icon}`} 
                                                        style={{ 
                                                            color: '#2D3B96', 
                                                            fontSize: '1.8rem'
                                                        }}
                                                    />
                                                </div>
                                                <h6 className="mb-0" style={{ color: '#2D3B96' }}>{item.name}</h6>
                                            </CardBody>
                                        </Card>
                                    </Link>
                                </Col>
                            ))
                        )}
                    </Row>

                    {/* GPP and SIGAP Cards */}
                    <Row className="mb-5">
                        <Col xl={6} className="mb-4">
                            <Card className="h-100 shadow-sm hover-card" style={{ boxShadow: '0 4px 12px rgba(45, 59, 150, 0.15)' }}>
                                <CardHeader className="text-white" style={{ backgroundColor: '#2D3B96' }}>
                                    <h6 className="mb-0 text-white">SLIP GAJI</h6>
                                </CardHeader>
                                <CardBody>
                                    {this.state.dashboardGppLoading ? (
                                        <Shimmer><div className="shimmer-card" style={{ height: 100 }}></div></Shimmer>
                                    ) : (
                                        this.state.dashboardGpp.map((item, i) => (
                                            <div key={i} className="d-flex justify-content-between align-items-center mb-3 p-3 bg-light rounded">
                                                <div>
                                                    <h6 className="mb-1">{item.name}</h6>
                                                    <p className="mb-0 text-muted">{item.message}</p>
                                                </div>
                                                <Link className="btn btn-sm" style={{ backgroundColor: '#4CA85F', borderColor: '#4CA85F', color: 'white' }} to={item.url}>
                                                    Cek
                                                </Link>
                                            </div>
                                        ))
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6} className="mb-4">
                            <Card className="h-100 shadow-sm hover-card" style={{ boxShadow: '0 4px 12px rgba(45, 59, 150, 0.15)' }}>
                                <CardHeader className="text-white" style={{ backgroundColor: '#2D3B96' }}>
                                    <h6 className="mb-0 text-white">SIGAP</h6>
                                </CardHeader>
                                <CardBody>
                                    {this.state.dashboardSigapLoading ? (
                                        <Shimmer><div className="shimmer-card" style={{ height: 100 }}></div></Shimmer>
                                    ) : (
                                        this.state.dashboardSigap.map((item, i) => (
                                            <div key={i} className="d-flex justify-content-between align-items-center mb-3 p-3 bg-light rounded">
                                                <div>
                                                    <h6 className="mb-1">{item.name}</h6>
                                                    <p className="mb-0 text-muted">{item.message}</p>
                                                </div>
                                                <Link className="btn btn-sm" style={{ backgroundColor: '#4CA85F', borderColor: '#4CA85F', color: 'white' }} to={item.url}>
                                                    Cek
                                                </Link>
                                            </div>
                                        ))
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Daftar Pekerjaan and Histori Pengajuan */}
                    <Row>
                        <Col xl={6} className="mb-4">
                            <Card className="h-100 shadow-sm hover-card" style={{ boxShadow: '0 4px 12px rgba(45, 59, 150, 0.15)' }}>
                                <CardHeader className="text-white" style={{ backgroundColor: '#2D3B96' }}>
                                    <h6 className="mb-0 text-white">DAFTAR PEKERJAAN</h6>
                                </CardHeader>
                                <CardBody style={{ padding: '0.5rem' }}>
                                    {this.state.dashboardDaftarPekerjaanLoading ? (
                                        <Shimmer><div className="shimmer-card" style={{ height: 100 }}></div></Shimmer>
                                    ) : (
                                        this.state.dashboardDaftarPekerjaan.length > 0 ? (
                                            this.state.dashboardDaftarPekerjaan?.map((item, i) => (
                                                <div key={i} className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                                    <div style={{ flex: 1 }}>
                                                        <h6 className="mb-1" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>{item.title}</h6>
                                                        <p className="mb-0 text-muted" style={{ fontSize: '0.8rem' }}>{item.message}</p>
                                                    </div>
                                                    <Link 
                                                        className={`btn btn-sm ${item.type === 'proses' ? 'btn-success' : 'btn-warning'}`}
                                                        to={item.url}
                                                        style={{ 
                                                            minWidth: '80px',
                                                            fontSize: '0.8rem',
                                                            marginLeft: '1rem'
                                                        }}
                                                    >
                                                        {item.type === 'proses' ? 'Proses' : 'Revisi'}
                                                    </Link>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-center py-4">
                                                <strong>-- Tidak ada daftar pekerjaan --</strong>
                                            </div>
                                        )
                                    )}
                                    {/* {
                                        this.state.dashboardDaftarPekerjaan?.map((item, i) => (
                                            <Card key={i} className="mb-3 border">
                                                
                                                <CardBody>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h6 className="mb-1">{item.title}</h6>
                                                            <p className="mb-0 text-muted">{item.message}</p>
                                                        </div>
                                                        <div className="text-end">
                                                            <Link 
                                                                className={`btn btn-sm ${item.type === 'proses' ? 'btn-success' : 'btn-warning'}`}
                                                                to={item.url}
                                                            >
                                                                {item.type === 'proses' ? 'Proses' : 'Revisi'}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))
                                    } */}
                                </CardBody>
                                <CardFooter className="text-center bg-light">
                                    <Link className="text-secondary" to={"/home/notification.html"}>
                                        <small>
                                            <strong>Lihat semua daftar pekerjaan <i className="uil-arrow-right ms-1"></i></strong>
                                        </small>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col xl={6} className="mb-4">
                            <Card className="h-100 shadow-sm hover-card" style={{ boxShadow: '0 4px 12px rgba(45, 59, 150, 0.15)' }}>
                                <CardHeader className="text-white" style={{ backgroundColor: '#2D3B96' }}>
                                    <h6 className="mb-0 text-white">HISTORI PENGAJUAN</h6>
                                </CardHeader>
                                <CardBody>
                                    <AvForm className="needs-validation mb-4" onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}>
                                        <Row>
                                            <Col md={4} className="mb-3">
                                                <label className="form-label">Jenis Pengajuan</label>
                                                <CustomSelect 
                                                    name="jenis_pengajuan"
                                                    errorMessage="Pilih Jenis Pengajuan"
                                                    options={this.state.jenisPengajuanOption} 
                                                    defaultValue={null}
                                                    placeholder="Pilih Jenis Pengajuan"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                            <Col md={4} className="mb-3">
                                                <label className="form-label">Bulan Pengajuan</label>
                                                <CustomSelect 
                                                    name="bulan_pengajuan"
                                                    errorMessage="Pilih Bulan Pengajuan"
                                                    options={this.state.bulanPengajuanOption} 
                                                    defaultValue={null}
                                                    placeholder="Pilih Bulan Pengajuan"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                            <Col md={4} className="mb-3">
                                                <label className="form-label">Tahun Pengajuan</label>
                                                <CustomSelect 
                                                    name="tahun_pengajuan"
                                                    errorMessage="Pilih Tahun Pengajuan"
                                                    options={this.state.tahunPengajuanOption} 
                                                    defaultValue={null}
                                                    placeholder="Pilih Tahun Pengajuan"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit" className="w-100" style={{ backgroundColor: '#2D3B96' }}>
                                            <i className="uil-search me-2"></i> Filter Pencarian
                                        </Button>
                                    </AvForm>

                                    {this.state.dashboardPengajuanLoading ? (
                                        <Shimmer><div className="shimmer-card" style={{ height: 100 }}></div></Shimmer>
                                    ) : (
                                        this.state.dashboardPengajuan.length > 0 ? (
                                            this.state.dashboardPengajuan?.map((item, i) => (
                                                <Card key={i} className="mb-3 border">
                                                    <CardHeader className="bg-light">
                                                        <p className="mb-0">{moment(item.request_date).format('dddd, LL')}</p>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <h6 className="mb-1">{item.title}</h6>
                                                                <p className="mb-0 text-muted">{item.description}</p>
                                                            </div>
                                                            <div className="text-end">
                                                                {item.nominal && (
                                                                    <p className="mb-0">Rp. {(item.nominal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</p>
                                                                )}
                                                                <p className="mb-0">Status: <span className="badge bg-info">{item.status}</span></p>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            ))
                                        ) : (
                                            <div className="text-center py-4">
                                                <strong>-- Tidak ada riwayat pengajuan --</strong>
                                            </div>
                                        )
                                    )}
                                </CardBody>
                                <CardFooter className="text-center bg-light">
                                    <Link className="text-secondary" to={"/home/notification.html"}>
                                        <small>
                                            <strong>Lihat Semua History Pengajuan <i className="uil-arrow-right ms-1"></i></strong>
                                        </small>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    className='modal-sm'
                    isOpen={this.state.modalShow}
                    toggle={() => {
                        this.setState({
                            modalShow: false
                        })
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            this.setState({
                                modalShow: false
                            })
                        }}
                    >Ubah Batas Waktu</ModalHeader>
                    <ModalBody>
                        <label className="form-label">
                            Jenis Pengajuan
                        </label>
                        <Input
                            type="text"
                            autoComplete="off"
                            className="mb-3"
                            readOnly={true}
                            value={this.state.jenisPengajuan}
                        />
                        <label className="form-label">
                            Batas Waktu
                        </label>
                        <Input
                            type="date"
                            autoComplete="off"
                            className="mb-3"
                            readOnly={true}
                            value={this.state.jenisPengajuanDueDate}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={(e)=> this.simpanBatasWaktu(e)}>
                            <i className="uil-save"></i> Simpan
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.setState({
                                    modalShow: false
                                })
                            }}><i className="uil-arrow-left"></i> Tutup
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

export default IndexView