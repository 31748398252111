import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link, Redirect } from "react-router-dom"
import Select from "react-select"
import Shimmer from "react-shimmer-effect";

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Label,
} from "reactstrap"
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import CreateBloc from './Blocs/CreateBloc'
import apiResponse from '../../../../services/apiResponse'

class CreateView extends Component {

    createBloc = new CreateBloc();

    constructor(props) {
        super(props)
        this.state = {
            loadingEmployee: true,
            loadingApprover: true,
            loadingOperator: true,
            optionEmployee: {},
            optionTreasurer: {},
            optionPpspm: {},
            selectedEmployee: null,
            selectedPpspm: null,
            ppkCode: '',
            ppkName: '',
            ppkUnitCode: '',
            ppkUnitName: '',
            ppkDipaNo: '',
            ppkDipaDate: '',
            ppkSkNumber: '',
            ppkSkDateStart: '',
            ppkSkDateEnd: '',
            ppkSkTypeSelected: 1,
            ppkSkTypeOption: [
                { value: 1, label: 'Baru' },
                { value: 2, label: 'Perpanjangan' }
            ],
            dataList: [],
            dataValue: [],
            minDate: null,
            maxDate: null,
        }
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingEmployee: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionEmployee(response.except)
                    this.setOptionTreasurer(response.all)
                    this.setState({
                        loadingEmployee: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.employeeOperatorChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingOperator: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionPpspm(response.ppspm)
                    this.setState({
                        loadingOperator: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.employeeApproverChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingApprover: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionApprover(response.ppspm)
                    this.setState({
                        loadingApprover: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.fetchEmployeeList()
        this.createBloc.fetchEmployeeOperator()
        this.createBloc.fetchEmployeeApprover()
    }

    componentWillUnmount() {
        this.createBloc.createChannel.unsubscribe()
    }

    handleOnChangeSk = (selected) => {
        this.setState({
            ppkSkTypeSelected: selected.value
        })
    }

    handleOnChangeUuid = (selected) => {
        this.setState({
            selectedEmployee: selected.value
        })
    }

    handleOnChangePpspmUuid = (selected) => {
        this.setState({
            selectedPpspm: selected.value
        })
    }

    handleOnChangeTreasurer = (selected) => {
        this.setState({
            selectedTreasurer: selected.value
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    setOptionEmployee = (list) => {
        const itemEmployee = []
        list.map((employee) => {
            itemEmployee.push(employee)
            return null
        })
        this.setState({
            selectedEmployee: null,
            optionEmployee: itemEmployee
        }, () => {
            this.addNewRow()
        });
    }

    setOptionPpspm = (list) => {
        const itemEmployee = []
        list.map((employee) => {
            itemEmployee.push(employee)
            return null
        })
        this.setState({
            selectedPpspm: null,
            optionPpspm: itemEmployee
        }, () => {
            this.addNewRow()
        });
    }

    setOptionTreasurer = (list) => {
        const itemTreasurer = []
        list.map((employee) => {
            itemTreasurer.push(employee)
            return null
        })
        this.setState({
            selectedTreasurer: null,
            optionTreasurer: itemTreasurer
        });
    }

    resetAllForm = () => {
        this.setState({
            dataList: [],
            dataValue: []
        })
        this.loadEmployee()
    }

    addNewRow = () => {
        var _dataList = this.state.dataList
        var _dataValue = this.state.dataValue
        var allowAdd = true;
        _dataValue.forEach((item) => {
            if (item.employee === null || item.value === '' || item.value === 0) {
                allowAdd = false;
            }
        })
        if (allowAdd) {
            _dataList.push(this.state.optionEmployee)
            _dataValue.push({ employee: null })
            this.setState({
                dataList: _dataList,
                dataValue: _dataValue
            })
        }
    }

    deleteRow(key) {
        var _dataList = this.state.dataList
        var _dataValue = this.state.dataValue
        delete _dataList[key]
        delete _dataValue[key]
        var __dataList = [];
        _dataList.forEach(element => {
            __dataList.push(element)
        });
        var __dataValue = [];
        _dataValue.forEach(element => {
            __dataValue.push(element)
        });
        this.setState({
            dataList: __dataList,
            dataValue: __dataValue
        }, () => {
            if (this.state.dataList.length === 0) {
                this.addNewRow()
            }
        })
    }

    handleOnChangeEmployee = (key, selected) => {
        var _dataValue = this.state.dataValue
        _dataValue[key] = { employee: selected }

        this.setState({
            dataValue: _dataValue,
        });
    }

    handlerOnSubmit = (e, values) => {
        var _dataValue = this.state.dataValue
        var _employee = []
        _dataValue.forEach((item) => {
            _employee.push(item.employee.value)
        })
        this.setState({
            dataForm: {
                code: this.state.ppkCode,
                name: this.state.ppkName,
                uuid: this.state.selectedEmployee,
                ppspm_uuid: this.state.selectedPpspm,
                sk_number: this.state.ppkSkNumber,
                sk_date_start: this.state.ppkSkDateStart,
                sk_date_end: this.state.ppkSkDateEnd,
                unit_code: this.state.ppkUnitCode,
                unit_name: this.state.ppkUnitName,
                dipa_no: this.state.ppkDipaNo,
                dipa_date: this.state.ppkDipaDate,
                treasurer: this.state.selectedTreasurer,
                employee: _employee,
            },
            confirm: true
        })
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, { field: 'document', name: 'Trash/Unknown' })
        await this.createBloc.fetchCreate(formData)
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if (last.length >= 11) { event.preventDefault(); }
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        const regex = /^[0-9a-zA-Z(\-)]+$/; //this will admit letters, numbers and dashes
        if (!str.match(regex)) {
            event.preventDefault();
        }
    }
    handleKeyUpCode = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        _this.value = value.toUpperCase()
        this.setState({
            ppkCode: value.toUpperCase()
        })
    }
    handleKeyUpName = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        this.setState({
            ppkName: value
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Pejabat Pembuat Komitmen</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nomor SK<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="3">
                                                <AvField
                                                    name="ppk_sk_number"
                                                    placeholder="Nomor Surat Keputusan"
                                                    errorMessage="Masukkan Nomor Surat Keputusan"
                                                    type="text"
                                                    className="form-control"
                                                    id="fieldNumber"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(v) => {
                                                        this.setState({
                                                            ppkSkNumber: v.target.value,
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldDateActive"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tanggal Mulai Berlaku<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <AvField
                                                    name="ppk_sk_date_start"
                                                    placeholder="Tanggal Mulai Berlaku"
                                                    errorMessage="Masukkan Tanggal Mulai Berlaku"
                                                    type="date"
                                                    max={this.state.maxDate}
                                                    className="form-control"
                                                    id="fieldDateActive"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(v) => {
                                                        this.setState({
                                                            minDate: v.target.value,
                                                            ppkSkDateStart: v.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldDateActive"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tanggal Selesai Berlaku<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <AvField
                                                    name="ppk_sk_date_end"
                                                    placeholder="Tanggal Selesai Berlaku"
                                                    errorMessage="Masukkan Tanggal Selesai Berlaku"
                                                    type="date"
                                                    min={this.state.minDate}
                                                    className="form-control"
                                                    id="fieldDateActive"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(v) => {
                                                        this.setState({
                                                            maxDate: v.target.value,
                                                            ppkSkDateEnd: v.target.value,
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="ppk_work_code"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode Satuan Kerja<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <AvField
                                                    name="ppk_work_code"
                                                    placeholder="Kode Satuan Kerja"
                                                    errorMessage="Masukkan Kode Satuan Kerja"
                                                    type="text"
                                                    onKeyUp={(e) => {
                                                        this.setState({
                                                            ppkUnitCode: e.target.value
                                                        })
                                                    }}
                                                    className="form-control"
                                                    id="ppk_work_code"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="ppk_work_name"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Satuan Kerja<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                <AvField
                                                    name="ppk_work_name"
                                                    placeholder="Nama Satuan Kerja"
                                                    errorMessage="Masukkan Nama Satuan Kerja"
                                                    type="text"
                                                    onKeyUp={(e) => {
                                                        this.setState({
                                                            ppkUnitName: e.target.value
                                                        })
                                                    }}
                                                    className="form-control"
                                                    id="ppk_work_name"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="ppk_work_name"
                                                className="col-md-2 col-form-label"
                                            >
                                                Pilih PPSPM<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                {
                                                    this.state.loadingOperator ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 36 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <CustomSelect
                                                                name="uuid"
                                                                errorMessage="Pilih Nama Pegawai"
                                                                options={this.state.optionPpspm}
                                                                placeholder="Pilih Nama Pegawai"
                                                                optionLabel="label"
                                                                optionValue="value"
                                                                validate={{ required: { value: true } }}
                                                                onChange={(selected) => {
                                                                    this.handleOnChangePpspmUuid(selected)
                                                                }}
                                                            />
                                                        </>
                                                }
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="ppk_code"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode PPK<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                <div style={{ width: 200 }}>
                                                    <AvField
                                                        name="ppk_code"
                                                        style={{ textTransform: 'uppercase' }}
                                                        placeholder="Kode PPK"
                                                        errorMessage="Masukkan Kode PPK"
                                                        type="text"
                                                        onKeyPress={(e) => { this.handleKeyPress(e) }}
                                                        onKeyUp={(e) => { this.handleKeyUpCode(e, 'ppk_code') }}
                                                        className="form-control"
                                                        id="ppk_code"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="uuid"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Pegawai<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                {
                                                    this.state.loadingEmployee ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 36 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <CustomSelect
                                                                name="uuid"
                                                                errorMessage="Pilih Nama Pegawai"
                                                                options={this.state.optionEmployee}
                                                                placeholder="Pilih Nama Pegawai"
                                                                optionLabel="label"
                                                                optionValue="value"
                                                                validate={{ required: { value: true } }}
                                                                onChange={(selected) => {
                                                                    this.handleOnChangeUuid(selected)
                                                                }}
                                                            />
                                                        </>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="ppk_name"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Jabatan PPK<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                <AvField
                                                    name="ppk_name"
                                                    placeholder="Nama PPK"
                                                    errorMessage="Masukkan Nama PPK"
                                                    type="text"
                                                    onKeyUp={(e) => { this.handleKeyUpName(e, 'ppk_name') }}
                                                    className="form-control"
                                                    id="ppk_name"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="ppk_dipa_no"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nomor DIPA<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="ppk_dipa_no"
                                                    placeholder="Nomor DIPA"
                                                    errorMessage="Masukkan Nomor DIPA"
                                                    type="text"
                                                    onKeyUp={(e) => {
                                                        this.setState({
                                                            ppkDipaNo: e.target.value
                                                        })
                                                    }}
                                                    className="form-control"
                                                    id="ppk_dipa_no"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="ppk_dipa_date"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tanggal DIPA<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <AvField
                                                    name="ppk_dipa_date"
                                                    placeholder="Tanggal DIPA"
                                                    errorMessage="Masukkan Tanggal DIPA"
                                                    type="date"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            ppkDipaDate: e.target.value
                                                        })
                                                    }}
                                                    className="form-control"
                                                    id="ppk_dipa_date"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Bendahara<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                {
                                                    this.state.loadingEmployee ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 36 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <CustomSelect
                                                                name="uuid"
                                                                errorMessage="Pilih Nama Bendahara"
                                                                options={this.state.optionTreasurer}
                                                                placeholder="Pilih Nama Bendahara"
                                                                optionLabel="label"
                                                                optionValue="value"
                                                                validate={{ required: { value: true } }}
                                                                onChange={(selected) => {
                                                                    this.handleOnChangeTreasurer(selected)
                                                                }}
                                                            />
                                                        </>
                                                }
                                            </Col>
                                        </Row>

                                        <hr />
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Anggota <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                <Table
                                                    className="table table-striped table-bordered table-hover"
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="*" data-priority="1">Nama Pegawai</Th>
                                                            <Th width="90px" data-priority="1" class="text-center">Aksi</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            this.state.loadingEmployee ?
                                                                <tr>
                                                                    <Td>
                                                                        <Select
                                                                            value={{ label: 'Pilih Pegawai', value: '' }}
                                                                            isDisabled={true}
                                                                            options={[]}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </Td>
                                                                    <Td className="text-center">
                                                                        <div
                                                                            className="btn btn-danger"
                                                                            disabled={true}
                                                                            onClick={() => {
                                                                                //this.downloadSigned(record.spt_detail_file_path)
                                                                            }}
                                                                        >
                                                                            <i className="uil-trash-alt"></i>
                                                                        </div>
                                                                    </Td>
                                                                </tr>
                                                                : <>
                                                                    {
                                                                        Object.entries(this.state.dataList).map((item, key) => {
                                                                            return (
                                                                                <tr key={key}>
                                                                                    <td>
                                                                                        <CustomSelect
                                                                                            name={"tunjab_employee_" + key}
                                                                                            value={this.state.dataValue[key].employee}
                                                                                            errorMessage="Pilih Pegawai"
                                                                                            options={item[1]}
                                                                                            placeholder="Pilih Pegawai"
                                                                                            optionLabel="label"
                                                                                            optionValue="value"
                                                                                            validate={{ required: { value: true } }}
                                                                                            onChange={(selected) => {
                                                                                                this.handleOnChangeEmployee(key, selected)
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <Td className="text-center">
                                                                                        <div
                                                                                            className="btn btn-danger"
                                                                                            disabled={this.state.loadingEmployee}
                                                                                            onClick={() => {
                                                                                                this.deleteRow(key)
                                                                                            }}
                                                                                        >
                                                                                            <i className="uil-trash-alt"></i>
                                                                                        </div>
                                                                                    </Td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                        }
                                                    </Tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <Td className="text-center">
                                                                <Button
                                                                    type="button"
                                                                    className="btn btn-success"
                                                                    disabled={this.state.loadingEmployee}
                                                                    onClick={() => {
                                                                        this.addNewRow()
                                                                    }}
                                                                >
                                                                    <i className="uil-plus"></i>
                                                                </Button>
                                                            </Td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/official-travel/ppk.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {this.state.redirect ?
                    <Redirect to={'/official-travel/ppk.html'} />
                    : null}
            </Fragment>
        )
    }
}

export default CreateView